// src/components/dashboard/Dashboard.js
import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  styled
} from '@mui/material';
import WelcomePanel from '../layout/WelcomePanel';
import BookSelector from './BookSelector';
import BookDetails from './BookDetails';
import CharacterSection from './CharacterSection';
import PlacesEvents from './PlacesEvents';
import PlotPoints from './PlotPoints';
import NarrativeStyle from './NarrativeStyle';
import ChapterPlans from './ChapterPlans';
import { useAuth } from '../../hooks/useAuth';
import { useBook } from '../../hooks/useBook';

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  minHeight: '100%',
  backgroundColor: theme.palette.background.default
}));

const SectionPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  borderRadius: 2,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  }
}));

const Dashboard = () => {
  const [selectedBook, setSelectedBook] = useState(null);
  const { user } = useAuth();
  const { books } = useBook();
  const theme = useTheme();

  if (!selectedBook) {
    return (
      <StyledContainer>
        <WelcomePanel userName={user?.user_metadata?.name || 'Author'} />
        <BookSelector
          selectedBook={selectedBook}
          onBookSelect={setSelectedBook}
        />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BookSelector
            selectedBook={selectedBook}
            onBookSelect={setSelectedBook}
          />
        </Grid>

        {/* Book Details and Narrative Style */}
        <Grid item xs={12} md={6}>
          <SectionPaper>
            <Typography variant="h6" gutterBottom>
              Book Details
            </Typography>
            <BookDetails bookId={selectedBook.book_id} />
          </SectionPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionPaper>
            <Typography variant="h6" gutterBottom>
              Writing Style
            </Typography>
            <NarrativeStyle bookId={selectedBook.book_id} />
          </SectionPaper>
        </Grid>

        {/* Characters and Plot Points */}
        <Grid item xs={12} md={6}>
          <SectionPaper>
            <Typography variant="h6" gutterBottom>
              Characters
            </Typography>
            <CharacterSection bookId={selectedBook.book_id} />
          </SectionPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionPaper>
            <Typography variant="h6" gutterBottom>
              Plot Points
            </Typography>
            <PlotPoints bookId={selectedBook.book_id} />
          </SectionPaper>
        </Grid>

        {/* Places & Events and Chapters */}
        <Grid item xs={12} md={6}>
          <SectionPaper>
            <Typography variant="h6" gutterBottom>
              Places & Events
            </Typography>
            <PlacesEvents bookId={selectedBook.book_id} />
          </SectionPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionPaper>
            <Typography variant="h6" gutterBottom>
              Chapters
            </Typography>
            <ChapterPlans bookId={selectedBook.book_id} />
          </SectionPaper>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Dashboard;