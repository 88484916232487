// src/components/tools/SceneBuilder/ParagraphGenerator.js

import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Rating,
  Tooltip,
  TextField,
  Stack,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  useTheme
} from '@mui/material';
import {
  BookmarkBorder as BookmarkIcon,
  BookmarkAdded as BookmarkFilledIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
  ContentCopy as CopyIcon
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';

const ParagraphGenerator = ({
  versions = [],
  onSelect,
  selectedParagraphs = [],
  isGenerating,
  onRegenerateOne,
  onSavePhrase
}) => {
  const theme = useTheme();
  const [editingVersion, setEditingVersion] = useState(null);
  const [ratings, setRatings] = useState({});
  const [feedback, setFeedback] = useState({});
  const [savedPhrases, setSavedPhrases] = useState([]);

  const handleRating = (versionId, value) => {
    setRatings(prev => ({
      ...prev,
      [versionId]: value
    }));
  };

  const handleSavePhrase = (phrase) => {
    setSavedPhrases(prev => [...prev, {
      text: phrase,
      source: 'generated',
      timestamp: Date.now()
    }]);
    if (onSavePhrase) {
      onSavePhrase(phrase);
    }
  };

  const VersionCard = ({ version, index }) => {
    const isSelected = selectedParagraphs.includes(version.content);
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <Paper
        sx={{
          p: 2,
          mb: 2,
          border: `1px solid ${
            isSelected ? 
              theme.palette.primary.main : 
              theme.palette.divider
          }`,
          backgroundColor: isSelected ?
            alpha(theme.palette.primary.main, 0.05) :
            'background.paper'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1
        }}>
          <Typography variant="subtitle1">
            Version {index + 1}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Rating
              value={ratings[version.id] || 0}
              onChange={(_, value) => handleRating(version.id, value)}
              size="small"
            />
          </Box>
        </Box>

        <Typography variant="body1">
          {editingVersion === version.id ? (
            <TextField
              fullWidth
              multiline
              value={version.content}
              onChange={(e) => {
                const newVersions = [...versions];
                newVersions[index].content = e.target.value;
                onSelect(newVersions);
              }}
              autoFocus
            />
          ) : (
            version.content
          )}
        </Typography>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Tooltip title="Select this version">
              <Button
                size="small"
                variant={isSelected ? "contained" : "outlined"}
                onClick={() => onSelect([...selectedParagraphs, version.content])}
                startIcon={isSelected ? <SaveIcon /> : null}
              >
                {isSelected ? 'Selected' : 'Select'}
              </Button>
            </Tooltip>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() => setEditingVersion(
                  editingVersion === version.id ? null : version.id
                )}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Copy to clipboard">
              <IconButton
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(version.content);
                }}
              >
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {onRegenerateOne && (
              <Tooltip title="Regenerate this version">
                <IconButton
                  size="small"
                  onClick={() => onRegenerateOne(index)}
                  disabled={isGenerating}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Save phrase">
              <IconButton
                size="small"
                onClick={() => handleSavePhrase(version.content)}
              >
                <BookmarkIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {version.analysis && (
          <Box sx={{ mt: 2, p: 1, bgcolor: 'background.default', borderRadius: 1 }}>
            <Typography variant="caption" color="text.secondary">
              Analysis:
            </Typography>
            {typeof version.analysis === 'string' ? (
              <Typography variant="body2">
                {version.analysis}
              </Typography>
            ) : (
              <Stack spacing={1}>
                {version.analysis.strengths && (
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Strengths:
                    </Typography>
                    <Typography variant="body2">
                      {Array.isArray(version.analysis.strengths) 
                        ? version.analysis.strengths.join(', ')
                        : version.analysis.strengths}
                    </Typography>
                  </Box>
                )}
                {version.analysis.style_notes && (
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Style Notes:
                    </Typography>
                    <Typography variant="body2">
                      {version.analysis.style_notes}
                    </Typography>
                  </Box>
                )}
                {version.analysis.suggested_improvements && (
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Suggested Improvements:
                    </Typography>
                    <Typography variant="body2">
                      {version.analysis.suggested_improvements}
                    </Typography>
                  </Box>
                )}
              </Stack>
            )}
          </Box>
        )}
      </Paper>
    );
  };

  if (versions.length === 0 && !isGenerating) {
    return (
      <Paper 
        sx={{ 
          p: 4, 
          textAlign: 'center',
          backgroundColor: 'background.default'
        }}
      >
        <Typography color="text.secondary">
          Generated paragraphs will appear here.
          Configure your scene and click Generate to start.
        </Typography>
      </Paper>
    );
  }

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
        <Typography variant="h6">
          Generated Versions
        </Typography>
        {versions.length > 0 && (
          <Button
            size="small"
            startIcon={<BookmarkIcon />}
            onClick={() => setEditingVersion('savedPhrases')}
          >
            Saved Phrases ({savedPhrases.length})
          </Button>
        )}
      </Box>

      {isGenerating && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          p: 4
        }}>
          <CircularProgress size={24} />
          <Typography>
            Generating paragraphs...
          </Typography>
        </Box>
      )}

      {versions.map((version, index) => (
        <VersionCard
          key={version.id || index}
          version={version}
          index={index}
        />
      ))}
    </Box>
  );
};

export default ParagraphGenerator;