// src/components/tools/AssignImageDialog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Alert,
  CircularProgress,
  CardMedia,
  Card,
  useTheme,
  Stack,
  Divider
} from '@mui/material';
import {
  Person as PersonIcon,
  Book as BookIcon,
  Landscape as SettingIcon,
  PhotoLibrary as GalleryIcon
} from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import supabase from '../../api/supabase';

const ASSIGNMENT_TYPES = [
    {
      value: 'character',
      label: 'Character Avatar',
      icon: <PersonIcon />,
      table: 'characters',
      imageColumn: 'image_path',
      titleColumn: 'name',
      galleryUsedAs: 'avatar',  // This matches the constraint
      query: (user_id) => ({
        query: `*,
          books!inner (
            user_id
          )`,
        match: { 'books.user_id': user_id }
      })
    },
    {
      value: 'cover',
      label: 'Book Cover',
      icon: <BookIcon />,
      table: 'books',
      imageColumn: 'cover_image',
      titleColumn: 'title',
      galleryUsedAs: 'cover',  // This matches the constraint
      query: (user_id) => ({
        query: '*',
        match: { user_id }
      })
    },
    {
      value: 'setting',
      label: 'Setting Illustration',
      icon: <SettingIcon />,
      table: 'settings',
      imageColumn: 'image_url',
      titleColumn: 'location',
      galleryUsedAs: 'illustration',  // This matches the constraint
      query: (user_id) => ({
        query: `*,
          books!inner (
            user_id
          )`,
        match: { 'books.user_id': user_id }
      })
    }
  ];
const AssignImageDialog = ({ 
  open, 
  onClose, 
  imageUrl,
  onSave,
  onAssign 
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [assignmentType, setAssignmentType] = useState('');
  const [assignmentTarget, setAssignmentTarget] = useState('');
  const [availableTargets, setAvailableTargets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (!open) {
      setAssignmentType('');
      setAssignmentTarget('');
      setError(null);
      setSuccess(null);
    }
  }, [open]);

  useEffect(() => {
    const loadTargets = async () => {
        if (!assignmentType) return;
      
        try {
          setLoading(true);
          const typeConfig = ASSIGNMENT_TYPES.find(t => t.value === assignmentType);
          const queryConfig = typeConfig.query(user.id);
      
          const { data, error } = await supabase
            .from(typeConfig.table)
            .select(queryConfig.query)
            .eq(Object.keys(queryConfig.match)[0], Object.values(queryConfig.match)[0]);
      
          if (error) throw error;
      
          setAvailableTargets(data || []);
        } catch (err) {
          console.error('Error loading targets:', err);
          setError('Failed to load available targets: ' + err.message);
        } finally {
          setLoading(false);
        }
      };
  
    loadTargets();
  }, [assignmentType, user.id]);

  const handleAssign = async () => {
    if (!assignmentType || !assignmentTarget) return;
  
    try {
      setLoading(true);
      const typeConfig = ASSIGNMENT_TYPES.find(t => t.value === assignmentType);
      
      // Get the correct ID field name
      const idField = typeConfig.value === 'character' ? 'character_id' : 
                     typeConfig.value === 'cover' ? 'book_id' : 'setting_id';
  
      // Save to gallery first
      const filePath = await onSave();
      
      // Update the target entity with the image
      const { error: updateError } = await supabase
        .from(typeConfig.table)
        .update({ [typeConfig.imageColumn]: filePath })
        .eq(idField, assignmentTarget);
  
      if (updateError) throw updateError;
  
      // Update gallery entry with the correct used_as value
      const { error: galleryError } = await supabase
        .from('gallery')
        .update({
          used_by_id: assignmentTarget,
          used_as: typeConfig.galleryUsedAs  // Use the mapped value that matches the constraint
        })
        .eq('image_path', filePath);
  
      if (galleryError) throw galleryError;
  
      setSuccess('Image assigned successfully!');
      onClose();
    } catch (err) {
      console.error('Assignment error:', err);
      setError('Failed to assign image: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const getTargetName = (target) => {
    if (!target) return '';
    const typeConfig = ASSIGNMENT_TYPES.find(t => t.value === assignmentType);
    return target[typeConfig?.titleColumn] || 'Unnamed';
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <GalleryIcon color="primary" />
          <Typography variant="h6">Assign Image</Typography>
        </Stack>
      </DialogTitle>

      <Divider />
      
      <DialogContent>
        <Stack spacing={3}>
          {imageUrl && (
            <Card elevation={0} sx={{ mb: 2 }}>
              <CardMedia
                component="img"
                height="200"
                image={imageUrl}
                alt="Image to assign"
                sx={{ 
                  objectFit: 'contain',
                  borderRadius: 1,
                  backgroundColor: 'background.default'
                }}
              />
            </Card>
          )}

          {error && (
            <Alert 
              severity="error" 
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          )}

          {success && (
            <Alert 
              severity="success"
              onClose={() => setSuccess(null)}
            >
              {success}
            </Alert>
          )}

          <FormControl fullWidth>
            <InputLabel>Assignment Type</InputLabel>
            <Select
              value={assignmentType}
              onChange={(e) => {
                setAssignmentType(e.target.value);
                setAssignmentTarget('');
              }}
              label="Assignment Type"
            >
              {ASSIGNMENT_TYPES.map((type) => (
                <MenuItem 
                  key={type.value} 
                  value={type.value}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {type.icon}
                    <Box>
                      <Typography variant="body1">
                        {type.label}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                      >
                        {type.description}
                      </Typography>
                    </Box>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {assignmentType && (
  <FormControl fullWidth>
    <InputLabel>Assign To</InputLabel>
    <Select
      value={assignmentTarget || ''}  // Ensure there's always a value
      onChange={(e) => setAssignmentTarget(e.target.value)}
      label="Assign To"
      disabled={loading || availableTargets.length === 0}
    >
      {loading ? (
        <MenuItem disabled>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          Loading options...
        </MenuItem>
      ) : availableTargets.length === 0 ? (
        <MenuItem disabled>
          No available targets
        </MenuItem>
      ) : (
        availableTargets.map((target) => (
          <MenuItem 
            key={target.character_id || target.book_id || target.setting_id}
            value={target.character_id || target.book_id || target.setting_id}  // Use the correct ID field
          >
            {target[ASSIGNMENT_TYPES.find(t => t.value === assignmentType).titleColumn]}
          </MenuItem>
        ))
      )}
    </Select>
  </FormControl>
)}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 2.5 }}>
        <Button 
          onClick={onClose} 
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleAssign}
          disabled={loading || !assignmentType || !assignmentTarget}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Assigning...' : 'Assign Image'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignImageDialog;
