import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme
} from '@mui/material';
import { ChromePicker } from 'react-color';

const TextColors = ({ currentTheme, onChange }) => {
  const theme = useTheme();
  const [activeColor, setActiveColor] = React.useState(null);

  const handleColorChange = (color) => {
    if (!activeColor) return;
    
    onChange({
      palette: {
        ...currentTheme.palette,
        text: {
          ...currentTheme.palette.text,
          [activeColor]: color.hex
        }
      }
    });
  };

  const ColorBox = ({ colorKey, label, description, example }) => (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        mb: 2,
        border: activeColor === colorKey ? `2px solid ${theme.palette.primary.main}` : 'none',
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {label}
          </Typography>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              cursor: 'pointer',
              backgroundColor: currentTheme.palette.text[colorKey],
              border: '2px solid',
              borderColor: 'divider',
            }}
            onClick={() => setActiveColor(colorKey)}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" display="block" gutterBottom>
            {description}
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: currentTheme.palette.text[colorKey],
              mb: 1 
            }}
          >
            {example}
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
            {currentTheme.palette.text[colorKey]}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Text Colors
      </Typography>
      
      <ColorBox 
        colorKey="primary" 
        label="Primary" 
        description="Main text color"
        example="This is primary text"
      />
      <ColorBox 
        colorKey="secondary" 
        label="Secondary" 
        description="Less prominent text"
        example="This is secondary text"
      />
      <ColorBox 
        colorKey="disabled" 
        label="Disabled" 
        description="Disabled text state"
        example="This is disabled text"
      />

      {activeColor && (
        <Box sx={{ mt: 2 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Color Picker
            </Typography>
            <ChromePicker
              color={currentTheme.palette.text[activeColor]}
              onChange={handleColorChange}
              disableAlpha
              styles={{
                default: {
                  picker: {
                    width: '100%',
                    boxShadow: 'none',
                  }
                }
              }}
            />
          </Paper>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Ensure text colors have sufficient contrast with your background colors
          for optimal readability. Test different combinations to find the perfect balance.
        </Typography>
      </Box>
    </Box>
  );
};

export default TextColors;