// src/components/tools/CharacterChat/ScenarioSetup.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Chip,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Alert,
  useTheme
} from '@mui/material';
import {
  Person as PersonIcon,
  Edit as EditIcon,
  Psychology as PsychologyIcon,
  AccessTime as TimeIcon,
  Place as PlaceIcon
} from '@mui/icons-material';

const PRESET_SCENARIOS = [
  {
    id: 'interview',
    title: 'Author Interview',
    description: 'Interview your character to learn more about their background, motivations, and personality.',
    mode: 'author',
    context: 'A casual conversation where you can ask your character anything.',
    suggestedQuestions: [
      "What's your earliest memory?",
      "What do you fear most?",
      "What drives you to achieve your goals?",
      "How do you feel about [other character]?"
    ]
  },
  {
    id: 'scene_practice',
    title: 'Scene Practice',
    description: 'Work through a specific scene with your character to understand their reactions and choices.',
    mode: 'scene',
    context: 'Place your character in a specific scene to explore their behavior.',
    requiresSceneDetails: true
  },
  {
    id: 'character_chat',
    title: 'In-Character Conversation',
    description: 'Chat with your character as another character from the story.',
    mode: 'character',
    context: 'A conversation between two characters.',
    requiresOtherCharacter: true
  },
  {
    id: 'development',
    title: 'Character Development',
    description: 'Explore how your character changes across different chapters.',
    mode: 'development',
    context: 'Focus on character growth and changes.',
    requiresChapterSelection: true
  }
];

const ScenarioSetup = ({
  open,
  onClose,
  onSetup,
  character,
  bookCharacters = [],
  bookChapters = []
}) => {
  const theme = useTheme();
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [customScenario, setCustomScenario] = useState('');
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [otherCharacter, setOtherCharacter] = useState('');
  const [sceneDetails, setSceneDetails] = useState({
    location: '',
    time: '',
    situation: '',
    mood: ''
  });
  const [chatMode, setChatMode] = useState('author');

  const handleStart = () => {
    const scenarioData = {
      mode: chatMode,
      chapters: selectedChapters,
      scenario: selectedScenario ? {
        ...PRESET_SCENARIOS.find(s => s.id === selectedScenario),
        ...(customScenario && { description: customScenario }),
        ...(sceneDetails && { details: sceneDetails }),
        otherCharacter: otherCharacter || undefined
      } : {
        title: 'Custom Scenario',
        description: customScenario,
        mode: chatMode,
        details: sceneDetails,
        otherCharacter: otherCharacter || undefined
      }
    };

    onSetup(scenarioData);
  };

  const isValid = () => {
    if (!chatMode) return false;
    if (chatMode === 'scene' && !sceneDetails.situation) return false;
    if (chatMode === 'character' && !otherCharacter) return false;
    if (!selectedScenario && !customScenario) return false;
    return true;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6">
          Setup Chat Scenario
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Configure how you want to interact with {character?.name}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {/* Chat Mode Selection */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Chat Mode
            </Typography>
            <RadioGroup
              row
              value={chatMode}
              onChange={(e) => setChatMode(e.target.value)}
            >
              <FormControlLabel
                value="author"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EditIcon />
                    <span>Author Mode</span>
                  </Box>
                }
              />
              <FormControlLabel
                value="character"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PersonIcon />
                    <span>Character Mode</span>
                  </Box>
                }
              />
              <FormControlLabel
                value="scene"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PsychologyIcon />
                    <span>Scene Practice</span>
                  </Box>
                }
              />
            </RadioGroup>
          </Box>

          <Divider />

          {/* Preset Scenarios */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Select Scenario
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {PRESET_SCENARIOS
                .filter(s => s.mode === chatMode)
                .map((scenario) => (
                  <Chip
                    key={scenario.id}
                    label={scenario.title}
                    onClick={() => setSelectedScenario(scenario.id)}
                    color={selectedScenario === scenario.id ? "primary" : "default"}
                    variant={selectedScenario === scenario.id ? "filled" : "outlined"}
                  />
                ))}
            </Box>
          </Box>

          {/* Custom Scenario */}
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Custom Scenario Description"
            value={customScenario}
            onChange={(e) => {
              setCustomScenario(e.target.value);
              setSelectedScenario(null);
            }}
            placeholder="Describe your custom scenario..."
          />

          {/* Scene Details (if applicable) */}
          {chatMode === 'scene' && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Scene Details
              </Typography>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Location"
                  value={sceneDetails.location}
                  onChange={(e) => setSceneDetails(prev => ({
                    ...prev,
                    location: e.target.value
                  }))}
                  InputProps={{
                    startAdornment: <PlaceIcon sx={{ mr: 1 }} />
                  }}
                />
                <TextField
                  fullWidth
                  label="Time/Setting"
                  value={sceneDetails.time}
                  onChange={(e) => setSceneDetails(prev => ({
                    ...prev,
                    time: e.target.value
                  }))}
                  InputProps={{
                    startAdornment: <TimeIcon sx={{ mr: 1 }} />
                  }}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Situation"
                  value={sceneDetails.situation}
                  onChange={(e) => setSceneDetails(prev => ({
                    ...prev,
                    situation: e.target.value
                  }))}
                />
              </Stack>
            </Box>
          )}

          {/* Other Character Selection (if applicable) */}
          {chatMode === 'character' && (
            <FormControl fullWidth>
              <InputLabel>Speaking as Character</InputLabel>
              <Select
                value={otherCharacter}
                onChange={(e) => setOtherCharacter(e.target.value)}
                label="Speaking as Character"
              >
                {bookCharacters
                  .filter(char => char.character_id !== character.character_id)
                  .map((char) => (
                    <MenuItem key={char.character_id} value={char.character_id}>
                      {char.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          {/* Chapter Selection */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Relevant Chapters
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {bookChapters.map((chapter) => (
                <Chip
                  key={chapter.number}
                  label={`Chapter ${chapter.number}`}
                  onClick={() => {
                    setSelectedChapters(prev =>
                      prev.includes(chapter.number)
                        ? prev.filter(num => num !== chapter.number)
                        : [...prev, chapter.number]
                    );
                  }}
                  color={selectedChapters.includes(chapter.number) ? "primary" : "default"}
                  variant={selectedChapters.includes(chapter.number) ? "filled" : "outlined"}
                />
              ))}
            </Box>
          </Box>

          {!isValid() && (
            <Alert severity="info">
              Please complete the required fields to start the chat.
            </Alert>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleStart}
          disabled={!isValid()}
        >
          Start Chat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioSetup;