import supabase from '../api//supabase';

class DatabaseService {
  // Book operations
  async getBook(bookId) {
    try {
      // First get the book
      const { data: book, error: bookError } = await supabase
        .from('books')
        .select('*')
        .eq('book_id', bookId)
        .single();

      if (bookError) throw bookError;

      // Then get all related data
      const [
        { data: characters },
        { data: chapters },
        { data: plots },
        { data: settings },
        { data: narrativeStyles }
      ] = await Promise.all([
        supabase.from('characters').select('*').eq('book_id', bookId),
        supabase.from('chapters').select('*').eq('book_id', bookId),
        supabase.from('plots').select('*').eq('book_id', bookId),
        supabase.from('settings').select('*').eq('book_id', bookId),
        supabase.from('narrative_styles').select('*').eq('book_id', bookId)
      ]);

      // Combine all data
      return {
        ...book,
        characters: characters || [],
        chapters: chapters || [],
        plots: plots || [],
        settings: settings || [],
        narrative_styles: narrativeStyles ? [narrativeStyles[0]] : null
      };
    } catch (error) {
      console.error('Error in getBook:', error);
      throw error;
    }
  }

  async updateBook(bookId, updates) {
    try {
      // Clean the updates object to remove null/undefined for optional fields
      const cleanUpdates = Object.entries(updates).reduce((acc, [key, value]) => {
        // Only include the field if it has a value or it's a boolean
        if (value !== null && value !== undefined && value !== '') {
          acc[key] = value;
        }
        return acc;
      }, {});
  
      // If is_series is false, explicitly set series fields to null
      if (cleanUpdates.is_series === false) {
        cleanUpdates.series_name = null;
        cleanUpdates.series_order = null;
      }
  
      const { data, error } = await supabase
        .from('books')
        .update(cleanUpdates)
        .eq('book_id', bookId)
        .select();
  
      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error updating book:', error);
      throw error;
    }
  }

  // Character operations
  async getCharacters(bookId) {
    const { data, error } = await supabase
      .from('characters')
      .select('*')
      .eq('book_id', bookId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data || [];
  }

  async createCharacter(characterData) {
    const { data, error } = await supabase
      .from('characters')
      .insert([characterData])
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async updateCharacter(characterId, updates) {
    const { data, error } = await supabase
      .from('characters')
      .update(updates)
      .eq('character_id', characterId)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async deleteCharacter(characterId) {
    const { error } = await supabase
      .from('characters')
      .delete()
      .eq('character_id', characterId);

    if (error) throw error;
  }

  // Chapters operations
  async getChapters(bookId) {
    const { data, error } = await supabase
      .from('chapters')
      .select('*')
      .eq('book_id', bookId)
      .order('order_number', { ascending: true });

    if (error) throw error;
    return data || [];
  }

  async createChapter(chapterData) {
    const { data, error } = await supabase
      .from('chapters')
      .insert([chapterData])
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async updateChapter(chapterId, updates) {
    const { data, error } = await supabase
      .from('chapters')
      .update(updates)
      .eq('chapter_id', chapterId)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async deleteChapter(chapterId) {
    const { error } = await supabase
      .from('chapters')
      .delete()
      .eq('chapter_id', chapterId);

    if (error) throw error;
  }

  // Settings operations
  async getSettings(bookId) {
    const { data, error } = await supabase
      .from('settings')
      .select('*')
      .eq('book_id', bookId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data || [];
  }

  // Similar patterns for createSetting, updateSetting, deleteSetting...

  // Narrative Style operations
  async getNarrativeStyle(bookId) {
    const { data, error } = await supabase
      .from('narrative_styles')
      .select('*')
      .eq('book_id', bookId)
      .single();

    if (error && error.code !== 'PGRST116') throw error;
    return data;
  }

  // Add error logging
  logError(method, error) {
    console.error(`DatabaseService ${method} error:`, error);
    throw error;
  }
}

export default new DatabaseService();