import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  useTheme
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useAuth } from '../../hooks/useAuth';

const plans = [
  {
    id: 'free',
    name: 'Free',
    price: 0,
    interval: 'month',
    features: [
      'Basic AI model access',
      'Up to 3 books',
      'Basic chapter generation',
      'Community support'
    ]
  },
  {
    id: 'standard',
    name: 'Standard',
    price: 9.99,
    interval: 'month',
    features: [
      'Multiple AI models',
      'Unlimited books',
      'Advanced chapter generation',
      'Priority support',
      'Background interview tool'
    ]
  },
  {
    id: 'premium',
    name: 'Premium',
    price: 19.99,
    interval: 'month',
    features: [
      'All AI models',
      'Unlimited books',
      'Premium chapter generation',
      '24/7 priority support',
      'Advanced tools access',
      'Custom API integration'
    ]
  }
];

const Subscription = () => {
  const theme = useTheme();
  const { user, updateSubscription } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubscribe = async (planId) => {
    try {
      setLoading(true);
      setError(null);
      await updateSubscription(planId);
      setSuccess(`Successfully subscribed to ${planId} plan!`);
    } catch (error) {
      setError('Failed to update subscription. Please try again.');
      console.error('Subscription error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Subscription Plans
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item xs={12} md={4} key={plan.id}>
            <Card 
              variant="outlined"
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                ...(user?.subscription_type === plan.id && {
                  border: `2px solid ${theme.palette.primary.main}`,
                })
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    {plan.name}
                  </Typography>
                  <Typography variant="h4" component="div">
                    ${plan.price}
                    <Typography variant="subtitle1" component="span" color="textSecondary">
                      /{plan.interval}
                    </Typography>
                  </Typography>
                </Box>

                <List>
                  {plan.features.map((feature, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <CheckIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>

              <Box sx={{ p: 2, pt: 0 }}>
                {user?.subscription_type === plan.id ? (
                  <Button
                    fullWidth
                    variant="outlined"
                    disabled
                  >
                    Current Plan
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleSubscribe(plan.id)}
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : `Subscribe to ${plan.name}`}
                  </Button>
                )}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Subscription;