import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  CircularProgress,
  useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../hooks/useAuth';
import DatabaseService from '../../services/DatabaseService';
import BookCard from './BookCard';
import supabase from '../../api/supabase';

const GENRES = [
  'Romance',
  'Mystery',
  'Science Fiction',
  'Fantasy',
  'Thriller',
  'Historical Fiction',
  'Literary Fiction',
  'Horror',
  'Young Adult',
  'Children\'s',
  'Biography',
  'Memoir',
  'Self-Help',
  'Crime',
  'Adventure',
  'Contemporary Fiction',
  'Dystopian',
  'Paranormal',
  'Women\'s Fiction',
  'Literary Nonfiction'
];

const BookSelector = ({ selectedBook, onBookSelect }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    genre: '',
    target_audience: '',
    is_series: false,
    series_name: '',
    series_order: ''
  });

  useEffect(() => {
    fetchBooks();
  }, [user?.id]);

  const fetchBooks = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('books')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setBooks(data || []);
    } catch (err) {
      setError('Failed to load books');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBook = async () => {
    if (!formData.title.trim()) {
      setError('Title is required');
      return;
    }

    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('books')
        .insert([{ ...formData, user_id: user.id }])
        .select()
        .single();

      if (error) throw error;

      setBooks(prev => [data, ...prev]);
      onBookSelect(data);
      setOpenDialog(false);
      setFormData({
        title: '',
        description: '',
        genre: '',
        target_audience: '',
        is_series: false,
        series_name: '',
        series_order: ''
      });
    } catch (err) {
      setError('Failed to create book');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBook = async (bookId) => {
    if (!window.confirm('Are you sure you want to delete this book? This action cannot be undone.')) {
      return;
    }

    try {
      const { error } = await supabase
        .from('books')
        .delete()
        .eq('book_id', bookId);

      if (error) throw error;
      
      setBooks(books.filter(book => book.book_id !== bookId));
      if (selectedBook?.book_id === bookId) {
        onBookSelect(null);
      }
    } catch (err) {
      setError('Failed to delete book');
      console.error('Error:', err);
    }
  };

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h5">
          My Books
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{ borderRadius: 2 }}
        >
          New Book
        </Button>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
          <Grid container spacing={2}>  
            {books.map((book) => (
              <Grid item key={book.book_id}>  
                <BookCard
                  book={book}
                  onSelect={() => onBookSelect(book)}
                  onEdit={() => onBookSelect(book)}
                  onDelete={() => handleDeleteBook(book.book_id)}
                  isSelected={selectedBook?.book_id === book.book_id}
                />
              </Grid>
            ))}
          </Grid>
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle>
          Create New Book
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                required
                error={!formData.title.trim()}
                helperText={!formData.title.trim() && 'Title is required'}
                InputProps={{
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                InputProps={{
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="Genre"
                value={formData.genre}
                onChange={(e) => setFormData(prev => ({ ...prev, genre: e.target.value }))}
                InputProps={{
                  sx: { borderRadius: 2 }
                }}
              >
                {GENRES.map((genre) => (
                  <MenuItem key={genre} value={genre}>
                    {genre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Target Audience"
                value={formData.target_audience}
                onChange={(e) => setFormData(prev => ({ ...prev, target_audience: e.target.value }))}
                InputProps={{
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateBook}
            disabled={loading || !formData.title.trim()}
            sx={{ borderRadius: 2 }}
          >
            {loading ? 'Creating...' : 'Create Book'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookSelector;