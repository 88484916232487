import React from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Slider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme
} from '@mui/material';

const FontSettings = ({ currentTheme, onChange }) => {
  const theme = useTheme();

  const handleFontFamilyChange = (value) => {
    onChange({
      typography: {
        ...currentTheme.typography,
        fontFamily: value
      }
    });
  };

  const handleBaseFontSizeChange = (value) => {
    onChange({
      typography: {
        ...currentTheme.typography,
        fontSize: value
      }
    });
  };

  const commonFonts = [
    '"Secular One", "Roboto", "Arial", sans-serif',
    '"Roboto", "Helvetica", "Arial", sans-serif',
    '"Open Sans", "Helvetica", "Arial", sans-serif',
    '"Lato", "Helvetica", "Arial", sans-serif',
    '"Playfair Display", serif',
    '"Merriweather", serif'
  ];

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Font Settings
      </Typography>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Font Family
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Select Font Family</InputLabel>
          <Select
            value={currentTheme.typography.fontFamily}
            onChange={(e) => handleFontFamilyChange(e.target.value)}
            label="Select Font Family"
          >
            {commonFonts.map((font) => (
              <MenuItem 
                key={font} 
                value={font}
                sx={{ fontFamily: font }}
              >
                <Typography sx={{ fontFamily: font }}>
                  The quick brown fox jumps over the lazy dog
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Custom Font Family"
          value={currentTheme.typography.fontFamily}
          onChange={(e) => handleFontFamilyChange(e.target.value)}
          helperText="Enter a custom font family stack"
          sx={{ mb: 2 }}
        />

        <Typography variant="subtitle2" gutterBottom>
          Base Font Size
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Slider
              value={currentTheme.typography.fontSize}
              onChange={(e, value) => handleBaseFontSizeChange(value)}
              min={12}
              max={20}
              step={1}
              marks
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {currentTheme.typography.fontSize}px
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Preview
        </Typography>
        <Typography 
          sx={{ 
            fontFamily: currentTheme.typography.fontFamily,
            fontSize: currentTheme.typography.fontSize
          }}
        >
          The quick brown fox jumps over the lazy dog.
          This is how your base text will look with the current settings.
        </Typography>
      </Paper>

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Font settings affect the overall readability and style of your text.
          Make sure to test different combinations to find the perfect balance.
        </Typography>
      </Box>
    </Box>
  );
};

export default FontSettings;