import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo2 from '../../assets/images/logo.png';

const Logo = () => {
  return (
    <Link 
      to="/" 
      style={{ 
        textDecoration: 'none', 
        color: 'inherit',
        display: 'flex',
        alignItems: 'right' 
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 75,
        }}
      >
        <img
          src={logo2}
          alt="myPages"
          style={{
            height:'100%',
            width: 'auto'
          }}
        />
      </Box>
    </Link>
  );
};

export default Logo;