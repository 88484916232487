import React, { useState, useEffect } from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  Alert,
  useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import supabase from '../../api/supabase';

const PlotPoints = ({ bookId }) => {
  const theme = useTheme();
  const [plots, setPlots] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    description: '',
    order_number: '',
    conflicts: '',
    resolution: '',
    impact: '',
    characters_involved: '',
    setting_context: '',
    themes: ''
  });

  useEffect(() => {
    fetchPlots();
  }, [bookId]);

  const fetchPlots = async () => {
    try {
      const { data, error } = await supabase
        .from('plots')
        .select('*')
        .eq('book_id', bookId)
        .order('order_number', { ascending: true });

      if (error) throw error;
      setPlots(data);
    } catch (err) {
      setError('Failed to load plot points');
      console.error('Error:', err);
    }
  };

  const handleOpen = (plot = null) => {
    if (plot) {
      setSelectedPlot(plot);
      setFormData(plot);
    } else {
      setSelectedPlot(null);
      setFormData({
        description: '',
        order_number: plots.length + 1,
        conflicts: '',
        resolution: '',
        impact: '',
        characters_involved: '',
        setting_context: '',
        themes: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPlot(null);
    setError(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedPlot) {
        const { error } = await supabase
          .from('plots')
          .update(formData)
          .eq('plot_id', selectedPlot.plot_id);

        if (error) throw error;
        setSuccess('Plot point updated successfully');
      } else {
        const { error } = await supabase
          .from('plots')
          .insert([{ ...formData, book_id: bookId }]);

        if (error) throw error;
        setSuccess('Plot point created successfully');
      }
      
      fetchPlots();
      handleClose();
    } catch (err) {
      setError('Failed to save plot point');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (plotId) => {
    if (!window.confirm('Are you sure you want to delete this plot point?')) return;

    try {
      const { error } = await supabase
        .from('plots')
        .delete()
        .eq('plot_id', plotId);

      if (error) throw error;
      
      setSuccess('Plot point deleted successfully');
      fetchPlots();
    } catch (err) {
      setError('Failed to delete plot point');
      console.error('Error:', err);
    }
  };

  return (
    <>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Plot Points</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Add Plot Point
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      <Timeline position="alternate">
        {plots.sort((a, b) => a.order_number - b.order_number).map((plot, index) => (
          <TimelineItem key={plot.plot_id}>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              {index !== plots.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle1" color="primary">
                      Point {plot.order_number}
                    </Typography>
                    <Box>
                      <IconButton size="small" onClick={() => handleOpen(plot)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleDelete(plot.plot_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Typography variant="body1" paragraph>
                    {plot.description}
                  </Typography>
                  {plot.conflicts && (
                    <Typography variant="body2" color="textSecondary">
                      Conflicts: {plot.conflicts}
                    </Typography>
                  )}
                  {plot.resolution && (
                    <Typography variant="body2" color="textSecondary">
                      Resolution: {plot.resolution}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>

      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedPlot ? 'Edit Plot Point' : 'Add New Plot Point'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Order Number"
              name="order_number"
              type="number"
              value={formData.order_number}
              onChange={handleChange}
              required
            />
            <TextField
              multiline
              rows={4}
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
            <TextField
              multiline
              rows={3}
              label="Conflicts"
              name="conflicts"
              value={formData.conflicts}
              onChange={handleChange}
            />
            <TextField
              multiline
              rows={3}
              label="Resolution"
              name="resolution"
              value={formData.resolution}
              onChange={handleChange}
            />
            <TextField
              multiline
              rows={2}
              label="Impact"
              name="impact"
              value={formData.impact}
              onChange={handleChange}
            />
            <TextField
              label="Characters Involved"
              name="characters_involved"
              value={formData.characters_involved}
              onChange={handleChange}
            />
            <TextField
              label="Setting Context"
              name="setting_context"
              value={formData.setting_context}
              onChange={handleChange}
            />
            <TextField
              label="Themes"
              name="themes"
              value={formData.themes}
              onChange={handleChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Plot Point'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlotPoints;