// src/components/tools/ImageGenerator.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Stack,
  MenuItem,
  Card,
  CardMedia,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  ButtonGroup,
  Tooltip,
  Slider,
  useTheme,
  Switch,
  FormControlLabel,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  PhotoCamera,
  Brush,
  Portrait,
  Landscape,
  Edit,
  Add,
  Image,
  DeleteOutline,
  Save,
  Psychology,
  AutoFixHigh,
  ImageSearch
} from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import supabase from '../../api/supabase';
import AssignImageDialog from './AssignImageDialog';
import EditImageDialog from './EditImageDialog';

const IMAGE_STYLES = [
  { 
    icon: <AutoFixHigh />, 
    label: 'Default', 
    value: 'default',
    description: 'Standard DALL-E style'
  },
  { 
    icon: <PhotoCamera />, 
    label: 'Realistic', 
    value: 'realistic',
    description: 'Photorealistic output'
  },
  { 
    icon: <Brush />, 
    label: 'Artistic', 
    value: 'artistic',
    description: 'Stylized artistic interpretation'
  },
  { 
    icon: <Psychology />, 
    label: 'Abstract', 
    value: 'abstract',
    description: 'Conceptual and abstract results'
  },
  { 
    icon: <ImageSearch />, 
    label: 'Detailed', 
    value: 'detailed',
    description: 'High detail and precision'
  }
];

const GENERATION_SETTINGS = [
  { 
    label: 'Quality', 
    value: 'standard',
    options: ['standard', 'hd'],
    description: 'Higher quality takes longer but produces better results'
  },
  { 
    label: 'Style Weight', 
    value: 7, 
    min: 1, 
    max: 10,
    description: 'How strongly to apply the selected style'
  },
  { 
    label: 'Creativity', 
    value: 7.5, 
    min: 1, 
    max: 20,
    description: 'Higher values produce more unique and varied results'
  },
  { 
    label: 'Detail Level', 
    value: 0.8, 
    min: 0, 
    max: 1,
    description: 'Controls the level of detail in the generated image'
  }
];

const AVAILABLE_MODELS = [
  { value: 'dall-e-2', label: 'DALL-E 2', available: true },
  { value: 'dall-e-3', label: 'DALL-E 3', available: false },
  { value: 'stable-diffusion', label: 'Stable Diffusion', available: false },
  { value: 'midjourney', label: 'Midjourney', available: false }
];
const ImageGenerator = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [selectedModel, setSelectedModel] = useState('dall-e-2');
  const [prompt, setPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [selectedStyle, setSelectedStyle] = useState('default');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState('free');
  const [generationSettings, setGenerationSettings] = useState(
    GENERATION_SETTINGS.reduce((acc, setting) => ({
      ...acc,
      [setting.label]: setting.value
    }), {})
  );

  // Get subscription tier
  useEffect(() => {
    const getSubscriptionTier = async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('subscription_tier')
        .eq('id', user.id)
        .single();

      if (!error && data) {
        setSubscriptionTier(data.subscription_tier);
      }
    };
    getSubscriptionTier();
  }, [user]);

  const handleGenerate = async () => {
    if (!prompt.trim() || loading) return;
  
    try {
      setLoading(true);
      setError(null);
  
      console.log('Sending request with:', {
        prompt,
        type: selectedStyle,
        subscriptionTier
      });
  
      const { data, error } = await supabase.functions.invoke('generate-images', {
        body: {
          prompt,
          type: selectedStyle,
          subscriptionTier
        }
      });
  
      console.log('Response:', { data, error });
  
      if (error) {
        console.error('Generation error:', error);
        throw new Error(typeof error === 'object' ? JSON.stringify(error) : error);
      }
  
      if (!data?.images) {
        throw new Error('No images received from the server');
      }
  
      setGeneratedImages(data.images);
      setSuccess('Images generated successfully!');
    } catch (err) {
      console.error('Complete error:', err);
      setError(err.message || 'Failed to generate images');
    } finally {
      setLoading(false);
    }
  };
  
  const handleSaveToGallery = async (image) => {
    try {
      const fileName = `${Date.now()}.png`;
      const filePath = `${user.id}/gallery/${fileName}`;
  
      // Convert base64 to blob
      const base64Response = await fetch(image.url);
      const blob = await base64Response.blob();
  
      // Upload to Supabase storage
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('images')
        .upload(filePath, blob, {
          contentType: 'image/png'
        });
  
      if (uploadError) throw uploadError;
  
      // Save to gallery table
      const { error: dbError } = await supabase
        .from('gallery')
        .insert([{
          user_id: user.id,
          image_path: filePath,
          prompt: image.prompt,
          type: selectedStyle,
          settings: generationSettings
        }]);
  
      if (dbError) throw dbError;
  
      setSuccess('Image saved to gallery successfully!');
      setGeneratedImages(prev => prev.filter(img => img.url !== image.url));
  
      return filePath;
    } catch (err) {
      console.error('Save error:', err);
      setError('Failed to save image to gallery: ' + err.message);
      throw err;
    }
  };
  
  

  const handleAssignImage = async (image) => {
    try {
      setSelectedImage(image);
      setAssignDialogOpen(true);
    } catch (err) {
      console.error('Assignment error:', err);
      setError('Failed to prepare image for assignment');
    }
  };

  const handleEditImage = (image) => {
    setSelectedImage(image);
    setEditDialogOpen(true);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Settings Panel */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={3}>
              <Typography variant="h6">Generation Settings</Typography>

              {/* Model Selection */}
              <FormControl>
                <InputLabel>AI Model</InputLabel>
                <Select
                  value={selectedModel}
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  {AVAILABLE_MODELS.map((model) => (
                    <MenuItem 
                      key={model.value} 
                      value={model.value}
                      disabled={!model.available}
                    >
                      {model.label} {!model.available && '(Coming Soon)'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Style Selection */}
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Image Style
                </Typography>
                <Grid container spacing={1}>
                  {IMAGE_STYLES.map((style) => (
                    <Grid item key={style.value}>
                      <Tooltip title={style.description}>
                        <IconButton
                          color={selectedStyle === style.value ? 'primary' : 'default'}
                          onClick={() => setSelectedStyle(style.value)}
                        >
                          {style.icon}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* Generation Settings */}
              {GENERATION_SETTINGS.map((setting) => (
                <Box key={setting.label}>
                  <Typography variant="subtitle2" gutterBottom>
                    {setting.label}
                  </Typography>
                  {setting.options ? (
                    <FormControl fullWidth>
                      <Select
                        value={generationSettings[setting.label]}
                        onChange={(e) => setGenerationSettings(prev => ({
                          ...prev,
                          [setting.label]: e.target.value
                        }))}
                      >
                        {setting.options.map(option => (
                          <MenuItem key={option} value={option}>
                            {option.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Slider
                      value={generationSettings[setting.label]}
                      onChange={(_, value) => setGenerationSettings(prev => ({
                        ...prev,
                        [setting.label]: value
                      }))}
                      min={setting.min}
                      max={setting.max}
                      step={0.1}
                      marks
                      valueLabelDisplay="auto"
                    />
                  )}
                  <Typography variant="caption" color="textSecondary">
                    {setting.description}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Paper>
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={9}>
          <Stack spacing={2}>
            {error && (
              <Alert severity="error" onClose={() => setError(null)}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" onClose={() => setSuccess(null)}>
                {success}
              </Alert>
            )}

            {/* Generated Images */}
            <Grid container spacing={2}>
              {loading ? (
                [...Array(4)].map((_, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Paper
                      sx={{
                        height: 300,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularProgress />
                    </Paper>
                  </Grid>
                ))
              ) : generatedImages.length > 0 ? (
                generatedImages.map((image, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="300"
                        image={image.url}
                        alt={`Generated ${index + 1}`}
                      />
                      <CardActions>
                        <Button
                          size="small"
                          startIcon={<Save />}
                          onClick={() => handleSaveToGallery(image.url)}
                        >
                          Save to Gallery
                        </Button>
                        <Button
                          size="small"
                          startIcon={<Image />}
                          onClick={() => handleAssignImage(image)}
                        >
                          Assign
                        </Button>
                        <Button
                          size="small"
                          startIcon={<Edit />}
                          onClick={() => handleEditImage(image)}
                        >
                          Edit
                        </Button>
                        <IconButton
                          size="small"
                          onClick={() => setGeneratedImages(prev =>
                            prev.filter((_, i) => i !== index)
                          )}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))
              ) : (
                [...Array(4)].map((_, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Paper
                      sx={{
                        height: 300,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '2px dashed',
                        borderColor: 'divider'
                      }}
                    >
                      <Typography color="textSecondary">
                        Generated image will appear here
                      </Typography>
                    </Paper>
                  </Grid>
                ))
              )}
            </Grid>

            {/* Prompt Inputs */}
            <Paper sx={{ p: 2 }}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Prompt"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Describe what you want to generate..."
                />
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Negative Prompt"
                  value={negativePrompt}
                  onChange={(e) => setNegativePrompt(e.target.value)}
                  placeholder="Describe what you don't want in the image..."
                />
                <Button
                  variant="contained"
                  onClick={handleGenerate}
                  disabled={loading || !prompt.trim()}
                >
                  {loading ? 'Generating...' : 'Generate Images'}
                </Button>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
      </Grid>

      {/* Dialogs */}
      <AssignImageDialog
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
        imageUrl={selectedImage?.url}
        onSave={() => handleSaveToGallery(selectedImage?.url)}
      />

      <EditImageDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        image={selectedImage}
        onSave={handleSaveToGallery}
      />
    </Box>
  );
};

export default ImageGenerator;
