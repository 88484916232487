// src/components/tools/SceneBuilder/SceneBuilder.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Alert,
  IconButton,
  useTheme
} from '@mui/material';
import {
  Save as SaveIcon,
  Settings as SettingsIcon,
  Download as DownloadIcon,
  Add as AddIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useBooks } from '../../../hooks/useBooks';
import supabase from '../../../api/supabase';

import SceneControls from './SceneControls';
import ParagraphGenerator from './ParagraphGenerator';
import ModelSettings from './ModelSettings';
import SceneAssembly from './SceneAssembly';
import ExportScene from './ExportScene';

const SceneBuilder = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { bookId } = useParams();
  const { books, loading: booksLoading } = useBooks();

  // Core states
  const [selectedBook, setSelectedBook] = useState(null);
  const [availableCharacters, setAvailableCharacters] = useState([]);
  const [scene, setScene] = useState({
    title: '',
    description: '',
    type: 'description',
    pov: '',
    location: '',
    time: '',
    mood: '',
    characters: [],
    keyEvents: [],
    userPhrases: [],
    paragraphs: []
  });

  // UI states
  const [modelSettings, setModelSettings] = useState({
    model: 'gpt-3.5-turbo',
    temperature: 0.7,
    style: 'default',
    tone: 'neutral'
  });
  const [generatedVersions, setGeneratedVersions] = useState([]);
  const [selectedParagraphs, setSelectedParagraphs] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);

  // Load book and characters
  useEffect(() => {
    if (books && books.length > 0) {
      if (bookId) {
        const book = books.find(b => b.book_id === bookId);
        if (book) {
          setSelectedBook(book);
          setAvailableCharacters(book.characters || []);
        }
      }
    }
  }, [books, bookId]);

  const generateParagraphs = async () => {
    try {
      setIsGenerating(true);
      setError(null);

      const { data, error: genError } = await supabase.functions.invoke('scene-generator', {
        body: {
          scene,
          modelSettings,
          count: 4 // Generate 4 versions
        }
      });

      if (genError) throw genError;

      // Make sure we have an array of generations
      if (data && data.generations && Array.isArray(data.generations)) {
        setGeneratedVersions(prev => [...prev, ...data.generations]);
      } else {
        console.error('Invalid response format:', data);
        throw new Error('Invalid response format from generator');
      }

    } catch (err) {
      console.error('Generation error:', err);
      setError('Failed to generate paragraphs. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSaveScene = async () => {
    try {
      const { error: saveError } = await supabase
        .from('scenes')
        .upsert({
          book_id: bookId,
          title: scene.title,
          content: selectedParagraphs,
          metadata: {
            description: scene.description,
            type: scene.type,
            pov: scene.pov,
            location: scene.location,
            time: scene.time,
            mood: scene.mood,
            characters: scene.characters,
            keyEvents: scene.keyEvents,
            userPhrases: scene.userPhrases
          },
          model_settings: modelSettings,
          updated_at: new Date().toISOString()
        });

      if (saveError) throw saveError;

    } catch (err) {
      console.error('Save error:', err);
      setError('Failed to save scene. Please try again.');
    }
  };

  // If no book is selected, show book selection
  if (!bookId) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Select a Book
        </Typography>
        <Grid container spacing={2}>
          {books.map((book) => (
            <Grid item xs={12} sm={6} md={4} key={book.book_id}>
              <Paper
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: 'primary.main',
                    borderWidth: 2,
                    borderStyle: 'solid'
                  }
                }}
                onClick={() => navigate(`/tools/scene-builder/${book.book_id}`)}
              >
                <Typography variant="h6">{book.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {book.characters?.length || 0} characters
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (booksLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, height: '100%' }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}>
          <Typography variant="h6">
            Scene Builder - {selectedBook?.title}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              startIcon={<SettingsIcon />}
              onClick={() => setSettingsOpen(true)}
            >
              Model Settings
            </Button>
            <Button
              startIcon={<SaveIcon />}
              onClick={handleSaveScene}
              disabled={selectedParagraphs.length === 0}
            >
              Save Scene
            </Button>
            <IconButton onClick={() => setExportOpen(true)}>
              <DownloadIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>

      {error && (
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}

      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: '300px 1fr',
        gap: 2,
        height: 'calc(100% - 80px)'
      }}>
        <SceneControls
          scene={scene}
          onChange={setScene}
          onGenerate={generateParagraphs}
          isGenerating={isGenerating}
          availableCharacters={availableCharacters}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <ParagraphGenerator
            versions={generatedVersions || []}
            onSelect={setSelectedParagraphs}
            selectedParagraphs={selectedParagraphs}
            isGenerating={isGenerating}
          />

          <SceneAssembly
            paragraphs={selectedParagraphs}
            onReorder={setSelectedParagraphs}
            onEdit={(index, content) => {
              const newParagraphs = [...selectedParagraphs];
              newParagraphs[index] = content;
              setSelectedParagraphs(newParagraphs);
            }}
          />
        </Box>
      </Box>

      <ModelSettings
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        settings={modelSettings}
        onChange={setModelSettings}
      />

      <ExportScene
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        scene={scene}
        content={selectedParagraphs}
      />
    </Box>
  );
};

export default SceneBuilder;