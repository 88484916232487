// src/components/tools/CharacterChat/CharacterChat.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Alert,
  Avatar,
  Stack,
  IconButton,
  TextField,
  useTheme
} from '@mui/material';
import {
  Send as SendIcon,
  Save as SaveIcon,
  Refresh as RefreshIcon,
  Download as DownloadIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useBooks } from '../../../hooks/useBooks';
import supabase from '../../../api/supabase';

import CharacterProfile from './CharacterProfile';
import ChatInterface from './ChatInterface';
import InsightsPanel from './InsightsPanel';
import ScenarioSetup from './ScenarioSetup';
import ExportDialog from './ExportDialog';

const CharacterChat = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { bookId, characterId } = useParams();
  const { books, loading: booksLoading } = useBooks();

  const [selectedBook, setSelectedBook] = useState(null);
  const [character, setCharacter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scenarioDialogOpen, setScenarioDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [relevantChapters, setRelevantChapters] = useState([]);
  const [chatMode, setChatMode] = useState('author');
  const [scenario, setScenario] = useState(null);
  const [insights, setInsights] = useState([]);

  useEffect(() => {
    const loadBookAndCharacter = async () => {
      try {
        if (books && books.length > 0 && bookId) {
          const book = books.find(b => b.book_id === bookId);
          
          if (book) {
            setSelectedBook(book);

            if (characterId) {
              const { data: characterData, error: characterError } = await supabase
                .from('characters')
                .select(`
                  *,
                  personality_traits,
                  background_story,
                  core_values,
                  fears,
                  desires,
                  internal_conflicts,
                  example_dialogue
                `)
                .eq('character_id', characterId)
                .eq('book_id', bookId)
                .single();

              if (characterError) throw characterError;
              setCharacter(characterData);

              // Load most recent chat session if exists
              const { data: sessionData, error: sessionError } = await supabase
                .from('chat_sessions')
                .select('*')
                .eq('character_id', characterId)
                .order('updated_at', { ascending: false })
                .limit(1)
                .single();

              if (sessionError && sessionError.code !== 'PGRST116') {
                throw sessionError;
              }

              if (sessionData) {
                setCurrentSession(sessionData);
                setMessages(sessionData.messages || []);
                setInsights(sessionData.insights || []);
              }
            }
          } else {
            throw new Error('Book not found');
          }
        }
      } catch (err) {
        console.error('Error loading data:', err);
        setError(err.message);
      }
    };

    loadBookAndCharacter();
  }, [books, bookId, characterId]);

  const handleNewChat = async () => {
    try {
      setMessages([]);
      setInsights([]);
      setCurrentSession(null);
      setScenarioDialogOpen(true);
      
      // Create new chat session
      const { data: session, error } = await supabase
        .from('chat_sessions')
        .insert({
          character_id: characterId,
          book_id: bookId,
          messages: [],
          insights: []
        })
        .select()
        .single();

      if (error) throw error;
      setCurrentSession(session);

    } catch (err) {
      console.error('Error creating new chat:', err);
      setError('Failed to start new chat');
    }
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    try {
      const newMessage = {
        role: 'user',
        content: input,
        timestamp: Date.now()
      };

      setMessages(prev => [...prev, newMessage]);
      setInput('');

      const { data: response, error } = await supabase.functions.invoke('character-chat', {
        body: {
          message: input,
          character,
          history: messages
        }
      });

      if (error) throw error;

      const updatedMessages = [...messages, newMessage, {
        role: 'assistant',
        content: response.message,
        timestamp: Date.now()
      }];

      setMessages(updatedMessages);

      // Update chat session
      if (currentSession) {
        const { error: updateError } = await supabase
          .from('chat_sessions')
          .update({
            messages: updatedMessages,
            updated_at: new Date().toISOString()
          })
          .eq('session_id', currentSession.session_id);

        if (updateError) throw updateError;
      }

    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message. Please try again.');
    }
  };

  const handleSave = async () => {
    if (!currentSession) return;

    try {
      setIsSaving(true);
      
      const { error: saveError } = await supabase
        .from('chat_sessions')
        .update({
          messages,
          insights,
          updated_at: new Date().toISOString()
        })
        .eq('session_id', currentSession.session_id);

      if (saveError) throw saveError;

    } catch (err) {
      console.error('Error saving chat:', err);
      setError('Failed to save chat');
    } finally {
      setIsSaving(false);
    }
  };

  if (!characterId && selectedBook) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Select Character to Chat With
        </Typography>
        <Grid container spacing={2}>
          {selectedBook.characters?.map((char) => (
            <Grid item xs={12} sm={6} md={4} key={char.character_id}>
              <Paper
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: 'primary.main',
                    borderWidth: 2,
                    borderStyle: 'solid'
                  }
                }}
                onClick={() => navigate(`/tools/character-chat/${bookId}/${char.character_id}`)}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                    {char.name?.[0] || '?'}
                  </Avatar>
                  <Box>
                    <Typography variant="h6">{char.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {char.role || 'Character'}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (!bookId) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Select a Book
        </Typography>
        <Grid container spacing={2}>
          {books?.map((book) => (
            <Grid item xs={12} sm={6} md={4} key={book.book_id}>
              <Paper
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: 'primary.main',
                    borderWidth: 2,
                    borderStyle: 'solid'
                  }
                }}
                onClick={() => navigate(`/tools/character-chat/${book.book_id}`)}
              >
                <Typography variant="h6">{book.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {book.characters?.length || 0} characters
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          action={
            <Button color="inherit" size="small" onClick={() => navigate('/tools/character-chat')}>
              Start Over
            </Button>
          }
        >
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '300px 1fr 300px',
      gap: 2,
      p: 2
    }}>
      <CharacterProfile
        character={character}
        onUpdate={setCharacter}
        relevantChapters={relevantChapters}
        onChaptersChange={setRelevantChapters}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center' 
          }}>
            <Typography variant="h6">
              Chat with {character?.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                startIcon={<RefreshIcon />}
                onClick={handleNewChat}
              >
                New Chat
              </Button>
              <Button
                startIcon={<SaveIcon />}
                onClick={handleSave}
                disabled={isSaving || !currentSession}
              >
                Save Chat
              </Button>
              <IconButton onClick={() => setExportDialogOpen(true)}>
                <DownloadIcon />
              </IconButton>
            </Box>
          </Box>
        </Paper>

        <ChatInterface
          messages={messages}
          isLoading={isLoading}
          onUpdateInsight={setInsights}
          character={character}
        />

        <Box sx={{ p: 2, mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            disabled={isLoading}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (input.trim()) {
                  handleSend();
                }
              }
            }}
            InputProps={{
              endAdornment: (
                <IconButton 
                  onClick={(e) => {
                    e.preventDefault();
                    if (input.trim()) {
                      handleSend();
                    }
                  }}
                  disabled={isLoading || !input.trim()}
                >
                  <SendIcon />
                </IconButton>
              )
            }}
          />
        </Box>
      </Box>

      <InsightsPanel
        insights={insights}
        onUpdate={setInsights}
        character={character}
      />

      <ScenarioSetup
        open={scenarioDialogOpen}
        onClose={() => setScenarioDialogOpen(false)}
        onSetup={(settings) => {
          setChatMode(settings.mode);
          setScenario(settings.scenario);
          setRelevantChapters(settings.chapters);
          setScenarioDialogOpen(false);
        }}
        character={character}
      />

      <ExportDialog
        open={exportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        messages={messages}
        insights={insights}
        character={character}
      />
    </Box>
  );
};

export default CharacterChat;