// src/components/tools/CharacterChat/CharacterProfile.js

import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Chip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Stack,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Save as SaveIcon,
  BookmarkBorder as BookmarkIcon,
  Timeline as TimelineIcon,
  Psychology as PsychologyIcon,
  Group as GroupIcon,
  AccessTime as AccessTimeIcon
} from '@mui/icons-material';
import supabase from '../../../api/supabase';

const CharacterProfile = ({
  character,
  onUpdate,
  relevantChapters = [],
  onChaptersChange
}) => {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);
  const [editedCharacter, setEditedCharacter] = useState(null);
  const [editDialog, setEditDialog] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  // If no character, show placeholder
  if (!character) {
    return (
      <Paper sx={{ p: 2, height: '100%', overflow: 'auto' }}>
        <Box sx={{ 
          height: '100%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <Typography color="text.secondary">
            Select a character to view their profile
          </Typography>
        </Box>
      </Paper>
    );
  }

  const initializeEditedCharacter = () => {
    setEditedCharacter({
      ...character,
      personality_traits: Array.isArray(character.personality_traits) ? character.personality_traits : [],
      core_values: Array.isArray(character.core_values) ? character.core_values : [],
      goals: Array.isArray(character.goals) ? character.goals : [],
      fears: Array.isArray(character.fears) ? character.fears : [],
      growth_points: Array.isArray(character.growth_points) ? character.growth_points : []
    });
    setEditMode(true);
  };

  const handleSaveChanges = async () => {
    try {
      setIsSaving(true);
      setError(null);

      const { error: updateError } = await supabase
        .from('characters')
        .update(editedCharacter)
        .eq('character_id', character.character_id);

      if (updateError) throw updateError;

      onUpdate(editedCharacter);
      setEditMode(false);
    } catch (err) {
      console.error('Error saving character:', err);
      setError('Failed to save changes. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleTraitAdd = (traitType) => {
    setEditDialog({
      type: traitType,
      title: `Add ${traitType.charAt(0).toUpperCase() + traitType.slice(1).replace('_', ' ')}`,
      current: Array.isArray(editedCharacter?.[traitType]) ? editedCharacter[traitType] : []
    });
  };

  const handleAddDialogSave = (type, value) => {
    if (!editedCharacter) {
      initializeEditedCharacter();
    }
    setEditedCharacter(prev => ({
      ...prev,
      [type]: Array.isArray(prev[type]) ? [...prev[type], value] : [value]
    }));
    setEditDialog(null);
  };

  const TraitSection = ({ title, traits = [], type, icon: Icon }) => {
    const traitArray = Array.isArray(traits) ? traits : [];
    
    return (
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Icon sx={{ mr: 1, color: 'primary.main' }} />
          <Typography variant="subtitle2">{title}</Typography>
          {editMode && (
            <IconButton 
              size="small" 
              onClick={() => handleTraitAdd(type)}
              sx={{ ml: 'auto' }}
            >
              <AddIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {traitArray.map((trait, index) => (
            <Chip
              key={index}
              label={trait}
              size="small"
              onDelete={editMode ? () => {
                setEditedCharacter(prev => ({
                  ...prev,
                  [type]: prev[type].filter((_, i) => i !== index)
                }));
              } : undefined}
            />
          ))}
          {traitArray.length === 0 && !editMode && (
            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
              No {title.toLowerCase()} defined
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Paper sx={{ p: 2, overflow: 'auto', height: '100%' }}>
      {error && (
        <Box sx={{ mb: 2 }}>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>
      )}

      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Avatar
          sx={{
            width: 120,
            height: 120,
            mx: 'auto',
            mb: 2,
            bgcolor: theme.palette.primary.main
          }}
        >
          {character.name?.[0] || '?'}
        </Avatar>
        
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
          <Typography variant="h6">
            {editMode ? (
              <TextField
                value={editedCharacter?.name || ''}
                onChange={(e) => setEditedCharacter(prev => ({
                  ...prev,
                  name: e.target.value
                }))}
                size="small"
              />
            ) : character.name}
          </Typography>
          <IconButton 
            size="small"
            onClick={() => {
              if (!editMode) {
                initializeEditedCharacter();
              } else {
                setEditMode(false);
              }
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>

        <Typography variant="body2" color="text.secondary">
          {character.role || 'Character'} 
          {character.age && ` • ${character.age}`}
          {character.gender && ` • ${character.gender}`}
        </Typography>
      </Box>

      <Stack spacing={2}>
        <TraitSection
          title="Personality Traits"
          traits={editMode ? editedCharacter?.personality_traits : character.personality_traits}
          type="personality_traits"
          icon={PsychologyIcon}
        />

        <TraitSection
          title="Core Values"
          traits={editMode ? editedCharacter?.core_values : character.core_values}
          type="core_values"
          icon={BookmarkIcon}
        />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TimelineIcon color="primary" />
              <Typography>Character Development</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {editMode ? (
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Character Arc"
                value={editedCharacter?.character_arc || ''}
                onChange={(e) => setEditedCharacter(prev => ({
                  ...prev,
                  character_arc: e.target.value
                }))}
              />
            ) : (
              <Typography variant="body2">
                {character.character_arc || 'No character arc defined'}
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>

        {editMode && (
          <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSaveChanges}
              disabled={isSaving}
              startIcon={<SaveIcon />}
            >
              Save Changes
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setEditedCharacter(null);
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Stack>

      <Dialog
        open={!!editDialog}
        onClose={() => setEditDialog(null)}
      >
        <DialogTitle>
          {editDialog?.title}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={editDialog?.value || ''}
            onChange={(e) => setEditDialog(prev => ({
              ...prev,
              value: e.target.value
            }))}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialog(null)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleAddDialogSave(editDialog.type, editDialog.value)}
            disabled={!editDialog?.value}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default CharacterProfile;