import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  useTheme
} from '@mui/material';
import { ChromePicker } from 'react-color';

const StateColors = ({ currentTheme, onChange }) => {
  const theme = useTheme();
  const [activeState, setActiveState] = React.useState(null);
  const [activeVariant, setActiveVariant] = React.useState(null);

  const handleColorChange = (color) => {
    if (!activeState || !activeVariant) return;
    
    onChange({
      palette: {
        ...currentTheme.palette,
        [activeState]: {
          ...currentTheme.palette[activeState],
          [activeVariant]: color.hex
        }
      }
    });
  };

  const states = [
    { key: 'error', label: 'Error', description: 'For error states and messages' },
    { key: 'warning', label: 'Warning', description: 'For warning states and alerts' },
    { key: 'info', label: 'Info', description: 'For informational messages' },
    { key: 'success', label: 'Success', description: 'For success states and confirmations' }
  ];

  const variants = ['main', 'light', 'dark'];

  const StateBox = ({ stateKey, stateLabel, description }) => (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        mb: 2,
        border: activeState === stateKey ? `2px solid ${theme.palette.primary.main}` : 'none',
      }}
    >
      <Typography variant="subtitle2" gutterBottom>
        {stateLabel}
      </Typography>
      <Alert severity={stateKey} sx={{ mb: 2 }}>
        This is an example {stateKey} alert
      </Alert>
      <Grid container spacing={2}>
        {variants.map((variant) => (
          <Grid item xs={4} key={variant}>
            <Typography variant="caption" display="block" gutterBottom>
              {variant.charAt(0).toUpperCase() + variant.slice(1)}
            </Typography>
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                cursor: 'pointer',
                backgroundColor: currentTheme.palette[stateKey][variant],
                border: activeState === stateKey && activeVariant === variant ? 
                  `2px solid ${theme.palette.primary.main}` : 
                  '2px solid',
                borderColor: 'divider',
              }}
              onClick={() => {
                setActiveState(stateKey);
                setActiveVariant(variant);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        State Colors
      </Typography>
      
      {states.map(state => (
        <StateBox
          key={state.key}
          stateKey={state.key}
          stateLabel={state.label}
          description={state.description}
        />
      ))}

      {activeState && activeVariant && (
        <Box sx={{ mt: 2 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Color Picker - {activeState.charAt(0).toUpperCase() + activeState.slice(1)} {activeVariant}
            </Typography>
            <ChromePicker
              color={currentTheme.palette[activeState][activeVariant]}
              onChange={handleColorChange}
              disableAlpha
              styles={{
                default: {
                  picker: {
                    width: '100%',
                    boxShadow: 'none',
                  }
                }
              }}
            />
          </Paper>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          State colors help users understand the status or outcome of their actions.
          Ensure these colors are distinct and meaningful.
        </Typography>
      </Box>
    </Box>
  );
};

export default StateColors;