import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  Alert,
  useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useBook } from '../../hooks/useBook';
import supabase from '../../api/supabase';

const CharacterSection = ({ bookId }) => {
  const theme = useTheme();
  const [characters, setCharacters] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    gender: '',
    physical_description: '',
    personality_traits: '',
    background_story: '',
    role: '',
    relationships: '',
    development_arc: ''
  });

  useEffect(() => {
    fetchCharacters();
  }, [bookId]);

  const fetchCharacters = async () => {
    try {
      const { data, error } = await supabase
        .from('characters')
        .select('*')
        .eq('book_id', bookId)
        .order('created_at', { ascending: true });

      if (error) throw error;
      setCharacters(data);
    } catch (err) {
      setError('Failed to load characters');
      console.error('Error:', err);
    }
  };

  const handleOpen = (character = null) => {
    if (character) {
      setSelectedCharacter(character);
      setFormData(character);
    } else {
      setSelectedCharacter(null);
      setFormData({
        name: '',
        age: '',
        gender: '',
        physical_description: '',
        personality_traits: '',
        background_story: '',
        role: '',
        relationships: '',
        development_arc: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCharacter(null);
    setError(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedCharacter) {
        const { error } = await supabase
          .from('characters')
          .update(formData)
          .eq('character_id', selectedCharacter.character_id);

        if (error) throw error;
        setSuccess('Character updated successfully');
      } else {
        const { error } = await supabase
          .from('characters')
          .insert([{ ...formData, book_id: bookId }]);

        if (error) throw error;
        setSuccess('Character created successfully');
      }
      
      fetchCharacters();
      handleClose();
    } catch (err) {
      setError('Failed to save character');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (characterId) => {
    if (!window.confirm('Are you sure you want to delete this character?')) return;

    try {
      const { error } = await supabase
        .from('characters')
        .delete()
        .eq('character_id', characterId);

      if (error) throw error;
      
      setSuccess('Character deleted successfully');
      fetchCharacters();
    } catch (err) {
      setError('Failed to delete character');
      console.error('Error:', err);
    }
  };

  return (
    <>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Characters</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Add Character
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {characters.map((character) => (
          <Grid item xs={12} md={6} lg={4} key={character.character_id}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6">{character.name}</Typography>
                  <Box>
                    <IconButton onClick={() => handleOpen(character)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(character.character_id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                {character.role && (
                  <Typography color="textSecondary" gutterBottom>
                    Role: {character.role}
                  </Typography>
                )}
                {character.age && (
                  <Typography color="textSecondary" gutterBottom>
                    Age: {character.age}
                  </Typography>
                )}
                {character.physical_description && (
                  <Typography variant="body2" paragraph>
                    {character.physical_description}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedCharacter ? 'Edit Character' : 'Add New Character'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Age"
                name="age"
                value={formData.age}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Physical Description"
                name="physical_description"
                value={formData.physical_description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Personality Traits"
                name="personality_traits"
                value={formData.personality_traits}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Background Story"
                name="background_story"
                value={formData.background_story}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Role"
                name="role"
                value={formData.role}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Relationships"
                name="relationships"
                value={formData.relationships}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Character Development Arc"
                name="development_arc"
                value={formData.development_arc}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Character'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CharacterSection;