import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField,
  IconButton,
  Paper,
  Stack,
  useTheme,
  styled,
  Container,
  Card,
  CardContent,
  Grid,
  alpha,
  InputAdornment,
  keyframes
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { 
  Chat as ChatIcon,
  AutoStories as BookIcon,
  Brush as ArtIcon,
  PersonAdd as CharacterIcon,
  FormatQuote as DialogueIcon,
  Settings as SettingsIcon,
  Palette as PaletteIcon,
  LibraryAdd as LibraryAddIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import supabase from '../api/supabase';

// Animations
const typewriter = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const blink = keyframes`
  from, to { border-color: transparent }
  50% { border-color: ${props => props.theme.palette.primary.main} }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 10px rgba(255,137,163,0.2) }
  50% { box-shadow: 0 0 40px rgba(255,137,163,0.4) }
  100% { box-shadow: 0 0 10px rgba(255,137,163,0.2) }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Styled Components
const TypewriterText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  border-right: .15em solid;
  letter-spacing: .15em;
  animation: 
    ${typewriter} 3.5s steps(40, end),
    ${blink} .75s step-end infinite;
`;

const StyledInputContainer = styled(Paper)(({ theme }) => ({
    position: 'absolute', // Change from fixed to absolute
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2), // Reduce padding
    background: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(10px)',
    borderTop: `1px solid ${theme.palette.divider}`,
    zIndex: 1000,
  }));

const QuickActionCard = styled(Card)(({ theme, isAIFeature, cardColor }) => ({
  background: alpha(theme.palette.background.paper, 0.9),
  backdropFilter: 'blur(8px)',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  height: '100%',
  position: 'relative',
  border: `3px solid ${cardColor || theme.palette.primary.main}`,
  animation: `${fadeIn} 0.5s ease-out`,
  ...(isAIFeature && {
    animation: `${glow} 2s infinite`,
  }),
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 4px 20px ${alpha(cardColor || theme.palette.primary.main, 0.3)}`,
    '& .card-icon': {
      transform: 'scale(1.1) rotate(5deg)',
    }
  }
}));

const IconContainer = styled(Box)(({ theme, cardColor }) => ({
  position: 'absolute',
  top: -20,
  right: -20,
  width: 60,
  height: 60,
  borderRadius: '50%',
  backgroundColor: cardColor || theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease',
  boxShadow: `0 4px 10px ${alpha(cardColor || theme.palette.primary.main, 0.3)}`,
  '& svg': {
    fontSize: 30,
    color: theme.palette.common.white,
  }
}));

const MessageBubble = styled(Paper)(({ theme, isUser }) => ({
  padding: theme.spacing(2),
  maxWidth: '80%',
  width: 'fit-content',
  marginLeft: isUser ? 'auto' : 0,
  marginRight: isUser ? 0 : 'auto',
  background: isUser ? theme.palette.primary.main : alpha(theme.palette.background.paper, 0.8),
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  backdropFilter: 'blur(8px)',
  borderRadius: 12,
  animation: `${fadeIn} 0.3s ease-out`,
}));

const actionCategories = {
    ai: {
      title: 'Use AI to bring your story to life',
      actions: [
        {
          icon: <ChatIcon />,
          title: 'Chat with Characters',
          description: 'Engage in dynamic conversations with your characters. Understand their motivations, explore their backstories, and develop deeper connections with your creations.',
          path: '/tools/character-chat',
          isAIFeature: true,
          color: '#FF6B6B'
        },
        {
          icon: <DialogueIcon />,
          title: 'Dialogue Generator',
          description: 'Create authentic and engaging dialogue between your characters. Direct their interactions, emotional responses, and conversation flow in real-time.',
          path: '/tools/dialogue',
          isAIFeature: true,
          color: '#4ECDC4'
        },
        {
          icon: <ArtIcon />,
          title: 'Generate Art',
          description: 'Bring your story to life visually with AI-generated illustrations. Create character portraits, scene illustrations, and book covers that capture your vision.',
          path: '/tools/imagemaker',
          color: '#96CEB4'
        }
      ]
    },
    books: {
      title: 'Create or manage your books?',
      actions: [
        {
          icon: <BookIcon />,
          title: 'My Books',
          description: 'Access and manage your collection of books. Review, edit, and organize your works in progress and completed manuscripts.',
          path: '/dashboard',
          color: '#FF9F1C'
        },
        {
          icon: <LibraryAddIcon />,
          title: 'Create New Book',
          description: 'Start your next literary journey. Set up a new book project with guided steps for genre, style, and initial planning.',
          path: '/new-book',
          color: '#7B68EE'
        },
        {
          icon: <CharacterIcon />,
          title: 'Character Manager',
          description: 'Develop and organize your cast of characters. Create detailed profiles, track relationships, and manage character arcs throughout your story.',
          path: '/characters',
          color: '#FF85A2'
        }
      ]
    },
    settings: {
      title: 'Change settings?',
      actions: [
        {
          icon: <PaletteIcon />,
          title: 'Theme Customization',
          description: 'Personalize your writing environment. Customize colors, fonts, and layout to create your perfect writing space.',
          path: '/settings/theme',
          color: '#45B7D1'
        },
        {
          icon: <SettingsIcon />,
          title: 'AI Settings',
          description: 'Fine-tune your AI assistance. Adjust writing style, response length, and creativity levels to match your preferences.',
          path: '/settings/ai',
          color: '#98A8F8'
        }
      ]
    }
  };
  
  const WelcomeScreen = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const messagesEndRef = useRef(null);
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
  
    const handleSend = async () => {
      if (!input.trim() || loading) return;
  
      try {
        setLoading(true);
        setMessages(prev => [...prev, { text: input, isUser: true }]);
        setInput('');
  
        const { data, error } = await supabase.functions.invoke('chat', {
          body: { message: input }
        });
  
        if (error) throw error;
  
        setMessages(prev => [...prev, { text: data.response, isUser: false }]);
      } catch (error) {
        console.error('Chat error:', error);
      } finally {
        setLoading(false);
      }
    };
  
    return (
        <Box
        sx={{
            position: 'fixed', // Add this
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden', // Add this
            backgroundColor: 'grey.50',
            backgroundImage: `
            url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FF89A3' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
            `,
        }}
        >
        <Container maxWidth="md">
          {messages.length === 0 ? (
            <>
            <Box sx={{ textAlign: 'center', mb: 6, mt: 8 }}>
            <Typography
                variant="h2"
                component="h1"
                sx={{
                fontFamily: 'Playfair Display',
                fontSize: { xs: '2rem', md: '3.5rem' }, // Reduce font sizes
                mb: 2, // Reduce margin
                background: 'linear-gradient(45deg, #FF89A3 30%, #FFB6C1 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 700
                }}
            >
                Welcome to myPages
            </Typography>
            <Typography
                variant="h4"
                sx={{ 
                mb: 4, // Reduce margin
                color: 'text.secondary',
                fontWeight: 300,
                fontSize: { xs: '1.25rem', md: '1.75rem' } // Reduce font sizes
                }}
            >
                Your AI-powered writing companion
            </Typography>
            </Box>
  
              {Object.entries(actionCategories).map(([key, category]) => (
                <Box key={key} sx={{ mb: 6 }}>
                  {key === 'ai' ? (
                    <TypewriterText
                      variant="h5"
                      color="primary"
                      align="center"
                      sx={{ mb: 3 }}
                    >
                      {category.title}
                    </TypewriterText>
                  ) : (
                    <Typography
                      variant="h5"
                      color="primary"
                      align="center"
                      sx={{ mb: 3 }}
                    >
                      {category.title}
                    </Typography>
                  )}
                  <Grid container spacing={3}>
                    {category.actions.map((action) => (
                      <Grid item xs={12} sm={6} md={4} key={action.title}>
                        <QuickActionCard 
                          onClick={() => navigate(action.path)}
                          isAIFeature={action.isAIFeature}
                          cardColor={action.color}
                        >
                          <CardContent sx={{ pt: 3, px: 2, pb: 3 }}>
                            <IconContainer className="card-icon" cardColor={action.color}>
                              {action.icon}
                            </IconContainer>
                            <Typography 
                              variant="h6" 
                              gutterBottom 
                              sx={{ 
                                mb: 2,
                                color: action.color,
                                fontWeight: 600,
                                fontSize: '1.25rem'
                              }}
                            >
                              {action.title}
                            </Typography>
                            <Typography 
                              variant="body2" 
                              color="text.secondary"
                              sx={{ 
                                lineHeight: 1.6,
                                minHeight: 80,
                                fontSize: '0.95rem'
                              }}
                            >
                              {action.description}
                            </Typography>
                          </CardContent>
                        </QuickActionCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </>
          ) : (
            <Stack spacing={2}>
              {messages.map((message, index) => (
                <MessageBubble key={index} isUser={message.isUser}>
                  <Typography>{message.text}</Typography>
                </MessageBubble>
              ))}
              <div ref={messagesEndRef} />
            </Stack>
          )}
        </Container>
  
        <StyledInputContainer elevation={0}>
          <Container maxWidth="md">
            <TextField
              fullWidth
              placeholder={messages.length === 0 ? 
                "Type 'help' to see what I can do, or click one of the cards above" : 
                "Type your message..."}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      onClick={handleSend}
                      disabled={loading || !input.trim()}
                      color="primary"
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Container>
        </StyledInputContainer>
      </Box>
    );
  };
  
  export default WelcomeScreen;