import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  Alert,
  useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import supabase from '../../api/supabase';

const PlacesEvents = ({ bookId }) => {
  const theme = useTheme();
  const [settings, setSettings] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    location: '',
    description: '',
    emotional_significance: '',
    time_period: '',
    weather_climate: '',
    cultural_significance: '',
    historical_context: ''
  });

  useEffect(() => {
    fetchSettings();
  }, [bookId]);

  const fetchSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('settings')
        .select('*')
        .eq('book_id', bookId)
        .order('created_at', { ascending: true });

      if (error) throw error;
      setSettings(data);
    } catch (err) {
      setError('Failed to load settings');
      console.error('Error:', err);
    }
  };

  const handleOpen = (setting = null) => {
    if (setting) {
      setSelectedSetting(setting);
      setFormData(setting);
    } else {
      setSelectedSetting(null);
      setFormData({
        location: '',
        description: '',
        emotional_significance: '',
        time_period: '',
        weather_climate: '',
        cultural_significance: '',
        historical_context: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSetting(null);
    setError(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedSetting) {
        const { error } = await supabase
          .from('settings')
          .update(formData)
          .eq('setting_id', selectedSetting.setting_id);

        if (error) throw error;
        setSuccess('Setting updated successfully');
      } else {
        const { error } = await supabase
          .from('settings')
          .insert([{ ...formData, book_id: bookId }]);

        if (error) throw error;
        setSuccess('Setting created successfully');
      }
      
      fetchSettings();
      handleClose();
    } catch (err) {
      setError('Failed to save setting');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (settingId) => {
    if (!window.confirm('Are you sure you want to delete this setting?')) return;

    try {
      const { error } = await supabase
        .from('settings')
        .delete()
        .eq('setting_id', settingId);

      if (error) throw error;
      
      setSuccess('Setting deleted successfully');
      fetchSettings();
    } catch (err) {
      setError('Failed to delete setting');
      console.error('Error:', err);
    }
  };

  return (
    <>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Places & Settings</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Add Setting
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {settings.map((setting) => (
          <Grid item xs={12} md={6} key={setting.setting_id}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6">{setting.location}</Typography>
                  <Box>
                    <IconButton onClick={() => handleOpen(setting)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(setting.setting_id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                {setting.time_period && (
                  <Typography color="textSecondary" gutterBottom>
                    Time Period: {setting.time_period}
                  </Typography>
                )}
                {setting.description && (
                  <Typography variant="body2" paragraph>
                    {setting.description}
                  </Typography>
                )}
                {setting.emotional_significance && (
                  <Typography variant="body2" color="textSecondary">
                    Emotional Significance: {setting.emotional_significance}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedSetting ? 'Edit Setting' : 'Add New Setting'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Time Period"
                name="time_period"
                value={formData.time_period}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Emotional Significance"
                name="emotional_significance"
                value={formData.emotional_significance}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Weather/Climate"
                name="weather_climate"
                value={formData.weather_climate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Cultural Significance"
                name="cultural_significance"
                value={formData.cultural_significance}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Historical Context"
                name="historical_context"
                value={formData.historical_context}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Setting'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlacesEvents;
