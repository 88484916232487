import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme
} from '@mui/material';
import { ChromePicker } from 'react-color';

const SecondaryColors = ({ currentTheme, onChange }) => {
  const theme = useTheme();
  const [activeColor, setActiveColor] = React.useState(null);

  const handleColorChange = (color) => {
    if (!activeColor) return;
    
    onChange({
      palette: {
        ...currentTheme.palette,
        secondary: {
          ...currentTheme.palette.secondary,
          [activeColor]: color.hex
        }
      }
    });
  };

  const ColorBox = ({ colorKey, label }) => (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        mb: 2,
        border: activeColor === colorKey ? `2px solid ${theme.palette.primary.main}` : 'none',
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {label}
          </Typography>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              cursor: 'pointer',
              backgroundColor: currentTheme.palette.secondary[colorKey],
              border: '2px solid',
              borderColor: 'divider',
            }}
            onClick={() => setActiveColor(colorKey)}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" display="block" gutterBottom>
            Current Value:
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
            {currentTheme.palette.secondary[colorKey]}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Secondary Color Palette
      </Typography>
      
      <ColorBox colorKey="main" label="Main Color" />
      <ColorBox colorKey="light" label="Light Variant" />
      <ColorBox colorKey="dark" label="Dark Variant" />

      {activeColor && (
        <Box sx={{ mt: 2 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Color Picker
            </Typography>
            <ChromePicker
              color={currentTheme.palette.secondary[activeColor]}
              onChange={handleColorChange}
              disableAlpha
              styles={{
                default: {
                  picker: {
                    width: '100%',
                    boxShadow: 'none',
                  }
                }
              }}
            />
          </Paper>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Click on any color box to edit. Changes will be reflected immediately across the theme.
        </Typography>
      </Box>
    </Box>
  );
};

export default SecondaryColors;