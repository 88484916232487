import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Chip,
  Tooltip,
  Alert,
  Switch,
  FormControlLabel,
  Collapse,
  useTheme
} from '@mui/material';
import {
  TipsAndUpdates as TipsIcon,
  Psychology as StyleIcon,
  Speed as SpeedIcon,
  Tune as TuneIcon,
  Lock as LockIcon
} from '@mui/icons-material';

const MODEL_OPTIONS = [
  {
    id: 'gpt-3.5-turbo',
    name: 'GPT-3.5 Turbo',
    description: 'Fast and efficient, good for most scenes',
    subscription: 'basic'
  },
  {
    id: 'gpt-4',
    name: 'GPT-4',
    description: 'More nuanced and creative, better for complex scenes',
    subscription: 'premium'
  },
  {
    id: 'claude-2',
    name: 'Claude 2',
    description: 'Excellent for literary and descriptive writing',
    subscription: 'premium'
  }
];

const STYLE_PRESETS = [
  {
    id: 'default',
    name: 'Balanced',
    description: 'Natural, balanced writing style',
    settings: { temperature: 0.7, presencePenalty: 0.6, frequencyPenalty: 0.3 }
  },
  {
    id: 'creative',
    name: 'Creative',
    description: 'More varied and imaginative output',
    settings: { temperature: 0.9, presencePenalty: 0.8, frequencyPenalty: 0.4 }
  },
  {
    id: 'consistent',
    name: 'Consistent',
    description: 'More predictable and focused output',
    settings: { temperature: 0.5, presencePenalty: 0.3, frequencyPenalty: 0.2 }
  },
  {
    id: 'detailed',
    name: 'Detailed',
    description: 'Rich in description and detail',
    settings: { temperature: 0.8, presencePenalty: 0.7, frequencyPenalty: 0.5 }
  }
];

const TONE_PRESETS = [
  'Literary', 'Conversational', 'Formal', 'Poetic', 
  'Minimalist', 'Dramatic', 'Atmospheric', 'Technical'
];

const ModelSettings = ({
  open,
  onClose,
  settings,
  onChange,
  userSubscription = 'basic'
}) => {
  const theme = useTheme();
  const [localSettings, setLocalSettings] = useState(settings);
  const [advancedMode, setAdvancedMode] = useState(false);

  const handleChange = (field, value) => {
    setLocalSettings(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePresetSelect = (preset) => {
    setLocalSettings(prev => ({
      ...prev,
      ...preset.settings
    }));
  };

  const handleSave = () => {
    onChange(localSettings);
    onClose();
  };

  const isModelAvailable = (model) => {
    if (model.subscription === 'basic') return true;
    return userSubscription === 'premium';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TuneIcon />
          <Typography variant="h6">
            Generation Settings
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {/* Model Selection */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Language Model
            </Typography>
            <Stack spacing={1}>
              {MODEL_OPTIONS.map((model) => (
                <Box
                  key={model.id}
                  sx={{
                    p: 2,
                    border: `1px solid ${
                      localSettings.model === model.id ?
                        theme.palette.primary.main :
                        theme.palette.divider
                    }`,
                    borderRadius: 1,
                    cursor: isModelAvailable(model) ? 'pointer' : 'default',
                    opacity: isModelAvailable(model) ? 1 : 0.6,
                    '&:hover': isModelAvailable(model) ? {
                      borderColor: theme.palette.primary.main
                    } : {}
                  }}
                  onClick={() => {
                    if (isModelAvailable(model)) {
                      handleChange('model', model.id);
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Typography variant="subtitle2">
                      {model.name}
                    </Typography>
                    {!isModelAvailable(model) && (
                      <Tooltip title="Premium feature">
                        <LockIcon fontSize="small" color="action" />
                      </Tooltip>
                    )}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {model.description}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>

          {/* Style Presets */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Writing Style
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {STYLE_PRESETS.map((preset) => (
                <Chip
                  key={preset.id}
                  label={preset.name}
                  onClick={() => handlePresetSelect(preset)}
                  color={localSettings.style === preset.id ? 
                    "primary" : "default"}
                  variant={localSettings.style === preset.id ? 
                    "filled" : "outlined"}
                />
              ))}
            </Box>
          </Box>

          {/* Tone Selection */}
          <FormControl fullWidth>
            <InputLabel>Tone</InputLabel>
            <Select
              value={localSettings.tone}
              onChange={(e) => handleChange('tone', e.target.value)}
              label="Tone"
            >
              {TONE_PRESETS.map((tone) => (
                <MenuItem key={tone} value={tone.toLowerCase()}>
                  {tone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Advanced Settings */}
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={advancedMode}
                  onChange={(e) => setAdvancedMode(e.target.checked)}
                />
              }
              label="Advanced Settings"
            />

            <Collapse in={advancedMode}>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Box>
                  <Typography gutterBottom>
                    Temperature (Creativity)
                  </Typography>
                  <Slider
                    value={localSettings.temperature}
                    onChange={(_, value) => handleChange('temperature', value)}
                    min={0}
                    max={1}
                    step={0.1}
                    marks
                    valueLabelDisplay="auto"
                  />
                </Box>

                <Box>
                  <Typography gutterBottom>
                    Presence Penalty
                  </Typography>
                  <Slider
                    value={localSettings.presencePenalty}
                    onChange={(_, value) => handleChange('presencePenalty', value)}
                    min={0}
                    max={2}
                    step={0.1}
                    marks
                    valueLabelDisplay="auto"
                  />
                </Box>

                <Box>
                  <Typography gutterBottom>
                    Frequency Penalty
                  </Typography>
                  <Slider
                    value={localSettings.frequencyPenalty}
                    onChange={(_, value) => handleChange('frequencyPenalty', value)}
                    min={0}
                    max={2}
                    step={0.1}
                    marks
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Stack>
            </Collapse>
          </Box>

          {userSubscription !== 'premium' && (
            <Alert severity="info">
              Upgrade to Premium to access additional models and features.
            </Alert>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
        >
          Apply Settings
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModelSettings;