import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Slider,
  Card,
  CardContent,
  useTheme
} from '@mui/material';

const CardStyles = ({ currentTheme, onChange }) => {
  const theme = useTheme();

  const handleStyleChange = (property, value) => {
    onChange({
      components: {
        ...currentTheme.components,
        MuiCard: {
          styleOverrides: {
            root: {
              ...currentTheme.components?.MuiCard?.styleOverrides?.root,
              [property]: value
            }
          }
        }
      }
    });
  };

  const handleShadowChange = (value) => {
    handleStyleChange('boxShadow', `0px ${value}px ${value * 2}px rgba(0, 0, 0, 0.1)`);
  };

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Card Styles
      </Typography>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Card Settings
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption">Border Radius</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={currentTheme.components?.MuiCard?.styleOverrides?.root?.borderRadius || 4}
                  onChange={(e, value) => handleStyleChange('borderRadius', value)}
                  min={0}
                  max={24}
                  step={1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {currentTheme.components?.MuiCard?.styleOverrides?.root?.borderRadius || 4}px
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">Shadow Depth</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={parseInt(currentTheme.components?.MuiCard?.styleOverrides?.root?.boxShadow?.split('px')[0]) || 0}
                  onChange={(e, value) => handleShadowChange(value)}
                  min={0}
                  max={24}
                  step={1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {parseInt(currentTheme.components?.MuiCard?.styleOverrides?.root?.boxShadow?.split('px')[0]) || 0}px
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Preview
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Sample Card
                </Typography>
                <Typography variant="body2">
                  This is how your cards will look with the current settings.
                  Check the shadow depth and border radius.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Card styles affect the appearance of all card components in your application.
          Balance between elevation and subtlety for the best visual hierarchy.
        </Typography>
      </Box>
    </Box>
  );
};

export default CardStyles;