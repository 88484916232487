// src/components/tools/calibrator/TextGeneration.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  Chip,
  useTheme
} from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
import supabase from '../../../api/supabase';

const presetPrompts = [
  {
    id: 'detective_sarah',
    label: 'Detective Character Study',
    prompt: `Write a character introduction for Detective Sarah Chen:
- Experienced homicide detective in her late thirties
- Known for solving seemingly impossible cases
- Has a photographic memory and keen eye for micro-expressions
- Carries her grandfather's pocket watch as a lucky charm
- Struggles with insomnia due to cases she can't solve
Please describe her physical appearance and personality, incorporating these details.`
  },
  {
    id: 'coffee_shop_scene',
    label: 'Coffee Shop Scene',
    prompt: `Describe this specific coffee shop scene:
- Located in a converted Victorian townhouse
- Early morning, just after sunrise
- Regular customer Maria works on her novel
- Barista Tom knows everyone's usual order
- Old jazz music plays softly in the background
- Smell of freshly baked croissants fills the air
Describe the atmosphere and details of this moment.`
  },
  {
    id: 'dialogue_siblings',
    label: 'Sibling Dialogue',
    prompt: `Write a dialogue between siblings Alex and Jamie:
- They're cleaning out their childhood home
- Found an old family photo album
- Alex is practical and wants to finish quickly
- Jamie is nostalgic and keeps getting distracted
- Their mother passed away recently
Create a conversation that reveals their different personalities and shared grief.`
  },
  {
    id: 'custom',
    label: 'Custom Prompt (Follow Format)',
    prompt: '',
    placeholder: `For best results, structure your prompt like this:

Task description:
- Key detail 1
- Key detail 2
- Key detail 3
- Key detail 4
- Key detail 5
Specific instructions for what to include.`
  }
];

const TextGeneration = ({ calibrationData, setCalibrationData }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      setSession(currentSession);
    };
    getSession();
  }, []);

  const generateTexts = async () => {
    try {
      setLoading(true);
      setError(null);

      console.log('Generating variations with parameter:', calibrationData.selectedParameter);

      const selectedPromptContent = presetPrompts.find(
        p => p.id === calibrationData.selectedPrompt
      )?.prompt || calibrationData.customPrompt;

      const { data, error } = await supabase.functions.invoke('calibrator-initial', {
        body: {
          prompt: selectedPromptContent,
          parameter: calibrationData.selectedParameter,
          testValues: calibrationData.generationSettings.testValues,
          user_id: user.id
        },
        headers: {
          Authorization: `Bearer ${session?.access_token}`
        }
      });

      if (error) throw error;

      setCalibrationData(prev => ({
        ...prev,
        generatedTexts: data.variations,
        feedbackId: data.feedback_id
      }));
    } catch (err) {
      console.error('Generation error:', err);
      setError(`Failed to generate text variations: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (calibrationData.selectedParameter && 
        (calibrationData.selectedPrompt || calibrationData.customPrompt) &&
        session) {
      generateTexts();
    }
  }, [session]);

  const getParameterValueLabel = (index) => {
    if (!calibrationData.generationSettings?.testValues) return '';
    const value = calibrationData.generationSettings.testValues[index];
    return `${value.label} (${value.value})`;
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Generated Variations
      </Typography>

      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Calibrating: {calibrationData.selectedParameter}
      </Typography>

      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          action={
            <Button color="inherit" size="small" onClick={generateTexts}>
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
          <CircularProgress sx={{ mb: 2 }} />
          <Typography>
            Generating text variations using GPT-4-mini...
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {calibrationData.generatedTexts.map((text, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card>
                <CardContent>
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle1">
                      Variation {index + 1}
                    </Typography>
                    <Chip 
                      label={getParameterValueLabel(index)}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  </Box>
                  <Typography variant="body1" sx={{ 
                    whiteSpace: 'pre-wrap',
                    backgroundColor: theme.palette.background.default,
                    p: 2,
                    borderRadius: 1
                  }}>
                    {text.content}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="caption" color="textSecondary">
                      Generated with {calibrationData.selectedParameter} = {
                        calibrationData.generationSettings.testValues[index].value
                      }
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default TextGeneration;