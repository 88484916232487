import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Switch,
  Button,
  TextField,
  Box,
  Alert,
  useTheme
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';

const integrations = [
  {
    id: 'dropbox',
    name: 'Dropbox',
    description: 'Sync your books and chapters with Dropbox',
    requiresKey: false,
  },
  {
    id: 'google_drive',
    name: 'Google Drive',
    description: 'Backup your content to Google Drive',
    requiresKey: false,
  },
  {
    id: 'custom_api',
    name: 'Custom API',
    description: 'Connect to your own API endpoint',
    requiresKey: true,
  },
];

const Integrations = () => {
  const theme = useTheme();
  const { user, updateUserSettings } = useAuth();
  const [settings, setSettings] = useState(user?.settings?.integrations || {});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleToggle = (integrationId) => {
    setSettings(prev => ({
      ...prev,
      [integrationId]: {
        ...prev[integrationId],
        enabled: !prev[integrationId]?.enabled
      }
    }));
  };

  const handleKeyChange = (integrationId, value) => {
    setSettings(prev => ({
      ...prev,
      [integrationId]: {
        ...prev[integrationId],
        apiKey: value
      }
    }));
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccess(false);

      await updateUserSettings({
        ...user.settings,
        integrations: settings
      });

      setSuccess(true);
    } catch (error) {
      setError('Failed to update integrations. Please try again.');
      console.error('Integrations error:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Integrations
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          Connect your account with external services to enhance your writing experience.
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Integrations updated successfully!
          </Alert>
        )}

        <Grid container spacing={3}>
          {integrations.map((integration) => (
            <Grid item xs={12} key={integration.id}>
              <Card variant="outlined">
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Box>
                      <Typography variant="subtitle1">
                        {integration.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {integration.description}
                      </Typography>
                    </Box>
                    <Switch
                      checked={settings[integration.id]?.enabled || false}
                      onChange={() => handleToggle(integration.id)}
                    />
                  </Box>

                  {integration.requiresKey && settings[integration.id]?.enabled && (
                    <TextField
                      fullWidth
                      label="API Key"
                      type="password"
                      value={settings[integration.id]?.apiKey || ''}
                      onChange={(e) => handleKeyChange(integration.id, e.target.value)}
                      size="small"
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Integrations;