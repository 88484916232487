import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Alert,
  useTheme
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';

const models = {
  free: [
    { id: 'gpt4', name: 'GPT-4', description: 'Latest GPT model with enhanced capabilities' },
  ],
  standard: [
    { id: 'gpt4', name: 'GPT-4', description: 'Latest GPT model with enhanced capabilities' },
    { id: 'claude', name: 'Claude', description: 'Anthropic\'s advanced language model' },
  ],
  premium: [
    { id: 'gpt4', name: 'GPT-4', description: 'Latest GPT model with enhanced capabilities' },
    { id: 'claude', name: 'Claude', description: 'Anthropic\'s advanced language model' },
    { id: 'palm', name: 'PaLM', description: 'Google\'s advanced language model' },
    { id: 'llama', name: 'LLaMA', description: 'Meta\'s open source language model' },
  ],
};

const ModelSelection = () => {
  const theme = useTheme();
  const { user, updateUserSettings } = useAuth();
  const [selectedModel, setSelectedModel] = useState(user?.settings?.preferred_model || 'gpt4');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const availableModels = models[user?.subscription_type || 'free'];

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccess(false);

      await updateUserSettings({
        ...user.settings,
        preferred_model: selectedModel
      });

      setSuccess(true);
    } catch (error) {
      setError('Failed to update model selection. Please try again.');
      console.error('Model selection error:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          AI Model Selection
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          Choose your preferred AI model for content generation. 
          {user?.subscription_type !== 'premium' && (
            ' Upgrade to Premium for access to all models.'
          )}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Model preference updated successfully!
          </Alert>
        )}

        <RadioGroup
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
        >
          <Grid container spacing={3}>
            {availableModels.map((model) => (
              <Grid item xs={12} md={6} key={model.id}>
                <Card variant="outlined">
                  <CardContent>
                    <FormControlLabel
                      value={model.id}
                      control={<Radio />}
                      label={
                        <Box>
                          <Typography variant="subtitle1">
                            {model.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {model.description}
                          </Typography>
                        </Box>
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={saving || selectedModel === user?.settings?.preferred_model}
          >
            {saving ? 'Saving...' : 'Save Preferences'}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ModelSelection;