import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a83663',
      light: '#FFA5B9',
      dark: '#E66D87',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#A5D7E8',
      light: '#C4E3F0',
      dark: '#89B9CC',
      contrastText: '#333333'
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
      accent: '#FFF5F7'
    },
    text: {
      primary: '#2D3748',
      secondary: '#718096',
      disabled: '#A0AEC0'
    },
    error: {
      main: '#D47D6A',
      light: '#E29B8C',
      dark: '#B35F4D',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#E6B655',
      light: '#ECC77D',
      dark: '#C49838',
      contrastText: '#000000'
    },
    success: {
      main: '#8BA888',
      light: '#A6BBA3',
      dark: '#6F896C',
      contrastText: '#FFFFFF'
    },
    divider: 'rgba(0, 0, 0, 0.12)'
  },
  typography: {
    fontFamily: '"Quicksand", "Roboto", "Arial", sans-serif',
    h1: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: 700,
      fontSize: '2.5rem',
      letterSpacing: '0.02em'
    },
    h2: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: 700,
      fontSize: '2rem',
      letterSpacing: '0.02em'
    },
    h3: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: 600,
      fontSize: '1.75rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.25rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem'
    },
    subtitle1: {
      fontSize: '1.125rem',
      fontWeight: 500
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
      fontSize: '0.875rem'
    },
    caption: {
      fontSize: '0.75rem'
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.08em'
    }
  },
  shape: {
    borderRadius: 2
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 2,
          padding: '8px 16px',
          fontWeight: 600,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        contained: {
          backgroundColor: '',
          '&:hover': {
            backgroundColor: '#E66D87'
          }
        },
        outlined: {
          borderColor: '#FF89A3',
          '&:hover': {
            borderColor: '#E66D87',
            backgroundColor: 'rgba(255, 137, 163, 0.04)'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
          border: '1px solid rgba(0,0,0,0.05)'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 2
        },
        elevation1: {
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            '&:hover fieldset': {
              borderColor: '#FF89A3'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#FF89A3'
            }
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          fontWeight: 600
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 2
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 2
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF89A3',
          color: '#FFFFFF'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(45, 55, 72, 0.9)',
          fontSize: '0.75rem',
          borderRadius: 2
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '8px'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }
      }
    }
  }
});

export default theme;