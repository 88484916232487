// src/App.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useAuth } from './hooks/useAuth';
import Sidebar from './components/layout/Sidebar';
import ThemeCustomizerDrawer from './components/theme/ThemeCustomizerDrawer';

// Route Components
import Dashboard from './components/dashboard/Dashboard';
import UserProfile from './components/profile/UserProfile';
import Subscription from './components/billing/Subscription';
import Help from './components/support/Help';
import ModelSelection from './components/settings/ModelSelection';
import Integrations from './components/settings/Integrations';
import ImageGenerator from './components/tools/ImageGenerator';
import Gallery from './components/tools/Gallery';
import Calibrator from './components/tools/Calibrator';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ProtectedRoute from './components/auth/ProtectedRoute';
import WelcomeScreen from './components/WelcomeScreen';
import CharacterChat from './components/tools/CharacterChat/CharacterChat';
import SceneBuilder from './components/tools/SceneBuilder/SceneBuilder';

const App = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '100vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Public routes (login, register)
  if (!user) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  // Protected routes (main app)
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Logo and Sidebar */}
      <Box 
        component="nav"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <Sidebar />
      </Box>

      {/* Main content area */}
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          minHeight: '100vh',
          ml: '280px', // Sidebar width
          pt: '65px', // TopAppBar height + Logo height
          backgroundColor: (theme) => theme.palette.background.default
        }}
      >
    

        {/* Routes */}
        <Box sx={{ p: 2 }}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            
            {/* Main Routes */}
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route path="/welcome" element={<WelcomeScreen />} />
            <Route 
              path="/profile" 
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/billing" 
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              } 
            />

            {/* Settings Routes */}
            <Route 
              path="/settings/model" 
              element={
                <ProtectedRoute>
                  <ModelSelection />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/settings/integrations" 
              element={
                <ProtectedRoute>
                  <Integrations />
                </ProtectedRoute>
              } 
            />

            {/* Tools Routes */}
            <Route 
              path="/tools/imagemaker" 
              element={
                <ProtectedRoute>
                  <ImageGenerator />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/gallery" 
              element={
                <ProtectedRoute>
                  <Gallery />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/calibrator" 
              element={
                <ProtectedRoute>
                  <Calibrator />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/character-chat" 
              element={
                <ProtectedRoute>
                  <CharacterChat />
                </ProtectedRoute>
              } 
            />
                        <Route 
              path="/tools/character-chat/:bookId" 
              element={
                <ProtectedRoute>
                  <CharacterChat />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/character-chat/:bookId/:characterId" 
              element={
                <ProtectedRoute>
                  <CharacterChat />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/scene-builder" 
              element={
                <ProtectedRoute>
                  <SceneBuilder />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/scene-builder/:bookId" 
              element={
                <ProtectedRoute>
                  <SceneBuilder />
                </ProtectedRoute>
              } 
            />

            {/* Help Route */}
            <Route 
              path="/support" 
              element={
                <ProtectedRoute>
                  <Help />
                </ProtectedRoute>
              } 
            />

            {/* Catch all redirect */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Box>
      </Box>

      {/* Theme Customizer Drawer */}
      <ThemeCustomizerDrawer />
    </Box>
  );
};

export default App;