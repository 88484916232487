import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme
} from '@mui/material';

const TextStyles = ({ currentTheme, onChange }) => {
  const theme = useTheme();

  const handleTextChange = (variant, property, value) => {
    onChange({
      typography: {
        ...currentTheme.typography,
        [variant]: {
          ...currentTheme.typography[variant],
          [property]: value
        }
      }
    });
  };

  const textVariants = [
    { key: 'body1', label: 'Body 1 - Primary Text' },
    { key: 'body2', label: 'Body 2 - Secondary Text' },
    { key: 'subtitle1', label: 'Subtitle 1' },
    { key: 'subtitle2', label: 'Subtitle 2' },
    { key: 'caption', label: 'Caption' },
    { key: 'overline', label: 'Overline' }
  ];

  const TextVariantControl = ({ variant, label }) => (
    <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption">Font Size</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                value={parseFloat(currentTheme.typography[variant].fontSize)}
                onChange={(e, value) => handleTextChange(variant, 'fontSize', `${value}rem`)}
                min={0.5}
                max={2}
                step={0.1}
                marks
                valueLabelDisplay="auto"
                valueLabelFormat={value => `${value}rem`}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {currentTheme.typography[variant].fontSize}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">Line Height</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                value={currentTheme.typography[variant].lineHeight}
                onChange={(e, value) => handleTextChange(variant, 'lineHeight', value)}
                min={1}
                max={2}
                step={0.1}
                marks
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {currentTheme.typography[variant].lineHeight}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant={variant} sx={{ mt: 2 }}>
            Preview: The quick brown fox jumps over the lazy dog.
            This is how your {label} will look with the current settings.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Text Styles
      </Typography>

      {textVariants.map((variant) => (
        <TextVariantControl 
          key={variant.key} 
          variant={variant.key}
          label={variant.label}
        />
      ))}

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Text styles affect the readability and visual hierarchy of your content.
          Ensure proper contrast and spacing between different text variants.
        </Typography>
      </Box>
    </Box>
  );
};

export default TextStyles;