// src/components/tools/SceneBuilder/SceneControls.js

import React, { useState } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
  Chip,
  Box,
  IconButton,
  Tooltip,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  useTheme
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  PlayArrow as GenerateIcon,
  Help as HelpIcon
} from '@mui/icons-material';

const SCENE_TYPES = [
  { value: 'description', label: 'Description/Setting' },
  { value: 'action', label: 'Action' },
  { value: 'dialogue', label: 'Dialogue Heavy' },
  { value: 'internal', label: 'Internal Monologue' },
  { value: 'mixed', label: 'Mixed' }
];

const MOODS = [
  'Tense', 'Peaceful', 'Mysterious', 'Romantic', 'Melancholic',
  'Joyful', 'Chaotic', 'Somber', 'Hopeful', 'Foreboding'
];

const SceneControls = ({
  scene,
  onChange,
  onGenerate,
  isGenerating,
  availableCharacters = []
}) => {
  const theme = useTheme();
  const [helpDialog, setHelpDialog] = useState(null);
  const [phraseInput, setPhraseInput] = useState('');

  const handleChange = (field, value) => {
    onChange({
      ...scene,
      [field]: value
    });
  };

  const addUserPhrase = (phrase) => {
    if (phrase.trim()) {
      handleChange('userPhrases', [...scene.userPhrases, phrase.trim()]);
      setPhraseInput('');
    }
  };

  const removeUserPhrase = (index) => {
    handleChange('userPhrases', 
      scene.userPhrases.filter((_, i) => i !== index)
    );
  };

  const HelpButton = ({ title, content }) => (
    <Tooltip title="Help">
      <IconButton
        size="small"
        onClick={() => setHelpDialog({ title, content })}
      >
        <HelpIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  return (
    <Paper sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      <Stack spacing={3}>
        {/* Basic Info */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Scene Details
          </Typography>
          
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Scene Title"
              value={scene.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />

            <TextField
              fullWidth
              multiline
              rows={3}
              label="Scene Description"
              value={scene.description}
              onChange={(e) => handleChange('description', e.target.value)}
              helperText="Brief overview of what happens in this scene"
            />

            <FormControl fullWidth>
              <InputLabel>Scene Type</InputLabel>
              <Select
                value={scene.type}
                onChange={(e) => handleChange('type', e.target.value)}
                label="Scene Type"
              >
                {SCENE_TYPES.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>

        {/* Characters */}
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">
              Characters
            </Typography>
            <HelpButton
              title="Character Selection"
              content="Select characters who appear in this scene. The POV character will be the primary perspective for the scene."
            />
          </Box>

          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel>POV Character</InputLabel>
              <Select
                value={scene.pov}
                onChange={(e) => handleChange('pov', e.target.value)}
                label="POV Character"
              >
                {availableCharacters.map(char => (
                  <MenuItem key={char.character_id} value={char.character_id}>
                    {char.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Autocomplete
              multiple
              value={scene.characters}
              onChange={(_, newValue) => handleChange('characters', newValue)}
              options={availableCharacters}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Other Characters Present"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    size="small"
                  />
                ))
              }
            />
          </Stack>
        </Box>

        {/* Setting */}
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">
              Setting
            </Typography>
            <HelpButton
              title="Setting Details"
              content="Provide specific details about where and when the scene takes place. The more specific you are, the more atmospheric the generated content will be."
            />
          </Box>

          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Location"
              value={scene.location}
              onChange={(e) => handleChange('location', e.target.value)}
              placeholder="Where does this scene take place?"
            />

            <TextField
              fullWidth
              label="Time/Period"
              value={scene.time}
              onChange={(e) => handleChange('time', e.target.value)}
              placeholder="When does this scene take place?"
            />

            <Autocomplete
              value={scene.mood}
              onChange={(_, newValue) => handleChange('mood', newValue)}
              options={MOODS}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mood/Atmosphere"
                  placeholder="What's the emotional tone?"
                />
              )}
            />
          </Stack>
        </Box>

        {/* Key Events */}
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">
              Key Events
            </Typography>
            <HelpButton
              title="Key Events"
              content="List the important events or beats that need to happen in this scene. These will be incorporated into the generated content."
            />
          </Box>

          <Stack spacing={1}>
            {scene.keyEvents.map((event, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  value={event}
                  onChange={(e) => {
                    const newEvents = [...scene.keyEvents];
                    newEvents[index] = e.target.value;
                    handleChange('keyEvents', newEvents);
                  }}
                />
                <IconButton
                  size="small"
                  onClick={() => handleChange('keyEvents', 
                    scene.keyEvents.filter((_, i) => i !== index)
                  )}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleChange('keyEvents', [...scene.keyEvents, ''])}
            >
              Add Event
            </Button>
          </Stack>
        </Box>

        {/* User Phrases */}
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">
              Your Phrases
            </Typography>
            <HelpButton
              title="Your Phrases"
              content="Add specific phrases, descriptions, or dialogue that you want included in the scene. These will be worked into the generated content."
            />
          </Box>

          <Stack spacing={1}>
            {scene.userPhrases.map((phrase, index) => (
              <Chip
                key={index}
                label={phrase}
                onDelete={() => removeUserPhrase(index)}
                sx={{ maxWidth: '100%' }}
              />
            ))}
            
            <TextField
              fullWidth
              size="small"
              value={phraseInput}
              onChange={(e) => setPhraseInput(e.target.value)}
              placeholder="Add your phrase..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  addUserPhrase(phraseInput);
                }
              }}
            />
          </Stack>
        </Box>

        {/* Generate Button */}
        <Button
          fullWidth
          variant="contained"
          size="large"
          startIcon={isGenerating ? <CircularProgress size={20} /> : <GenerateIcon />}
          onClick={onGenerate}
          disabled={isGenerating || !scene.title || !scene.description}
        >
          {isGenerating ? 'Generating...' : 'Generate Paragraphs'}
        </Button>
      </Stack>

      {/* Help Dialog */}
      <Dialog
        open={!!helpDialog}
        onClose={() => setHelpDialog(null)}
      >
        <DialogTitle>
          {helpDialog?.title}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {helpDialog?.content}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHelpDialog(null)}>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SceneControls;