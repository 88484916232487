import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Chip,
  Card,
  CardContent,
  Alert,
  Grid,
  Divider,
  ThemeProvider,
  createTheme
} from '@mui/material';

const LivePreview = ({ currentTheme }) => {
  const previewTheme = createTheme(currentTheme);

  return (
    <ThemeProvider theme={previewTheme}>
      <Box>
        <Typography variant="subtitle1" gutterBottom color="primary">
          Live Preview
        </Typography>

        <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            Typography
          </Typography>
          <Typography variant="h1" gutterBottom>Heading 1</Typography>
          <Typography variant="h2" gutterBottom>Heading 2</Typography>
          <Typography variant="h3" gutterBottom>Heading 3</Typography>
          <Typography variant="body1" paragraph>
            This is a paragraph with body1 typography. It demonstrates how regular text will look
            with the current theme settings. The quick brown fox jumps over the lazy dog.
          </Typography>
          <Typography variant="body2" paragraph>
            This is smaller text using body2 typography. It's commonly used for less prominent content.
          </Typography>
        </Paper>

        <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            Buttons & Interactive Elements
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained">Primary Button</Button>
            </Grid>
            <Grid item>
              <Button variant="outlined">Outlined Button</Button>
            </Grid>
            <Grid item>
              <Button variant="text">Text Button</Button>
            </Grid>
          </Grid>
          <Box sx={{ my: 2 }}>
            <Chip label="Basic Chip" sx={{ mr: 1 }} />
            <Chip label="Clickable Chip" onClick={() => {}} sx={{ mr: 1 }} />
            <Chip label="Deletable Chip" onDelete={() => {}} />
          </Box>
          <FormControlLabel control={<Switch />} label="Switch Example" />
        </Paper>

        <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            Cards & Containers
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Sample Card
                  </Typography>
                  <Typography variant="body2">
                    This is a sample card component showing how cards will look with the current theme settings.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Another Card
                  </Typography>
                  <Typography variant="body2">
                    Cards can be used to group related content and actions.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            Form Elements
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Text Field"
                placeholder="Enter some text"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Multiline Field"
                multiline
                rows={4}
                placeholder="Enter multiple lines of text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Alert severity="success" sx={{ mb: 2 }}>
                This is a success alert
              </Alert>
              <Alert severity="error" sx={{ mb: 2 }}>
                This is an error alert
              </Alert>
              <Alert severity="warning" sx={{ mb: 2 }}>
                This is a warning alert
              </Alert>
              <Alert severity="info">
                This is an info alert
              </Alert>
            </Grid>
          </Grid>
        </Paper>

        <Box sx={{ mt: 2 }}>
          <Typography variant="caption" color="text.secondary">
            This preview shows how your theme settings will affect different components.
            Try interacting with the elements to see hover and active states.
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LivePreview;