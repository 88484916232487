// src/components/tools/calibrator/ResultsSummary.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  LinearProgress,
  Alert,
  useTheme
} from '@mui/material';
import supabase  from '../../../api/supabase';

const ResultsSummary = ({ calibrationData }) => {
  const theme = useTheme();
  const [analyzing, setAnalyzing] = useState(true);
  const [recommendation, setRecommendation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    analyzeResults();
  }, []);

  const analyzeResults = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('calibrator-feedback', {
        body: {
          parameter: calibrationData.selectedParameter,
          feedback: calibrationData.feedback,
          testValues: calibrationData.generationSettings.testValues,
          prompt: calibrationData.selectedPrompt === 'custom' 
            ? calibrationData.customPrompt 
            : calibrationData.selectedPrompt
        }
      });

      if (error) throw error;
      setRecommendation(data.recommendation);
    } catch (err) {
      console.error('Analysis error:', err);
      setError('Failed to analyze results. Please try again.');
    } finally {
      setAnalyzing(false);
    }
  };

  if (analyzing) {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" gutterBottom>
          Analyzing Results
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Claude-3.5-sonnet is analyzing your feedback to determine optimal {calibrationData.selectedParameter} settings...
        </Typography>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Parameter Calibration Results
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Recommended {calibrationData.selectedParameter} Setting
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Chip 
                  label={`${recommendation.value.toFixed(2)}`}
                  color="primary"
                  size="large"
                  sx={{ mr: 2 }}
                />
                <Typography variant="body2" color="textSecondary">
                  Confidence: {recommendation.confidence}%
                </Typography>
              </Box>

              <Typography variant="body1" paragraph>
                {recommendation.explanation}
              </Typography>

              <Typography variant="subtitle2" gutterBottom>
                Key Observations:
              </Typography>
              <ul>
                {recommendation.observations.map((observation, index) => (
                  <li key={index}>
                    <Typography variant="body2">{observation}</Typography>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Pattern Analysis
              </Typography>
              <ul>
                {recommendation.patterns.map((pattern, index) => (
                  <li key={index}>
                    <Typography variant="body2">{pattern}</Typography>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Test Results Summary
              </Typography>
              
              <Grid container spacing={2}>
                {Object.entries(calibrationData.feedback.ratings).map(([index, data]) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2">
                            Test Value: {data.parameterValue}
                          </Typography>
                          <Chip 
                            label={`Rating: ${data.rating}/10`}
                            size="small"
                            color={data.rating >= 7 ? "success" : "default"}
                          />
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {calibrationData.feedback.comments[index]}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResultsSummary;