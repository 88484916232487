import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme
} from '@mui/material';
import { ChromePicker } from 'react-color';

const BackgroundColors = ({ currentTheme, onChange }) => {
  const theme = useTheme();
  const [activeColor, setActiveColor] = React.useState(null);

  const handleColorChange = (color) => {
    if (!activeColor) return;
    
    onChange({
      palette: {
        ...currentTheme.palette,
        background: {
          ...currentTheme.palette.background,
          [activeColor]: color.hex
        }
      }
    });
  };

  const ColorBox = ({ colorKey, label, description }) => (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        mb: 2,
        border: activeColor === colorKey ? `2px solid ${theme.palette.primary.main}` : 'none',
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {label}
          </Typography>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              cursor: 'pointer',
              backgroundColor: currentTheme.palette.background[colorKey],
              border: '2px solid',
              borderColor: 'divider',
            }}
            onClick={() => setActiveColor(colorKey)}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption" display="block" gutterBottom>
            {description}
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
            {currentTheme.palette.background[colorKey]}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Background Colors
      </Typography>
      
      <ColorBox 
        colorKey="default" 
        label="Default" 
        description="Main background color for the application"
      />
      <ColorBox 
        colorKey="paper" 
        label="Paper" 
        description="Background color for elevated components"
      />
      <ColorBox 
        colorKey="accent" 
        label="Accent" 
        description="Subtle background variation for emphasis"
      />

      {activeColor && (
        <Box sx={{ mt: 2 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Color Picker
            </Typography>
            <ChromePicker
              color={currentTheme.palette.background[activeColor]}
              onChange={handleColorChange}
              disableAlpha
              styles={{
                default: {
                  picker: {
                    width: '100%',
                    boxShadow: 'none',
                  }
                }
              }}
            />
          </Paper>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Background colors affect the overall look and feel of your application.
          Ensure sufficient contrast with text colors.
        </Typography>
      </Box>
    </Box>
  );
};

export default BackgroundColors;