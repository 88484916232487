import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import supabase from '../api/supabase';

export const useCustomTheme = () => {
  const { user } = useAuth();
  const [userThemes, setUserThemes] = useState([]);
  const [activeTheme, setActiveTheme] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      loadUserThemes();
    }
  }, [user]);

  const loadUserThemes = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_themes')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setUserThemes(data);
      const active = data.find(theme => theme.is_active);
      if (active) {
        setActiveTheme(active.theme_data);
      }
    } catch (err) {
      console.error('Error loading themes:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const saveTheme = async (themeName, themeData, setAsActive = false) => {
    try {
      setLoading(true);
      
      // If setting as active, deactivate current active theme
      if (setAsActive) {
        await supabase
          .from('user_themes')
          .update({ is_active: false })
          .eq('user_id', user.id)
          .eq('is_active', true);
      }

      const { data, error } = await supabase
        .from('user_themes')
        .insert([
          {
            user_id: user.id,
            name: themeName,
            theme_data: themeData,
            is_active: setAsActive
          }
        ])
        .select()
        .single();

      if (error) throw error;

      setUserThemes(prev => [data, ...prev]);
      if (setAsActive) {
        setActiveTheme(themeData);
      }

      return data;
    } catch (err) {
      console.error('Error saving theme:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateTheme = async (themeId, updates) => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_themes')
        .update(updates)
        .eq('id', themeId)
        .select()
        .single();

      if (error) throw error;

      setUserThemes(prev =>
        prev.map(theme => theme.id === themeId ? data : theme)
      );

      if (data.is_active) {
        setActiveTheme(data.theme_data);
      }

      return data;
    } catch (err) {
      console.error('Error updating theme:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteTheme = async (themeId) => {
    try {
      setLoading(true);
      const { error } = await supabase
        .from('user_themes')
        .delete()
        .eq('id', themeId);

      if (error) throw error;

      setUserThemes(prev => prev.filter(theme => theme.id !== themeId));
    } catch (err) {
      console.error('Error deleting theme:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const setThemeAsActive = async (themeId) => {
    try {
      setLoading(true);
      
      // Deactivate current active theme
      await supabase
        .from('user_themes')
        .update({ is_active: false })
        .eq('user_id', user.id)
        .eq('is_active', true);

      // Activate selected theme
      const { data, error } = await supabase
        .from('user_themes')
        .update({ is_active: true })
        .eq('id', themeId)
        .select()
        .single();

      if (error) throw error;

      setUserThemes(prev =>
        prev.map(theme => ({
          ...theme,
          is_active: theme.id === themeId
        }))
      );
      setActiveTheme(data.theme_data);
    } catch (err) {
      console.error('Error setting active theme:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    userThemes,
    activeTheme,
    loading,
    error,
    saveTheme,
    updateTheme,
    deleteTheme,
    setThemeAsActive
  };
};