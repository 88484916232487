import React, { createContext, useState } from 'react';

export const UIContext = createContext(null);

export const UIProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [themeCustomizerOpen, setThemeCustomizerOpen] = useState(false);
  const [loading, setLoading] = useState({});
  const [errors, setErrors] = useState({});

  const toggleSidebar = () => {
    setSidebarOpen(prev => !prev);
  };

  const toggleThemeCustomizer = () => {
    setThemeCustomizerOpen(prev => !prev);
  };

  const addNotification = (notification) => {
    setNotifications(prev => [
      {
        id: Date.now(),
        read: false,
        timestamp: new Date(),
        ...notification
      },
      ...prev
    ]);
  };

  const markNotificationAsRead = (notificationId) => {
    setNotifications(prev =>
      prev.map(notification =>
        notification.id === notificationId
          ? { ...notification, read: true }
          : notification
      )
    );
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  const setLoadingState = (key, isLoading) => {
    setLoading(prev => ({
      ...prev,
      [key]: isLoading
    }));
  };

  const setError = (key, error) => {
    setErrors(prev => ({
      ...prev,
      [key]: error
    }));
  };

  const clearError = (key) => {
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[key];
      return newErrors;
    });
  };

  const value = {
    notifications,
    addNotification,
    markNotificationAsRead,
    clearNotifications,
    sidebarOpen,
    toggleSidebar,
    themeCustomizerOpen,
    toggleThemeCustomizer,
    loading,
    setLoadingState,
    errors,
    setError,
    clearError
  };

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
};