import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  useTheme
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useAuth } from '../../hooks/useAuth';
import supabase from '../../api/supabase';

const Gallery = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [assignmentType, setAssignmentType] = useState('');
  const [assignmentTarget, setAssignmentTarget] = useState('');
  const [availableTargets, setAvailableTargets] = useState([]);

  // Load images
  useEffect(() => {
    fetchImages();
  }, [user]);

  const fetchImages = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('gallery')
        .select(`
          *,
          used_by:used_by_id (
            name,
            title
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Get image URLs
      const imagesWithUrls = await Promise.all(
        data.map(async (image) => ({
          ...image,
          url: await getImageUrl(image.image_path)
        }))
      );

      setImages(imagesWithUrls);
    } catch (err) {
      setError('Failed to load images');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const getImageUrl = async (path) => {
    const { data } = supabase.storage
      .from('images')
      .getPublicUrl(path);
    return data.publicUrl;
  };

  const handleDelete = async (image) => {
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }

    try {
      // Delete from storage
      const { error: storageError } = await supabase.storage
        .from('images')
        .remove([image.image_path]);

      if (storageError) throw storageError;

      // Delete from database
      const { error: dbError } = await supabase
        .from('gallery')
        .delete()
        .eq('id', image.id);

      if (dbError) throw dbError;

      // Update state
      setImages(images.filter(img => img.id !== image.id));
    } catch (err) {
      setError('Failed to delete image');
      console.error('Error:', err);
    }
  };

  const handleAssignClick = async (image) => {
    setSelectedImage(image);
    setAssignDialogOpen(true);
  };

  // Load available targets when assignment type changes
  useEffect(() => {
    const loadTargets = async () => {
      if (!assignmentType) return;

      try {
        let table;
        switch (assignmentType) {
          case 'avatar':
            table = 'characters';
            break;
          case 'cover':
            table = 'books';
            break;
          case 'illustration':
            table = 'settings';
            break;
          default:
            return;
        }

        const { data, error } = await supabase
          .from(table)
          .select('*')
          .eq('user_id', user.id);

        if (error) throw error;
        setAvailableTargets(data);
      } catch (err) {
        setError('Failed to load assignment targets');
        console.error('Error:', err);
      }
    };

    loadTargets();
  }, [assignmentType]);

  const handleAssign = async () => {
    try {
      // Update the assignment in gallery
      const { error: galleryError } = await supabase
        .from('gallery')
        .update({
          used_by_id: assignmentTarget,
          used_as: assignmentType
        })
        .eq('id', selectedImage.id);

      if (galleryError) throw galleryError;

      // Update the target entity
      let table;
      let column;
      switch (assignmentType) {
        case 'avatar':
          table = 'characters';
          column = 'avatar_url';
          break;
        case 'cover':
          table = 'books';
          column = 'cover_image';
          break;
        case 'illustration':
          table = 'settings';
          column = 'image_url';
          break;
      }

      const { error: targetError } = await supabase
        .from(table)
        .update({ [column]: selectedImage.url })
        .eq('id', assignmentTarget);

      if (targetError) throw targetError;

      // Refresh images
      await fetchImages();
      setAssignDialogOpen(false);
    } catch (err) {
      setError('Failed to assign image');
      console.error('Error:', err);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Image Gallery
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
            <Card>
              <CardMedia
                component="img"
                image={image.url}
                alt={image.prompt}
                sx={{ 
                  aspectRatio: '1',
                  objectFit: 'cover'
                }}
              />
              <CardContent>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }}>
                  <Box>
                    {image.type && (
                      <Typography variant="caption" color="textSecondary">
                        {image.type.charAt(0).toUpperCase() + image.type.slice(1)}
                      </Typography>
                    )}
                    {image.used_by && (
                      <Typography variant="caption" color="primary" display="block">
                        Used as {image.used_as} for {image.used_by.name || image.used_by.title}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <IconButton onClick={(e) => {
                      setSelectedImage(image);
                      setAnchorEl(e.currentTarget);
                    }}>
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Image Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
          setAnchorEl(null);
          handleAssignClick(selectedImage);
        }}>
          <AssignmentIcon sx={{ mr: 1 }} /> Assign
        </MenuItem>
        <MenuItem onClick={() => {
          setAnchorEl(null);
          handleDelete(selectedImage);
        }}>
          <DeleteIcon sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Menu>

      {/* Assignment Dialog */}
      <Dialog 
        open={assignDialogOpen} 
        onClose={() => setAssignDialogOpen(false)}
      >
        <DialogTitle>Assign Image</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <InputLabel>Assignment Type</InputLabel>
            <Select
              value={assignmentType}
              onChange={(e) => setAssignmentType(e.target.value)}
              label="Assignment Type"
            >
              <MenuItem value="avatar">Character Avatar</MenuItem>
              <MenuItem value="cover">Book Cover</MenuItem>
              <MenuItem value="illustration">Setting Illustration</MenuItem>
            </Select>
          </FormControl>

          {assignmentType && (
            <FormControl fullWidth>
              <InputLabel>Assign To</InputLabel>
              <Select
                value={assignmentTarget}
                onChange={(e) => setAssignmentTarget(e.target.value)}
                label="Assign To"
              >
                {availableTargets.map((target) => (
                  <MenuItem key={target.id} value={target.id}>
                    {target.name || target.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignDialogOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleAssign}
            disabled={!assignmentType || !assignmentTarget}
            variant="contained"
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Gallery;