import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Slider,
  Switch,
  FormControlLabel,
  useTheme
} from '@mui/material';

const InputStyles = ({ currentTheme, onChange }) => {
  const theme = useTheme();

  const handleStyleChange = (property, value) => {
    onChange({
      components: {
        ...currentTheme.components,
        MuiTextField: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root': {
                ...currentTheme.components?.MuiTextField?.styleOverrides?.root?.['& .MuiOutlinedInput-root'],
                [property]: value
              }
            }
          }
        }
      }
    });
  };

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Input Styles
      </Typography>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Input Settings
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption">Border Radius</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={currentTheme.components?.MuiTextField?.styleOverrides?.root?.['& .MuiOutlinedInput-root']?.borderRadius || 4}
                  onChange={(e, value) => handleStyleChange('borderRadius', value)}
                  min={0}
                  max={24}
                  step={1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {currentTheme.components?.MuiTextField?.styleOverrides?.root?.['& .MuiOutlinedInput-root']?.borderRadius || 4}px
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={currentTheme.components?.MuiTextField?.styleOverrides?.root?.['& .MuiOutlinedInput-root']?.backgroundColor !== 'transparent'}
                  onChange={(e) => handleStyleChange('backgroundColor', e.target.checked ? theme.palette.background.paper : 'transparent')}
                />
              }
              label="Filled Background"
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Preview
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Sample Input"
              placeholder="Type something..."
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Multiline Input"
              multiline
              rows={4}
              placeholder="Type something..."
            />
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Input styles affect the appearance of all text fields and form inputs.
          Ensure they are easily recognizable and maintain good contrast with their labels.
        </Typography>
      </Box>
    </Box>
  );
};

export default InputStyles;