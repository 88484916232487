// src/components/tools/CharacterChat/ExportDialog.js

import React, { useState } from 'react';
import supabase from '../../../api/supabase';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  Box,
  Stack,
  Divider,
  CircularProgress,
  Alert,
  useTheme
} from '@mui/material';
import {
  PictureAsPdf as PdfIcon,
  Description as DocIcon,
  TableChart as CsvIcon,
  Code as JsonIcon,
  Preview as PreviewIcon
} from '@mui/icons-material';

const ExportDialog = ({
  open,
  onClose,
  messages = [],
  insights = [],
  character,
  onExport
}) => {
  const theme = useTheme();
  const [format, setFormat] = useState('pdf');
  const [options, setOptions] = useState({
    includeMetadata: true,
    includeInsights: true,
    includeCharacterProfile: true,
    includeTimestamps: false,
    includeRatings: true,
    includeTags: true,
    includeChapterReferences: true,
    includeNotes: true
  });
  const [title, setTitle] = useState('Character Chat Export');
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(false);

  const formatOptions = [
    {
      value: 'pdf',
      label: 'PDF Document',
      icon: PdfIcon,
      description: 'Formatted document with styling and layout'
    },
    {
      value: 'doc',
      label: 'Word Document',
      icon: DocIcon,
      description: 'Editable document format'
    },
    {
      value: 'csv',
      label: 'CSV Spreadsheet',
      icon: CsvIcon,
      description: 'Tabular format for data analysis'
    },
    {
      value: 'json',
      label: 'JSON Data',
      icon: JsonIcon,
      description: 'Raw data format for processing'
    }
  ];

  const handleExport = async () => {
    try {
      setIsExporting(true);
      setError(null);

      const exportData = {
        title,
        format,
        options,
        character: options.includeCharacterProfile ? character : null,
        messages: messages.map(msg => ({
          ...msg,
          timestamp: options.includeTimestamps ? msg.timestamp : undefined,
          rating: options.includeRatings ? msg.rating : undefined,
          tags: options.includeTags ? msg.tags : undefined
        })),
        insights: options.includeInsights ? insights : null
      };

      const { data, error } = await supabase.functions.invoke('export-handler', {
        body: exportData
      });

      if (error) throw error;

      // Handle the exported file based on format
      if (format === 'pdf' || format === 'doc') {
        // Create blob and download
        const blob = new Blob([data], { 
          type: format === 'pdf' ? 'application/pdf' : 'application/msword' 
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.${format}`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else if (format === 'csv' || format === 'json') {
        // Direct download of text file
        const blob = new Blob([data], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.${format}`;
        a.click();
        window.URL.revokeObjectURL(url);
      }

      onClose();
    } catch (err) {
      console.error('Export error:', err);
      setError('Failed to export chat. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const PreviewContent = () => (
    <Box sx={{ 
      bgcolor: 'background.paper', 
      p: 2, 
      borderRadius: 1,
      maxHeight: '400px',
      overflow: 'auto'
    }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      
      {options.includeCharacterProfile && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Character Profile
          </Typography>
          <Typography variant="body2" paragraph>
            Name: {character.name}
            {/* Add other character details */}
          </Typography>
        </>
      )}

      <Typography variant="subtitle1" gutterBottom>
        Conversation
      </Typography>
      {messages.map((msg, index) => (
        <Box key={index} sx={{ mb: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {msg.role === 'user' ? 'You' : character.name}
            {options.includeTimestamps && 
              ` - ${new Date(msg.timestamp).toLocaleString()}`
            }
          </Typography>
          <Typography variant="body2" paragraph>
            {msg.content}
          </Typography>
          {options.includeRatings && msg.rating && (
            <Typography variant="caption" color="text.secondary">
              Rating: {msg.rating}/5
            </Typography>
          )}
        </Box>
      ))}

      {options.includeInsights && insights.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Insights
          </Typography>
          {insights.map((insight, index) => (
            <Typography key={index} variant="body2" paragraph>
              • {insight.content}
            </Typography>
          ))}
        </>
      )}
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Export Chat History
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Export Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Export Format
            </Typography>
            <RadioGroup
              row
              value={format}
              onChange={(e) => setFormat(e.target.value)}
            >
              {formatOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <option.icon />
                      <Box>
                        <Typography variant="body2">
                          {option.label}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {option.description}
                        </Typography>
                      </Box>
                    </Box>
                  }
                  sx={{ mb: 1 }}
                />
              ))}
            </RadioGroup>
          </Box>

          <Divider />

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Export Options
            </Typography>
            <Stack>
              {Object.entries(options).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => setOptions(prev => ({
                        ...prev,
                        [key]: e.target.checked
                      }))}
                    />
                  }
                  label={key.replace(/([A-Z])/g, ' $1').toLowerCase()}
                />
              ))}
            </Stack>
          </Box>

          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          <Button
            startIcon={<PreviewIcon />}
            onClick={() => setPreview(!preview)}
          >
            {preview ? 'Hide Preview' : 'Show Preview'}
          </Button>

          {preview && <PreviewContent />}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleExport}
          disabled={isExporting || !title.trim()}
          startIcon={isExporting ? <CircularProgress size={20} /> : null}
        >
          {isExporting ? 'Exporting...' : 'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;