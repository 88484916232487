import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Slider,
  Typography,
  Box
} from '@mui/material';

const EditImageDialog = ({ open, onClose, image, onSave }) => {
  const [settings, setSettings] = useState({
    brightness: 1,
    contrast: 1,
    saturation: 1,
    blur: 0
  });

  const handleSettingChange = (setting, value) => {
    setSettings(prev => ({
      ...prev,
      [setting]: value
    }));
  };

  const handleSave = async () => {
    try {
      // Apply settings to image
      // This is a placeholder - you'll need to implement actual image processing
      // You might want to use a canvas or image processing library
      await onSave(image.url);
    } catch (err) {
      console.error('Edit error:', err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Image</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Box sx={{ textAlign: 'center' }}>
            <img
              src={image?.url}
              alt="Preview"
              style={{
                maxWidth: '100%',
                maxHeight: '400px',
                objectFit: 'contain'
              }}
            />
          </Box>
          
          <Typography>Brightness</Typography>
          <Slider
            value={settings.brightness}
            onChange={(_, value) => handleSettingChange('brightness', value)}
            min={0}
            max={2}
            step={0.1}
            marks
            valueLabelDisplay="auto"
          />

          <Typography>Contrast</Typography>
          <Slider
            value={settings.contrast}
            onChange={(_, value) => handleSettingChange('contrast', value)}
            min={0}
            max={2}
            step={0.1}
            marks
            valueLabelDisplay="auto"
          />

          <Typography>Saturation</Typography>
          <Slider
            value={settings.saturation}
            onChange={(_, value) => handleSettingChange('saturation', value)}
            min={0}
            max={2}
            step={0.1}
            marks
            valueLabelDisplay="auto"
          />

          <Typography>Blur</Typography>
          <Slider
            value={settings.blur}
            onChange={(_, value) => handleSettingChange('blur', value)}
            min={0}
            max={10}
            step={0.5}
            marks
            valueLabelDisplay="auto"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditImageDialog;