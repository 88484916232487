// src/components/tools/SceneBuilder/SceneAssembly.js

import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Chip,
  Collapse,
  useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  DragHandle as DragHandleIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ArrowUpward as ArrowUpIcon,
  ArrowDownward as ArrowDownIcon,
  Merge as MergeIcon,
  ContentCopy as CopyIcon,
  FormatQuote as QuoteIcon,
  Label as LabelIcon
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const SceneAssembly = ({
  paragraphs = [],
  onReorder,
  onEdit,
  onMerge,
  onSplit,
  onAddUserContent
}) => {
  const theme = useTheme();
  const [editingIndex, setEditingIndex] = useState(null);
  const [mergeDialog, setMergeDialog] = useState(null);
  const [userContentDialog, setUserContentDialog] = useState(false);
  const [selectedParagraphs, setSelectedParagraphs] = useState([]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(paragraphs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onReorder(items);
  };

  const handleMerge = () => {
    if (selectedParagraphs.length < 2) return;
    
    const mergedContent = selectedParagraphs
      .map(index => paragraphs[index])
      .join('\n\n');
    
    const newParagraphs = paragraphs.filter((_, index) => 
      !selectedParagraphs.includes(index)
    );
    
    newParagraphs.splice(Math.min(...selectedParagraphs), 0, mergedContent);
    onReorder(newParagraphs);
    setSelectedParagraphs([]);
    setMergeDialog(false);
  };

  const ParagraphCard = ({ paragraph, index }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isSelected = selectedParagraphs.includes(index);

    return (
      <Paper
        sx={{
          p: 2,
          mb: 2,
          border: `1px solid ${
            isSelected ? 
              theme.palette.primary.main : 
              theme.palette.divider
          }`,
          backgroundColor: isSelected ?
            alpha(theme.palette.primary.main, 0.05) :
            'background.paper'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'flex-start',
          gap: 1
        }}>
          <DragHandleIcon 
            sx={{ 
              cursor: 'grab',
              color: 'text.secondary'
            }} 
          />

          <Box sx={{ flexGrow: 1 }}>
            {editingIndex === index ? (
              <TextField
                fullWidth
                multiline
                value={paragraph}
                onChange={(e) => onEdit(index, e.target.value)}
                autoFocus
                onBlur={() => setEditingIndex(null)}
              />
            ) : (
              <Typography>
                {paragraph}
              </Typography>
            )}
          </Box>

          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 0.5
          }}>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() => setEditingIndex(index)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Select for merge">
              <IconButton
                size="small"
                onClick={() => {
                  if (isSelected) {
                    setSelectedParagraphs(prev => 
                      prev.filter(i => i !== index)
                    );
                  } else {
                    setSelectedParagraphs(prev => [...prev, index]);
                  }
                }}
                color={isSelected ? "primary" : "default"}
              >
                <MergeIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Move up">
              <IconButton
                size="small"
                onClick={() => {
                  const newParagraphs = [...paragraphs];
                  [newParagraphs[index], newParagraphs[index - 1]] = 
                    [newParagraphs[index - 1], newParagraphs[index]];
                  onReorder(newParagraphs);
                }}
                disabled={index === 0}
              >
                <ArrowUpIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Move down">
              <IconButton
                size="small"
                onClick={() => {
                  const newParagraphs = [...paragraphs];
                  [newParagraphs[index], newParagraphs[index + 1]] = 
                    [newParagraphs[index + 1], newParagraphs[index]];
                  onReorder(newParagraphs);
                }}
                disabled={index === paragraphs.length - 1}
              >
                <ArrowDownIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => {
                  onReorder(paragraphs.filter((_, i) => i !== index));
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
        <Typography variant="h6">
          Scene Assembly
        </Typography>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setUserContentDialog(true)}
          >
            Add Content
          </Button>
          
          <Button
            startIcon={<MergeIcon />}
            onClick={() => setMergeDialog(true)}
            disabled={selectedParagraphs.length < 2}
          >
            Merge Selected ({selectedParagraphs.length})
          </Button>
        </Box>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="paragraphs">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {paragraphs.map((paragraph, index) => (
                <Draggable
                  key={index}
                  draggableId={`paragraph-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ParagraphCard
                        paragraph={paragraph}
                        index={index}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Add Content Dialog */}
      <Dialog
        open={userContentDialog}
        onClose={() => setUserContentDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Your Content</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={6}
            placeholder="Write or paste your content here..."
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserContentDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              // Handle adding user content
              setUserContentDialog(false);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Merge Dialog */}
      <Dialog
        open={!!mergeDialog}
        onClose={() => setMergeDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Merge Paragraphs</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Selected paragraphs will be combined in their current order.
          </Typography>
          
          <Stack spacing={2}>
            {selectedParagraphs.map((index) => (
              <Paper key={index} sx={{ p: 2 }}>
                <Typography>
                  {paragraphs[index]}
                </Typography>
              </Paper>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMergeDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleMerge}
          >
            Merge Paragraphs
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SceneAssembly;