import React from 'react';
import { Box, Typography, Grid, Card, useTheme, styled, keyframes } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const WelcomePanel = ({ userName = "Author" }) => {
  const theme = useTheme();

return (
  <Box sx={{ mb: 4 }}>
    {/* Welcome Message */}
    <Card
      sx={{
        p: 4,
        height: '100%',
        background: 'transparent',
        border: '1px solid rgba(0,0,0,0.12)',
        boxShadow: 'none',
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontFamily: 'Playfair Display', color: theme.palette.text.primary, mb: 2 }}
      >
        Welcome back, {userName}
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        Ready to continue crafting your story? Select a book from the sidebar to begin editing.
      </Typography>
    </Card>
    {/* Feature Cards */}
    <Grid item xs={12} md={7}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            sx={{
              background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, rgba(255,137,163,0.1) 100%)`,
            }}
          >
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Getting Started
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Create a new book using the button in the sidebar, or select an existing book to continue working on it.
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  </Box>
)};

export default WelcomePanel;

