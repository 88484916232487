import { useAuth } from './useAuth';
import supabase from '../api/supabase';
import { useEffect, useState } from 'react';

export function useBooks() {
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();
  
    const fetchBooks = async () => {
      try {
        setLoading(true);
        
        // First, let's log the raw query to see what we're getting
        const { data: booksData, error: booksError } = await supabase
          .from('books')
          .select('*')
          .eq('user_id', user.id);

        console.log('Raw books data:', booksData);

        if (booksError) throw booksError;

        // Then, for each book, let's fetch its characters
        const booksWithCharacters = await Promise.all(
          booksData.map(async (book) => {
            const { data: charactersData, error: charactersError } = await supabase
              .from('characters')
              .select('*')
              .eq('book_id', book.book_id);

            console.log(`Characters for book ${book.book_id}:`, charactersData);

            if (charactersError) throw charactersError;

            return {
              ...book,
              characters: charactersData || []
            };
          })
        );

        console.log('Final books with characters:', booksWithCharacters);

        setBooks(booksWithCharacters);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching books:', err);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      if (user) {
        fetchBooks();
      }
    }, [user]);
  
    const addBook = async (bookData) => {
      try {
        // Insert the book first
        const { data: newBook, error: bookError } = await supabase
          .from('books')
          .insert([{ ...bookData, user_id: user.id }])
          .select()
          .single();

        if (bookError) throw bookError;

        // Then get its characters
        const { data: characters, error: charactersError } = await supabase
          .from('characters')
          .select('*')
          .eq('book_id', newBook.book_id);

        if (charactersError) throw charactersError;

        const bookWithCharacters = {
          ...newBook,
          characters: characters || []
        };

        setBooks(prev => [bookWithCharacters, ...prev]);
        return bookWithCharacters;
      } catch (err) {
        setError(err.message);
        throw err;
      }
    };
  
    return { books, loading, error, addBook, fetchBooks };
}
