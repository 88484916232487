// src/components/tools/Calibrator.js

import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Alert,
  useTheme
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import PromptSelection from './calibrator/PromptSelection';
import StyleOptions from './calibrator/StyleOptions';
import TextGeneration from './calibrator/TextGeneration';
import FeedbackPanel from './calibrator/FeedbackPanel';
import ResultsSummary from './calibrator/ResultsSummary';

const steps = [
  'Select Prompt',
  'Choose Parameter',
  'Generate Variations',
  'Provide Feedback',
  'Review Results'
];

const Calibrator = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [calibrationData, setCalibrationData] = useState({
    selectedPrompt: '',
    customPrompt: '',
    selectedParameter: '',
    generatedTexts: [],
    feedback: {
      ratings: {},
      selectedSentences: {},
      comments: {},
      effectiveness: {},
      discarded: []
    },
    generationSettings: {},
  });

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCalibrationData({
      selectedPrompt: '',
      customPrompt: '',
      selectedParameter: '',
      generatedTexts: [],
      feedback: {
        ratings: {},
        selectedSentences: {},
        comments: {},
        effectiveness: {},
        discarded: []
      },
      generationSettings: {},
    });
  };

  const canProceed = () => {
    switch (activeStep) {
      case 0:
        return calibrationData.selectedPrompt || calibrationData.customPrompt;
      case 1:
        return calibrationData.selectedParameter;
      case 2:
        return calibrationData.generatedTexts.length > 0;
      case 3:
        return Object.keys(calibrationData.feedback.ratings).length > 0;
      default:
        return true;
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <PromptSelection
            calibrationData={calibrationData}
            setCalibrationData={setCalibrationData}
          />
        );
      case 1:
        return (
          <StyleOptions
            calibrationData={calibrationData}
            setCalibrationData={setCalibrationData}
          />
        );
      case 2:
        return (
          <TextGeneration
            calibrationData={calibrationData}
            setCalibrationData={setCalibrationData}
          />
        );
      case 3:
        return (
          <FeedbackPanel
            calibrationData={calibrationData}
            setCalibrationData={setCalibrationData}
          />
        );
      case 4:
        return (
          <ResultsSummary
            calibrationData={calibrationData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Parameter Calibrator
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 2, mb: 2 }}>
          {renderStepContent()}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          {activeStep > 0 && (
            <Button
              onClick={handleBack}
              sx={{ mr: 1 }}
              disabled={loading}
            >
              Back
            </Button>
          )}

          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleReset}
              disabled={loading}
            >
              Start New Calibration
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={loading || !canProceed()}
            >
              {activeStep === steps.length - 2 ? 'Finish' : 'Next'}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Calibrator;