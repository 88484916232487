import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme
} from '@mui/material';

const HeadingStyles = ({ currentTheme, onChange }) => {
  const theme = useTheme();

  const handleHeadingChange = (variant, property, value) => {
    onChange({
      typography: {
        ...currentTheme.typography,
        [variant]: {
          ...currentTheme.typography[variant],
          [property]: value
        }
      }
    });
  };

  const headingVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const fontWeights = [300, 400, 500, 600, 700];

  const HeadingControl = ({ variant }) => (
    <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        {variant.toUpperCase()} Settings
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption">Font Size</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                value={parseInt(currentTheme.typography[variant].fontSize)}
                onChange={(e, value) => handleHeadingChange(variant, 'fontSize', `${value}rem`)}
                min={1}
                max={6}
                step={0.1}
                marks
                valueLabelDisplay="auto"
                valueLabelFormat={value => `${value}rem`}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {currentTheme.typography[variant].fontSize}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Font Weight</InputLabel>
            <Select
              value={currentTheme.typography[variant].fontWeight}
              onChange={(e) => handleHeadingChange(variant, 'fontWeight', e.target.value)}
              label="Font Weight"
            >
              {fontWeights.map((weight) => (
                <MenuItem key={weight} value={weight}>
                  {weight}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant={variant} sx={{ mt: 2 }}>
            Preview: The quick brown fox
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Heading Styles
      </Typography>

      {headingVariants.map((variant) => (
        <HeadingControl key={variant} variant={variant} />
      ))}

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Heading styles help create visual hierarchy in your content.
          Ensure proper scaling between different heading levels.
        </Typography>
      </Box>
    </Box>
  );
};

export default HeadingStyles;