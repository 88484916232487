// src/components/tools/SceneBuilder/ExportScene.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
  Box,
  Stack,
  Divider,
  Alert,
  CircularProgress,
  useTheme
} from '@mui/material';
import {
  Description as DocIcon,
  PictureAsPdf as PdfIcon,
  Code as JsonIcon,
  Preview as PreviewIcon,
  Save as SaveIcon
} from '@mui/icons-material';

const EXPORT_FORMATS = [
  {
    id: 'doc',
    label: 'Word Document',
    icon: DocIcon,
    description: 'Rich text format with formatting preserved'
  },
  {
    id: 'pdf',
    label: 'PDF Document',
    icon: PdfIcon,
    description: 'Fixed format document, ideal for sharing'
  },
  {
    id: 'json',
    label: 'JSON Data',
    icon: JsonIcon,
    description: 'Structured data format with metadata'
  }
];

const ExportScene = ({
  open,
  onClose,
  scene,
  content,
  onExport,
  onSave
}) => {
  const theme = useTheme();
  const [format, setFormat] = useState('doc');
  const [options, setOptions] = useState({
    includeMetadata: true,
    includeNotes: true,
    includeGenerationSettings: false,
    includeParagraphHistory: false,
    formatForNovel: true
  });
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      setError(null);

      const exportData = {
        format,
        options,
        scene: {
          ...scene,
          content
        }
      };

      await onExport(exportData);
      onClose();
    } catch (err) {
      console.error('Export error:', err);
      setError('Failed to export scene. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const PreviewContent = () => (
    <Box sx={{ 
      bgcolor: 'background.paper',
      p: 2,
      borderRadius: 1,
      maxHeight: '400px',
      overflow: 'auto'
    }}>
      <Typography variant="h6" gutterBottom>
        {scene.title}
      </Typography>

      {options.includeMetadata && (
        <>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Scene Details
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">
              Type: {scene.type}
              {scene.pov && ` • POV: ${scene.pov}`}
              {scene.location && ` • Location: ${scene.location}`}
            </Typography>
            {scene.description && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {scene.description}
              </Typography>
            )}
          </Box>
        </>
      )}

      <Divider sx={{ my: 2 }} />

      {content.map((paragraph, index) => (
        <Typography key={index} paragraph>
          {paragraph}
        </Typography>
      ))}

      {options.includeNotes && scene.keyEvents?.length > 0 && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle2" gutterBottom>
            Key Events
          </Typography>
          {scene.keyEvents.map((event, index) => (
            <Typography key={index} variant="body2">
              • {event}
            </Typography>
          ))}
        </>
      )}
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Export Scene
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {/* Export Format */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Export Format
            </Typography>
            <RadioGroup
              value={format}
              onChange={(e) => setFormat(e.target.value)}
            >
              {EXPORT_FORMATS.map((f) => (
                <FormControlLabel
                  key={f.id}
                  value={f.id}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <f.icon />
                      <Box>
                        <Typography variant="body1">
                          {f.label}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {f.description}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          </Box>

          <Divider />

          {/* Export Options */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Export Options
            </Typography>
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.includeMetadata}
                    onChange={(e) => setOptions(prev => ({
                      ...prev,
                      includeMetadata: e.target.checked
                    }))}
                  />
                }
                label="Include scene metadata"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.includeNotes}
                    onChange={(e) => setOptions(prev => ({
                      ...prev,
                      includeNotes: e.target.checked
                    }))}
                  />
                }
                label="Include notes and key events"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.formatForNovel}
                    onChange={(e) => setOptions(prev => ({
                      ...prev,
                      formatForNovel: e.target.checked
                    }))}
                  />
                }
                label="Format for novel (indentation, spacing)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.includeGenerationSettings}
                    onChange={(e) => setOptions(prev => ({
                      ...prev,
                      includeGenerationSettings: e.target.checked
                    }))}
                  />
                }
                label="Include generation settings"
              />
            </Stack>
          </Box>

          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          <Button
            startIcon={<PreviewIcon />}
            onClick={() => setShowPreview(!showPreview)}
          >
            {showPreview ? 'Hide Preview' : 'Show Preview'}
          </Button>

          {showPreview && <PreviewContent />}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          onClick={() => onSave(scene)}
          disabled={isExporting}
        >
          Save Scene
        </Button>
        <Button
          variant="contained"
          onClick={handleExport}
          disabled={isExporting}
          startIcon={isExporting ? 
            <CircularProgress size={20} /> : 
            EXPORT_FORMATS.find(f => f.id === format)?.icon
          }
        >
          {isExporting ? 'Exporting...' : 'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportScene;