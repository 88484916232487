import React, { useState } from 'react';
import { 
    Card, 
    CardContent, 
    Typography, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    TextField, 
    Button, 
    Box, 
    Alert, 
    Grid,
    useTheme 
  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'How do I get started with myPages?',
    answer: 'Begin by creating a new book from your dashboard. You\'ll be guided through the process of setting up your book\'s basic details, characters, and plot points.'
  },
  {
    question: 'What AI models are available?',
    answer: 'The available AI models depend on your subscription plan. Free users have access to GPT-4, while premium users can access multiple models including Claude, PaLM, and LLaMA.'
  },
  {
    question: 'How does the Calibrator tool work?',
    answer: 'The Calibrator tool helps fine-tune the AI to match your writing style. Provide sample text and dialogue, then rate and provide feedback on different variations to create personalized style instructions.'
  },
  // Add more FAQs as needed
];

const Help = () => {
  const theme = useTheme();
  const [contactForm, setContactForm] = useState({
    subject: '',
    message: ''
  });
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      setSending(true);
      setError(null);
      // Implement contact form submission
      setSuccess('Message sent successfully! We\'ll get back to you soon.');
      setContactForm({ subject: '', message: '' });
    } catch (error) {
      setError('Failed to send message. Please try again.');
      console.error('Contact form error:', error);
    } finally {
      setSending(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Frequently Asked Questions
            </Typography>

            {faqs.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Contact Support
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}

            <form onSubmit={handleContactSubmit}>
              <TextField
                fullWidth
                label="Subject"
                value={contactForm.subject}
                onChange={(e) => setContactForm(prev => ({
                  ...prev,
                  subject: e.target.value
                }))}
                required
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                multiline
                rows={6}
                label="Message"
                value={contactForm.message}
                onChange={(e) => setContactForm(prev => ({
                  ...prev,
                  message: e.target.value
                }))}
                required
                sx={{ mb: 2 }}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Send Message'}
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Help;