import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const auth = {
  signUp: async ({ email, password, userData }) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: userData,
      },
    });
    if (error) throw error;
    return data;
  },

  signIn: async ({ email, password }) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) throw error;
    return data;
  },

  signOut: async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  },

  getCurrentUser: async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) throw error;
    return user;
  },

  resetPassword: async (email) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) throw error;
  },

  updatePassword: async (newPassword) => {
    const { error } = await supabase.auth.updateUser({
      password: newPassword
    });
    if (error) throw error;
  }
};

export const storage = {
  uploadImage: async (bucket, path, file) => {
    const { data, error } = await supabase.storage
      .from(bucket)
      .upload(path, file, {
        cacheControl: '3600',
        upsert: true
      });
    if (error) throw error;
    return data;
  },

  getImageUrl: (bucket, path) => {
    const { data } = supabase.storage
      .from(bucket)
      .getPublicUrl(path);
    return data.publicUrl;
  },

  deleteImage: async (bucket, path) => {
    const { error } = await supabase.storage
      .from(bucket)
      .remove([path]);
    if (error) throw error;
  },

  list: async (bucket, path = '') => {
    const { data, error } = await supabase.storage
      .from(bucket)
      .list(path);
    if (error) throw error;
    return data;
  }
};

export const books = {
  create: async (bookData) => {
    const { data, error } = await supabase
      .from('books')
      .insert([bookData])
      .select();
    if (error) throw error;
    return data[0];
  },

  update: async (bookId, updates) => {
    const { data, error } = await supabase
      .from('books')
      .update(updates)
      .eq('book_id', bookId)
      .select();
    if (error) throw error;
    return data[0];
  },

  delete: async (bookId) => {
    const { error } = await supabase
      .from('books')
      .delete()
      .eq('book_id', bookId);
    if (error) throw error;
  },

  getById: async (bookId) => {
    const { data, error } = await supabase
      .from('books')
      .select('*')
      .eq('book_id', bookId)
      .single();
    if (error) throw error;
    return data;
  },

  getUserBooks: async (userId) => {
    const { data, error } = await supabase
      .from('books')
      .select('*')
      .eq('user_id', userId);
    if (error) throw error;
    return data;
  }
};

export const themes = {
  save: async (themeData) => {
    const { data, error } = await supabase
      .from('user_themes')
      .insert([themeData])
      .select();
    if (error) throw error;
    return data[0];
  },

  update: async (themeId, updates) => {
    const { data, error } = await supabase
      .from('user_themes')
      .update(updates)
      .eq('theme_id', themeId)
      .select();
    if (error) throw error;
    return data[0];
  },

  getUserThemes: async (userId) => {
    const { data, error } = await supabase
      .from('user_themes')
      .select('*')
      .eq('user_id', userId);
    if (error) throw error;
    return data;
  },

  setActive: async (themeId, userId) => {
    // First, deactivate all themes for this user
    await supabase
      .from('user_themes')
      .update({ is_active: false })
      .eq('user_id', userId);

    // Then activate the selected theme
    const { data, error } = await supabase
      .from('user_themes')
      .update({ is_active: true })
      .eq('theme_id', themeId)
      .select();
    if (error) throw error;
    return data[0];
  },

  getActive: async (userId) => {
    const { data, error } = await supabase
      .from('user_themes')
      .select('*')
      .eq('user_id', userId)
      .eq('is_active', true)
      .single();
    if (error && error.code !== 'PGRST116') throw error; // PGRST116 is "no rows returned"
    return data;
  }
};

export const database = {
  from: (table) => supabase.from(table)
};

export default supabase;