// src/components/tools/CharacterChat/ChatInterface.js

import React, { useRef, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  IconButton,
  Rating,
  Menu,
  MenuItem,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  BookmarkBorder as BookmarkIcon,
  BookmarkAdded as BookmarkFilledIcon,
  MoreVert as MoreVertIcon,
  ContentCopy as CopyIcon,
  Label as LabelIcon,
  Edit as EditIcon
} from '@mui/icons-material';

const ChatInterface = ({
  messages = [],
  isLoading = false,
  onUpdateInsight,
  onEditMessage,
  character
}) => {
  const theme = useTheme();
  const messagesEndRef = useRef(null);
  const [contextMenu, setContextMenu] = React.useState(null);
  const [selectedMessage, setSelectedMessage] = React.useState(null);

  // Auto-scroll to bottom on new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleContextMenu = (event, message) => {
    event.preventDefault();
    setContextMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    setSelectedMessage(message);
  };

  const handleMessageAction = (action) => {
    if (!selectedMessage) return;

    switch (action) {
      case 'copy':
        navigator.clipboard.writeText(selectedMessage.content);
        break;
      case 'bookmark':
        onUpdateInsight(prev => [...prev, {
          type: 'saved_response',
          content: selectedMessage.content,
          timestamp: Date.now()
        }]);
        break;
      case 'edit':
        onEditMessage(selectedMessage);
        break;
      default:
        break;
    }
    setContextMenu(null);
  };

  const MessageBubble = ({ message, index }) => {
    const isUser = message.role === 'user';
    const [rating, setRating] = React.useState(message.rating || 0);

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isUser ? 'row-reverse' : 'row',
          gap: 2,
          mb: 2,
          position: 'relative'
        }}
      >
        <Avatar
          sx={{
            bgcolor: isUser ? 'primary.main' : 'secondary.main',
            width: 40,
            height: 40
          }}
        >
          {isUser ? 'U' : character?.name[0]}
        </Avatar>

        <Box
          sx={{
            maxWidth: '70%',
            position: 'relative'
          }}
        >
          <Paper
            onContextMenu={(e) => handleContextMenu(e, message)}
            sx={{
              p: 2,
              backgroundColor: isUser ? 
                theme.palette.primary.main : 
                theme.palette.background.paper,
              color: isUser ?
                theme.palette.primary.contrastText :
                theme.palette.text.primary,
              borderRadius: 2,
              cursor: 'pointer',
              '&:hover': {
                '& .message-actions': {
                  opacity: 1
                }
              }
            }}
          >
            <Typography variant="body1">
              {message.content}
            </Typography>

            {!isUser && (
              <Box
                className="message-actions"
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  borderTop: `1px solid ${theme.palette.divider}`
                }}
              >
                <Rating
                  size="small"
                  value={rating}
                  onChange={(_, newValue) => {
                    setRating(newValue);
                    if (newValue >= 4) {
                      onUpdateInsight(prev => [...prev, {
                        type: 'highly_rated',
                        content: message.content,
                        rating: newValue,
                        timestamp: Date.now()
                      }]);
                    }
                  }}
                />
                <Tooltip title="Save as example">
                  <IconButton 
                    size="small"
                    onClick={() => handleMessageAction('bookmark')}
                  >
                    {message.bookmarked ? 
                      <BookmarkFilledIcon fontSize="small" /> : 
                      <BookmarkIcon fontSize="small" />
                    }
                  </IconButton>
                </Tooltip>
                <IconButton
                  size="small"
                  onClick={(e) => handleContextMenu(e, message)}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Paper>

          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'block',
              color: 'text.secondary',
              textAlign: isUser ? 'right' : 'left'
            }}
          >
            {new Date(message.timestamp).toLocaleTimeString()}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        bgcolor: 'background.default',
        borderRadius: 1,
        p: 2
      }}
    >
      {messages.map((message, index) => (
        <MessageBubble 
          key={message.timestamp} 
          message={message} 
          index={index} 
        />
      ))}
      <div ref={messagesEndRef} />

      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => handleMessageAction('copy')}>
          <CopyIcon fontSize="small" sx={{ mr: 1 }} />
          Copy text
        </MenuItem>
        <MenuItem onClick={() => handleMessageAction('bookmark')}>
          <BookmarkIcon fontSize="small" sx={{ mr: 1 }} />
          Save as example
        </MenuItem>
        <MenuItem onClick={() => handleMessageAction('edit')}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit message
        </MenuItem>
        <MenuItem onClick={() => handleMessageAction('label')}>
          <LabelIcon fontSize="small" sx={{ mr: 1 }} />
          Add label
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ChatInterface;