// src/components/tools/calibrator/PromptSelection.js

import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Paper,
  useTheme
} from '@mui/material';

const presetPrompts = [
  {
    id: 'detective_sarah',
    label: 'Detective Character Study',
    prompt: `Write a character introduction for Detective Sarah Chen:
- Experienced homicide detective in her late thirties
- Known for solving seemingly impossible cases
- Has a photographic memory and keen eye for micro-expressions
- Carries her grandfather's pocket watch as a lucky charm
- Struggles with insomnia due to cases she can't solve
Please describe her physical appearance and personality, incorporating these details.`
  },
  {
    id: 'coffee_shop_scene',
    label: 'Coffee Shop Scene',
    prompt: `Describe this specific coffee shop scene:
- Located in a converted Victorian townhouse
- Early morning, just after sunrise
- Regular customer Maria works on her novel
- Barista Tom knows everyone's usual order
- Old jazz music plays softly in the background
- Smell of freshly baked croissants fills the air
Describe the atmosphere and details of this moment.`
  },
  {
    id: 'dialogue_siblings',
    label: 'Sibling Dialogue',
    prompt: `Write a dialogue between siblings Alex and Jamie:
- They're cleaning out their childhood home
- Found an old family photo album
- Alex is practical and wants to finish quickly
- Jamie is nostalgic and keeps getting distracted
- Their mother passed away recently
Create a conversation that reveals their different personalities and shared grief.`
  },
  {
    id: 'custom',
    label: 'Custom Prompt (Follow Format)',
    prompt: '',
    placeholder: `For best results, structure your prompt like this:

Task description:
- Key detail 1
- Key detail 2
- Key detail 3
- Key detail 4
- Key detail 5
Specific instructions for what to include.`
  }
];


const PromptSelection = ({ calibrationData, setCalibrationData }) => {
  const theme = useTheme();

  const handlePromptChange = (event) => {
    const selectedPromptId = event.target.value;
    const selectedPrompt = presetPrompts.find(p => p.id === selectedPromptId);
    
    setCalibrationData(prev => ({
      ...prev,
      selectedPrompt: selectedPromptId,
      customPrompt: selectedPromptId === 'custom' ? prev.customPrompt : selectedPrompt.prompt
    }));
  };

  const handleCustomPromptChange = (event) => {
    setCalibrationData(prev => ({
      ...prev,
      customPrompt: event.target.value
    }));
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select a Prompt Type
      </Typography>
      
      <Typography variant="body2" color="textSecondary" paragraph>
        Choose a preset prompt or create your own to generate text variations for calibration.
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl component="fieldset">
            <RadioGroup
              value={calibrationData.selectedPrompt}
              onChange={handlePromptChange}
            >
              {presetPrompts.map((prompt) => (
                <Paper
                  key={prompt.id}
                  elevation={calibrationData.selectedPrompt === prompt.id ? 3 : 1}
                  sx={{
                    mb: 2,
                    p: 2,
                    border: calibrationData.selectedPrompt === prompt.id ? 
                      `2px solid ${theme.palette.primary.main}` : 
                      '1px solid transparent',
                    transition: 'all 0.3s ease'
                  }}
                >
                  <FormControlLabel
                    value={prompt.id}
                    control={<Radio />}
                    label={
                      <Box>
                        <Typography variant="subtitle1">
                          {prompt.label}
                        </Typography>
                        {prompt.id !== 'custom' && (
                          <Typography variant="body2" color="textSecondary">
                            {prompt.prompt}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </Paper>
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          {calibrationData.selectedPrompt === 'custom' && (
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Custom Prompt
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  value={calibrationData.customPrompt}
                  onChange={handleCustomPromptChange}
                  placeholder="Enter your custom prompt here..."
                  variant="outlined"
                />
                <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
                  Be specific about the type of writing you want to analyze. Include details about tone, style, and content.
                </Typography>
              </CardContent>
            </Card>
          )}

          {calibrationData.selectedPrompt && calibrationData.selectedPrompt !== 'custom' && (
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Selected Prompt Preview
                </Typography>
                <Typography variant="body1">
                  {presetPrompts.find(p => p.id === calibrationData.selectedPrompt)?.prompt}
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromptSelection;