import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Alert,
  useTheme
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import supabase from '../../api/supabase';

const ImageUploader = ({ 
  onUpload, 
  initialImage, 
  bucket = 'book-covers',
  path,
  maxSize = 5000000 // 5MB
}) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState(initialImage);
  const [error, setError] = useState(null);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    // Validate file size
    if (file.size > maxSize) {
      setError(`File size must be less than ${maxSize / 1000000}MB`);
      return;
    }

    try {
      setUploading(true);
      setError(null);

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Generate unique filename
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `${path}/${fileName}`;

      // Upload to Supabase storage
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from(bucket)
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from(bucket)
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      // Call onUpload callback with public URL
      onUpload(publicUrl);

    } catch (err) {
      console.error('Upload error:', err);
      setError('Error uploading image. Please try again.');
      setPreview(initialImage);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    if (!preview || !path) return;

    try {
      setUploading(true);
      setError(null);

      // Extract filename from path or URL
      const fileName = path.split('/').pop();
      
      // Delete from Supabase storage
      const { error: deleteError } = await supabase.storage
        .from(bucket)
        .remove([`${path}/${fileName}`]);

      if (deleteError) throw deleteError;

      setPreview(null);
      onUpload(null);

    } catch (err) {
      console.error('Delete error:', err);
      setError('Error deleting image. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box>
      {error && (
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}

      <Box
        sx={{
          border: `2px dashed ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          p: 3,
          textAlign: 'center',
          backgroundColor: theme.palette.background.default,
          position: 'relative'
        }}
      >
        {preview ? (
          <Box sx={{ position: 'relative' }}>
            <img
              src={preview}
              alt="Preview"
              style={{
                maxWidth: '100%',
                maxHeight: '200px',
                objectFit: 'contain',
              }}
            />
            <IconButton
              onClick={handleDelete}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
                color: 'white',
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <Box
            onClick={() => fileInputRef.current?.click()}
            sx={{
              cursor: 'pointer',
              py: 3,
            }}
          >
            <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.text.secondary }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Drop an image here or click to upload
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Supports: JPG, PNG, GIF (max {maxSize / 1000000}MB)
            </Typography>
          </Box>
        )}

        {uploading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          accept="image/*"
          style={{ display: 'none' }}
        />
      </Box>

      <Button
        variant="outlined"
        onClick={() => fileInputRef.current?.click()}
        disabled={uploading}
        sx={{ mt: 2 }}
        fullWidth
      >
        {preview ? 'Change Image' : 'Select Image'}
      </Button>
    </Box>
  );
};

export default ImageUploader;