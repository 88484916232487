import React, { useState } from 'react';
import { 
  Box, 
  Paper,
  Toolbar,
  IconButton,
  Divider,
  useTheme 
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatQuote,
  FormatListBulleted,
  FormatListNumbered,
  Undo,
  Redo
} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ value, onChange, placeholder }) => {
  const theme = useTheme();
  const [editorState, setEditorState] = useState(value || '');

  const modules = {
    toolbar: {
      container: '#toolbar',
    },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true
    }
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ];

  const handleChange = (content) => {
    setEditorState(content);
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <Paper
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden'
      }}
    >
      <Toolbar
        id="toolbar"
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.default
        }}
      >
        <IconButton><FormatBold /></IconButton>
        <IconButton><FormatItalic /></IconButton>
        <IconButton><FormatUnderlined /></IconButton>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <IconButton><FormatQuote /></IconButton>
        <IconButton><FormatListBulleted /></IconButton>
        <IconButton><FormatListNumbered /></IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton><Undo /></IconButton>
        <IconButton><Redo /></IconButton>
      </Toolbar>
      <ReactQuill
        theme="snow"
        value={editorState}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={{
          height: '300px',
          backgroundColor: theme.palette.background.paper
        }}
      />
    </Paper>
  );
};

export default RichTextEditor;