import { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import supabase from '../api/supabase';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { user, loading, signIn, signOut } = context;

  const updateProfile = async (updates) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .upsert({
          id: user.id,
          updated_at: new Date(),
          ...updates
        })
        .select()
        .single();

      if (error) throw error;

      // Update auth metadata
      const { error: updateError } = await supabase.auth.updateUser({
        data: updates
      });

      if (updateError) throw updateError;

      return data;
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  };

  return {
    user,
    loading,
    updateProfile,
    signIn,
    signOut
    // ... other auth methods
  };
};