// src/components/tools/CharacterChat/InsightsPanel.js

import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Button,
  Stack,
  Chip,
  Tooltip,
  Alert,
  Switch,
  FormControlLabel,
  MenuItem,
  Rating,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme
} from '@mui/material';
import {
  Lightbulb as InsightIcon,
  Chat as DialogueIcon,
  LocalOffer as TagIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Save as SaveIcon,
  FilterList as FilterIcon,
  Label as TraitIcon
} from '@mui/icons-material';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TimelineIcon from '@mui/icons-material/Timeline';

const InsightsPanel = ({
  insights = [],
  onUpdate,
  character,
  onSaveToCharacter
}) => {
  const theme = useTheme();
  const [filter, setFilter] = useState('all');
  const [editingInsight, setEditingInsight] = useState(null);
  const [newInsightDialog, setNewInsightDialog] = useState(false);
  const [savedAlert, setSavedAlert] = useState(false);

  const insightTypes = {
    trait: {
      icon: PsychologyIcon,
      label: 'Character Trait',
      color: theme.palette.primary.main
    },
    dialogue: {
      icon: DialogueIcon,
      label: 'Notable Dialogue',
      color: theme.palette.secondary.main
    },
    behavior: {
      icon: PsychologyIcon,
      label: 'Behavior Pattern',
      color: theme.palette.success.main
    },
    development: {
      icon: TimelineIcon,
      label: 'Character Development',
      color: theme.palette.info.main
    }
  };

  const handleSaveInsight = async (insight) => {
    try {
      const updatedCharacter = {
        ...character,
        [insight.type === 'trait' ? 'personality_traits' : 'example_dialogue']: 
          [...character[insight.type === 'trait' ? 'personality_traits' : 'example_dialogue'], 
          insight.content]
      };
      
      await onSaveToCharacter(updatedCharacter);
      setSavedAlert(true);
      setTimeout(() => setSavedAlert(false), 3000);
    } catch (err) {
      console.error('Error saving insight to character:', err);
    }
  };

  const InsightCard = ({ insight, index }) => {
    const TypeIcon = insightTypes[insight.type]?.icon || InsightIcon;
    
    return (
      <Paper
        sx={{
          p: 2,
          mb: 2,
          border: `1px solid ${theme.palette.divider}`,
          '&:hover': {
            borderColor: theme.palette.primary.main,
            '& .insight-actions': {
              opacity: 1
            }
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <TypeIcon 
            sx={{ 
              color: insightTypes[insight.type]?.color,
              mr: 1
            }}
          />
          <Typography variant="subtitle2">
            {insightTypes[insight.type]?.label || 'Insight'}
          </Typography>
          <Box 
            className="insight-actions"
            sx={{ 
              ml: 'auto', 
              opacity: 0,
              transition: 'opacity 0.2s'
            }}
          >
            <Tooltip title="Edit">
              <IconButton 
                size="small"
                onClick={() => setEditingInsight(insight)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save to character profile">
              <IconButton 
                size="small"
                onClick={() => handleSaveInsight(insight)}
              >
                <SaveIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton 
                size="small"
                onClick={() => {
                  onUpdate(prev => prev.filter((_, i) => i !== index));
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Typography variant="body2">
          {insight.content}
        </Typography>

        {insight.rating && (
          <Rating 
            value={insight.rating} 
            readOnly 
            size="small"
            sx={{ mt: 1 }}
          />
        )}

        {insight.relevantChapters?.length > 0 && (
          <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {insight.relevantChapters.map((chapter, i) => (
              <Chip
                key={i}
                label={`Ch. ${chapter}`}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>
        )}

        {insight.tags?.length > 0 && (
          <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {insight.tags.map((tag, i) => (
              <Chip
                key={i}
                icon={<TagIcon />}
                label={tag}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>
        )}

        <Typography 
          variant="caption" 
          color="text.secondary"
          sx={{ display: 'block', mt: 1 }}
        >
          {new Date(insight.timestamp).toLocaleString()}
        </Typography>
      </Paper>
    );
  };

  const filteredInsights = insights.filter(insight => 
    filter === 'all' || insight.type === filter
  );

  return (
    <Paper sx={{ p: 2, overflow: 'auto', height: '100%' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
        <Typography variant="h6">
          Insights & Examples
        </Typography>
        <Box>
          <Tooltip title="Filter insights">
            <IconButton 
              size="small"
              onClick={(e) => setFilter(e.currentTarget)}
            >
              <FilterIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add insight">
            <IconButton 
              size="small"
              onClick={() => setNewInsightDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {savedAlert && (
        <Alert 
          severity="success" 
          sx={{ mb: 2 }}
          onClose={() => setSavedAlert(false)}
        >
          Insight saved to character profile
        </Alert>
      )}

      {filteredInsights.length === 0 ? (
        <Typography 
          color="text.secondary"
          variant="body2"
          sx={{ textAlign: 'center', py: 4 }}
        >
          No insights gathered yet.
          Start chatting to discover more about your character!
        </Typography>
      ) : (
        filteredInsights.map((insight, index) => (
          <InsightCard 
            key={insight.timestamp} 
            insight={insight}
            index={index}
          />
        ))
      )}

      <Dialog
        open={!!editingInsight}
        onClose={() => setEditingInsight(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Insight</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Content"
              value={editingInsight?.content || ''}
              onChange={(e) => setEditingInsight(prev => ({
                ...prev,
                content: e.target.value
              }))}
            />
            <TextField
              fullWidth
              label="Tags"
              placeholder="Add tags separated by commas"
              value={editingInsight?.tags?.join(', ') || ''}
              onChange={(e) => setEditingInsight(prev => ({
                ...prev,
                tags: e.target.value.split(',').map(tag => tag.trim())
              }))}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingInsight(null)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onUpdate(prev => prev.map(insight => 
                insight.timestamp === editingInsight.timestamp ? 
                  editingInsight : insight
              ));
              setEditingInsight(null);
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={newInsightDialog}
        onClose={() => setNewInsightDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Insight</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              select
              fullWidth
              label="Type"
              value={newInsightDialog.type || 'trait'}
              onChange={(e) => setNewInsightDialog(prev => ({
                ...prev,
                type: e.target.value
              }))}
            >
              {Object.entries(insightTypes).map(([value, { label }]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Content"
              value={newInsightDialog.content || ''}
              onChange={(e) => setNewInsightDialog(prev => ({
                ...prev,
                content: e.target.value
              }))}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewInsightDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onUpdate(prev => [...prev, {
                ...newInsightDialog,
                timestamp: Date.now()
              }]);
              setNewInsightDialog(false);
            }}
            disabled={!newInsightDialog.content}
          >
            Add Insight
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default InsightsPanel;