import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  Alert,
  MenuItem,
  Divider,
  useTheme,
  Paper,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import DatabaseService from '../../services/DatabaseService';
import ImageUploader from '../common/ImageUploader';

const GENRES = [
  'Romance',
  'Mystery',
  'Science Fiction',
  'Fantasy',
  'Thriller',
  'Historical Fiction',
  'Literary Fiction',
  'Horror',
  'Young Adult',
  'Children\'s',
  'Biography',
  'Memoir',
  'Self-Help',
  'Crime',
  'Adventure',
  'Contemporary Fiction',
  'Dystopian',
  'Paranormal',
  'Women\'s Fiction',
  'Literary Nonfiction'
];

const BookDetails = ({ bookId }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    genre: '',
    setting: '',
    author_notes: '',
    premise: '',
    target_audience: '',
    is_series: false,
    series_name: '',
    series_order: '',
    cover_image: null
  });

  useEffect(() => {
    if (bookId) {
      loadBook();
    }
  }, [bookId]);

  const loadBook = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await DatabaseService.getBook(bookId);

      if (data) {
        setFormData({
          title: data.title || '',
          description: data.description || '',
          genre: data.genre || '',
          setting: data.setting || '',
          author_notes: data.author_notes || '',
          premise: data.premise || '',
          target_audience: data.target_audience || '',
          is_series: data.is_series || false,
          series_name: data.series_name || '',
          series_order: data.series_order || '',
          cover_image: data.cover_image || null
        });
      }
    } catch (err) {
      console.error('Error loading book:', err);
      setError('Failed to load book details');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCoverUpload = async (url) => {
    setFormData(prev => ({
      ...prev,
      cover_image: url
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title.trim()) {
      setError('Book title is required');
      return;
    }

    try {
      setSaving(true);
      setError(null);
      setSuccess(null);

      await DatabaseService.updateBook(bookId, {
        ...formData,
        updated_at: new Date().toISOString()
      });

      setSuccess('Book details saved successfully!');
      await loadBook(); // Reload to get fresh data
    } catch (err) {
      console.error('Error saving book:', err);
      setError('Failed to save book details');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            Book Details
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Update your book's information and settings
          </Typography>
        </Box>

        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 3 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        {success && (
          <Alert 
            severity="success" 
            sx={{ mb: 3 }}
            onClose={() => setSuccess(null)}
          >
            {success}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Cover Image Section */}
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 2, 
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Cover Image
                </Typography>
                <ImageUploader
                  onUpload={handleCoverUpload}
                  initialImage={formData.cover_image}
                  bucket="book-covers"
                  path={`${user.id}/${bookId}`}
                />
              </Paper>
            </Grid>

            {/* Book Details Section */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                    error={!formData.title.trim()}
                    helperText={!formData.title.trim() ? 'Title is required' : ''}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Provide a brief description of your book..."
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Premise"
                    name="premise"
                    value={formData.premise}
                    onChange={handleChange}
                    placeholder="What's the main premise of your story?"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    label="Genre"
                    name="genre"
                    value={formData.genre}
                    onChange={handleChange}
                  >
                    {GENRES.map((genre) => (
                      <MenuItem key={genre} value={genre}>
                        {genre}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Target Audience"
                    name="target_audience"
                    value={formData.target_audience}
                    onChange={handleChange}
                    placeholder="Who is your book written for?"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Setting"
                    name="setting"
                    value={formData.setting}
                    onChange={handleChange}
                    placeholder="Where and when does your story take place?"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Author Notes"
                    name="author_notes"
                    value={formData.author_notes}
                    onChange={handleChange}
                    placeholder="Any additional notes about the book..."
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Series Information
                  </Typography>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      p: 2, 
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 1
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.is_series}
                          onChange={(e) => handleChange({
                            target: {
                              name: 'is_series',
                              type: 'checkbox',
                              checked: e.target.checked
                            }
                          })}
                          name="is_series"
                        />
                      }
                      label="This book is part of a series"
                    />

                    {formData.is_series && (
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={8}>
                          <TextField
                            fullWidth
                            label="Series Name"
                            name="series_name"
                            value={formData.series_name}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Book # in Series"
                            name="series_order"
                            type="number"
                            value={formData.series_order}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={loadBook}
                  disabled={saving}
                >
                  Reset Changes
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={saving}
                >
                  {saving ? 'Saving...' : 'Save Changes'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default BookDetails;