import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import supabase from '../../api/supabase';
import RichTextEditor from '../common/RichTextEditor';

const ChapterPlans = ({ bookId }) => {
  const theme = useTheme();
  const [chapters, setChapters] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    summary: '',
    keywords: '',
    order_number: '',
    content: '',
    goals: '',
    notes: '',
    status: 'planned' // planned, in_progress, completed
  });

  useEffect(() => {
    fetchChapters();
  }, [bookId]);

  const fetchChapters = async () => {
    try {
      const { data, error } = await supabase
        .from('chapters')
        .select('*')
        .eq('book_id', bookId)
        .order('order_number', { ascending: true });

      if (error) throw error;
      setChapters(data);
    } catch (err) {
      setError('Failed to load chapters');
      console.error('Error:', err);
    }
  };

  const handleOpen = (chapter = null, view = false) => {
    if (chapter) {
      setSelectedChapter(chapter);
      setFormData(chapter);
    } else {
      setSelectedChapter(null);
      setFormData({
        title: '',
        summary: '',
        keywords: '',
        order_number: chapters.length + 1,
        content: '',
        goals: '',
        notes: '',
        status: 'planned'
      });
    }
    setViewMode(view);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChapter(null);
    setViewMode(false);
    setError(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleContentChange = (content) => {
    setFormData(prev => ({
      ...prev,
      content: content
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedChapter) {
        const { error } = await supabase
          .from('chapters')
          .update(formData)
          .eq('chapter_id', selectedChapter.chapter_id);

        if (error) throw error;
        setSuccess('Chapter updated successfully');
      } else {
        const { error } = await supabase
          .from('chapters')
          .insert([{ ...formData, book_id: bookId }]);

        if (error) throw error;
        setSuccess('Chapter created successfully');
      }
      
      fetchChapters();
      handleClose();
    } catch (err) {
      setError('Failed to save chapter');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (chapterId) => {
    if (!window.confirm('Are you sure you want to delete this chapter?')) return;

    try {
      const { error } = await supabase
        .from('chapters')
        .delete()
        .eq('chapter_id', chapterId);

      if (error) throw error;
      
      setSuccess('Chapter deleted successfully');
      fetchChapters();
    } catch (err) {
      setError('Failed to delete chapter');
      console.error('Error:', err);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'planned':
        return theme.palette.info.main;
      case 'in_progress':
        return theme.palette.warning.main;
      case 'completed':
        return theme.palette.success.main;
      default:
        return theme.palette.text.secondary;
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">Chapter Plans</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
          >
            Add Chapter
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Chapter</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Summary</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chapters
                .sort((a, b) => a.order_number - b.order_number)
                .map((chapter) => (
                  <TableRow key={chapter.chapter_id}>
                    <TableCell>{chapter.order_number}</TableCell>
                    <TableCell>{chapter.title}</TableCell>
                    <TableCell>{chapter.summary}</TableCell>
                    <TableCell>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: getStatusColor(chapter.status),
                          fontWeight: 'medium'
                        }}
                      >
                        {chapter.status?.replace('_', ' ').toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleOpen(chapter, true)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton onClick={() => handleOpen(chapter)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(chapter.chapter_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog 
          open={open} 
          onClose={handleClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {viewMode 
              ? `View Chapter: ${selectedChapter?.title}`
              : selectedChapter 
                ? 'Edit Chapter' 
                : 'Add New Chapter'
            }
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <TextField
                label="Chapter Number"
                name="order_number"
                type="number"
                value={formData.order_number}
                onChange={handleChange}
                required
                disabled={viewMode}
              />
              <TextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                disabled={viewMode}
              />
              <TextField
                multiline
                rows={4}
                label="Summary"
                name="summary"
                value={formData.summary}
                onChange={handleChange}
                required
                disabled={viewMode}
              />
              <TextField
                label="Keywords"
                name="keywords"
                value={formData.keywords}
                onChange={handleChange}
                helperText="Separate keywords with commas"
                disabled={viewMode}
              />
              <TextField
                multiline
                rows={3}
                label="Goals"
                name="goals"
                value={formData.goals}
                onChange={handleChange}
                disabled={viewMode}
              />
              <TextField
                multiline
                rows={3}
                label="Notes"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                disabled={viewMode}
              />
              <TextField
                select
                label="Status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                disabled={viewMode}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="planned">Planned</option>
                <option value="in_progress">In Progress</option>
                <option value="completed">Completed</option>
              </TextField>
              {(selectedChapter || !viewMode) && (
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Chapter Content
                  </Typography>
                  <RichTextEditor
                    value={formData.content}
                    onChange={handleContentChange}
                    readOnly={viewMode}
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {viewMode ? 'Close' : 'Cancel'}
            </Button>
            {!viewMode && (
              <Button 
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Chapter'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default ChapterPlans;