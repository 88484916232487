import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import supabase from '../api/supabase';

export const useBook = (bookId) => {
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBook = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('books')
        .select(`
          *,
          characters (*),
          chapters (*),
          plots (*),
          settings (*)
        `)
        .eq('book_id', bookId)
        .single();

      if (error) throw error;
      setBook(data);
      return data;
    } catch (err) {
      setError(err.message);
      console.error('Error fetching book:', err);
    } finally {
      setLoading(false);
    }
  };

  const updateBook = async (updates) => {
    try {
      const { data, error } = await supabase
        .from('books')
        .update({ ...updates, updated_at: new Date() })
        .eq('book_id', bookId)
        .select()
        .single();

      if (error) throw error;
      setBook(prev => ({ ...prev, ...data }));
      return data;
    } catch (err) {
      console.error('Error updating book:', err);
      throw err;
    }
  };

  const updateChapter = async (updates) => {
    try {
      const { data, error } = await supabase
        .from('chapters')
        .update({ ...updates, updated_at: new Date() })
        .eq('book_id', bookId)
        .select()
        .single();

      if (error) throw error;
    } catch (err) {
      console.error('Error updating book:', err);
      throw err;
    }
  };

  useEffect(() => {
    if (bookId) {
      fetchBook();
    }
  }, [bookId]);

  return {
    book,
    loading,
    error,
    updateBook,
    fetchBook,
    updateChapter
};
};