// src/components/profile/UserProfile.js
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Alert,
  Avatar,
  CircularProgress,
  Divider,
  MenuItem,
  FormControlLabel,
  Switch,
  IconButton,
  useTheme,
  Stack
} from '@mui/material';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  Language as WebsiteIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import ImageUploader from '../common/ImageUploader';
import { formatPhoneNumber, capitalizeWords } from '../../utils/formatters';

const TIMEZONES = [
  'UTC-12:00', 'UTC-11:00', 'UTC-10:00', 'UTC-09:00', 'UTC-08:00', 'UTC-07:00',
  'UTC-06:00', 'UTC-05:00', 'UTC-04:00', 'UTC-03:00', 'UTC-02:00', 'UTC-01:00',
  'UTC+00:00', 'UTC+01:00', 'UTC+02:00', 'UTC+03:00', 'UTC+04:00', 'UTC+05:00',
  'UTC+06:00', 'UTC+07:00', 'UTC+08:00', 'UTC+09:00', 'UTC+10:00', 'UTC+11:00',
  'UTC+12:00'
];

const PRONOUNS = [
  'He/Him',
  'She/Her',
  'They/Them',
  'He/They',
  'She/They',
  'Other',
  'Prefer not to say'
];

const TITLES = [
  'Mr.',
  'Mrs.',
  'Ms.',
  'Miss',
  'Dr.',
  'Prof.',
  'Mx.',
  'Other'
];

const UserProfile = () => {
  const theme = useTheme();
  const { user, updateProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    firstName: '',
    lastName: '',
    penName: '',
    email: '',
    phoneNumber: '',
    timezone: '',
    pronouns: '',
    bio: '',
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    },
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      website: ''
    },
    avatar_url: '',
    publicProfile: false
  });

  useEffect(() => {
    if (user) {
      setFormData(prev => ({
        ...prev,
        ...user.user_metadata,
        email: user.email,
        firstName: capitalizeWords(user.user_metadata?.firstName || ''),
        lastName: capitalizeWords(user.user_metadata?.lastName || ''),
        avatar_url: user.user_metadata?.avatar_url || ''
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handlePhoneChange = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData(prev => ({
      ...prev,
      phoneNumber: formatted
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      
      // Prepare the settings object
      const settings = {
        title: formData.title,
        firstName: capitalizeWords(formData.firstName),
        lastName: capitalizeWords(formData.lastName),
        penName: formData.penName,
        phoneNumber: formData.phoneNumber,
        timezone: formData.timezone,
        pronouns: formData.pronouns,
        bio: formData.bio,
        address: formData.address,
        social: formData.social,
        publicProfile: formData.publicProfile
      };
  
      await updateProfile({
        avatar_url: formData.avatar_url,
        settings  // This will be stored in the JSONB settings column
      });
      
      setSuccess('Profile updated successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  // And modify the useEffect that loads the data:
  useEffect(() => {
    if (user) {
      const settings = user.user_metadata?.settings || {};
      setFormData({
        ...settings,  // Spread all settings
        email: user.email,
        avatar_url: user.user_metadata?.avatar_url || '',
        // Set defaults for nested objects if they don't exist
        address: settings.address || {
          street: '',
          city: '',
          state: '',
          postalCode: '',
          country: ''
        },
        social: settings.social || {
          facebook: '',
          twitter: '',
          instagram: '',
          website: ''
        }
      });
    }
  }, [user]);
  
  if (!user) return <CircularProgress />;

  return (
    <Box sx={{ maxWidth: 1200, mx: '2px', p: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Avatar Section */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box sx={{ textAlign: 'center' }}>
                  <Avatar
                    src={formData.avatar_url}
                    alt={`${formData.firstName} ${formData.lastName}`}
                    sx={{ 
                      width: 120, 
                      height: 120, 
                      mx: 'auto', 
                      mb: 2,
                      border: `2px solid ${theme.palette.primary.main}`
                    }}
                  />
                  <ImageUploader
                    onUpload={(url) => handleChange({
                      target: { name: 'avatar_url', value: url }
                    })}
                    bucket="avatars"
                    path={`${user.id}/avatar`}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.publicProfile}
                        onChange={(e) => handleChange({
                          target: { 
                            name: 'publicProfile', 
                            value: e.target.checked 
                          }
                        })}
                      />
                    }
                    label="Public Profile"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Main Details */}
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Personal Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      fullWidth
                      label="Title"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    >
                      {TITLES.map((title) => (
                        <MenuItem key={title} value={title}>
                          {title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Pen Name"
                      name="penName"
                      value={formData.penName}
                      onChange={handleChange}
                      helperText="Name you publish under (optional)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Pronouns"
                      name="pronouns"
                      value={formData.pronouns}
                      onChange={handleChange}
                    >
                      {PRONOUNS.map((pronoun) => (
                        <MenuItem key={pronoun} value={pronoun}>
                          {pronoun}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={formData.email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handlePhoneChange}
                      placeholder="(XXX) XXX-XXXX"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Timezone"
                      name="timezone"
                      value={formData.timezone}
                      onChange={handleChange}
                    >
                      {TIMEZONES.map((timezone) => (
                        <MenuItem key={timezone} value={timezone}>
                          {timezone}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Typography variant="h6" gutterBottom>
                  Address
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Street Address"
                      name="address.street"
                      value={formData.address.street}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="City"
                      name="address.city"
                      value={formData.address.city}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="State/Province"
                      name="address.state"
                      value={formData.address.state}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Postal Code"
                      name="address.postalCode"
                      value={formData.address.postalCode}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Country"
                      name="address.country"
                      value={formData.address.country}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Typography variant="h6" gutterBottom>
                  Social Media
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Facebook"
                      name="social.facebook"
                      value={formData.social.facebook}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <FacebookIcon color="action" sx={{ mr: 1 }} />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Twitter"
                      name="social.twitter"
                      value={formData.social.twitter}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <TwitterIcon color="action" sx={{ mr: 1 }} />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Instagram"
                      name="social.instagram"
                      value={formData.social.instagram}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <InstagramIcon color="action" sx={{ mr: 1 }} />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Website"
                      name="social.website"
                      value={formData.social.website}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <WebsiteIcon color="action" sx={{ mr: 1 }} />
                      }}
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Typography variant="h6" gutterBottom>
                  Bio
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="About You"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                  helperText="Tell us about yourself and your writing"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          {error && (
            <Alert severity="error" sx={{ flexGrow: 1 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ flexGrow: 1 }}>
              {success}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ minWidth: 120 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UserProfile;
