import Dashboard from './components/dashboard/Dashboard';
import UserProfile from './components/profile/UserProfile';
import Subscription from './components/billing/Subscription';
import Help from './components/support/Help';
import ModelSelection from './components/settings/ModelSelection';
import Integrations from './components/settings/Integrations';
import ImageGenerator from './components/tools/ImageGenerator';
import Gallery from './components/tools/Gallery';
import Calibrator from './components/tools/Calibrator';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ThemeCustomizer from './components/tools/ThemeCustomizer';
import CharacterChat from './components/tools/CharacterChat/CharacterChat';
import SceneBuilder from './components/tools/SceneBuilder/SceneBuilder';


import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import HelpIcon from '@mui/icons-material/Help';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TuneIcon from '@mui/icons-material/Tune';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import AssistantIcon from '@mui/icons-material/Assistant';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PaymentIcon from '@mui/icons-material/Payment';
import ChatIcon from '@mui/icons-material/Chat';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';


const routes = {
  public: [
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/register',
      component: Register,
    },
  ],
  private: [
    {
      path: '/dashboard',
      component: Dashboard,
      label: 'Dashboard',
      icon: <DashboardIcon />,
      section: 'main',
    },
    {
      path: '/profile',
      component: UserProfile,
      label: 'My Profile',
      icon: <PersonIcon />,
      section: 'user',
    },
    {
      path: '/billing',
      component: Subscription,
      label: 'Subscription',
      icon: <PaymentIcon />,
      section: 'user',
    },
    {
      path: '/settings/model',
      component: ModelSelection,
      label: 'AI Model Selection',
      icon: <AssistantIcon />,
      section: 'configure',
    },
    {
      path: '/settings/integrations',
      component: Integrations,
      label: 'Integrations',
      icon: <IntegrationInstructionsIcon/>,
      section: 'configure',
    },
    {
      path: '/tools/imagemaker',
      component: ImageGenerator,
      label: 'Image Generator',
      icon: <BurstModeIcon />,
      section: 'tools',
    },
    {
      path: '/tools/gallery',
      component: Gallery,
      label: 'Gallery',
      icon: <ImageSearchIcon/>,
      section: 'tools',
    },
    {
      path: '/tools/calibrator',
      element: <Calibrator />,
      label: 'Calibrator',
      icon: <TuneIcon />,
      section: 'tools',
    },
    {
      path: '/tools/character-chat',
      component: CharacterChat,
      label: 'Character Chat',
      icon: <ChatIcon />,
      section: 'tools',
    },
    {
      path: '/tools/character-chat/:bookId',
      component: CharacterChat,
      hidden: true
    },
    {
      path: '/tools/character-chat/:bookId/:characterId',
      component: CharacterChat,
      hidden: true
    },
    {
      path: '/tools/scene-builder',
      component: SceneBuilder,
      label: 'Scene Builder',
      icon: <AutoStoriesIcon />,
      section: 'tools',
    },
    {
      path: '/tools/scene-builder/:bookId',
      component: SceneBuilder,
      hidden: true
    },
    {
      path: '/support',
      component: Help,
      label: 'Help & Support',
      icon: <HelpIcon />,
      section: 'help',
    }
  ],
};

export const getRouteByPath = (path) => {
  return [...routes.private, ...routes.public].find(route => route.path === path);
};

export const getRoutesBySection = (section) => {
  return routes.private.filter(route => route.section === section);
};

export default routes;