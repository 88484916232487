// src/components/tools/calibrator/StyleOptions.js

import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  useTheme
} from '@mui/material';

const PARAMETER_OPTIONS = {
  temperature: {
    label: 'Temperature',
    description: 'Controls randomness in the output. Higher values make the output more random, lower values make it more focused and deterministic.',
    defaultValue: 0.7,
    testValues: [
      { label: 'Very Conservative', value: 0.3 },
      { label: 'Balanced', value: 0.7 },
      { label: 'Creative', value: 1.0 },
      { label: 'Slightly Adjusted', value: 0.8 }
    ]
  },
  top_p: {
    label: 'Top P (Nucleus Sampling)',
    description: 'Controls diversity via nucleus sampling. Lower values make the output more focused on likely tokens, higher values allow more diversity.',
    defaultValue: 0.9,
    testValues: [
      { label: 'Focused', value: 0.5 },
      { label: 'Balanced', value: 0.9 },
      { label: 'Diverse', value: 1.0 },
      { label: 'Slightly Adjusted', value: 0.85 }
    ]
  },
  top_k: {
    label: 'Top K',
    description: 'Limits the cumulative probability of tokens considered for each step of text generation.',
    defaultValue: 40,
    testValues: [
      { label: 'Restrictive', value: 20 },
      { label: 'Balanced', value: 40 },
      { label: 'Permissive', value: 60 },
      { label: 'Slightly Adjusted', value: 45 }
    ]
  }
};

const StyleOptions = ({ calibrationData, setCalibrationData }) => {
  const theme = useTheme();

  const handleParameterSelect = (parameter) => {
    setCalibrationData(prev => ({
      ...prev,
      selectedParameter: parameter,
      generationSettings: {
        parameter,
        baseValue: PARAMETER_OPTIONS[parameter].defaultValue,
        testValues: PARAMETER_OPTIONS[parameter].testValues
      }
    }));
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Parameter Calibration
      </Typography>

      <Alert severity="info" sx={{ mb: 3 }}>
        Select a parameter to calibrate. We'll generate variations using different values to help you find the optimal setting.
      </Alert>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <FormControl fullWidth>
                <InputLabel>Select Parameter</InputLabel>
                <Select
                  value={calibrationData.selectedParameter || ''}
                  onChange={(e) => handleParameterSelect(e.target.value)}
                >
                  {Object.entries(PARAMETER_OPTIONS).map(([key, option]) => (
                    <MenuItem key={key} value={key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {calibrationData.selectedParameter && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    {PARAMETER_OPTIONS[calibrationData.selectedParameter].label}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {PARAMETER_OPTIONS[calibrationData.selectedParameter].description}
                  </Typography>
                  
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Test Values:
                    </Typography>
                    {PARAMETER_OPTIONS[calibrationData.selectedParameter].testValues.map((value, index) => (
                      <Typography key={index} variant="body2">
                        • {value.label}: {value.value}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Test Configuration
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                We'll generate four variations of your prompt using GPT-4-mini:
              </Typography>
              <ul>
                <li>A balanced/typical value</li>
                <li>A significantly higher value</li>
                <li>A significantly lower value</li>
                <li>A slightly adjusted value</li>
              </ul>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                After you provide feedback, Claude-3.5-sonnet will analyze the results and recommend optimal settings.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StyleOptions;