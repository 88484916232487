import React, { useState, useEffect } from 'react';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Typography, 
  Box, 
  Button,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  styled,
  Stack,
  Avatar
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { getRoutesBySection } from '../../routes';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Logo from '../common/Logo';
import supabase from '../../api/supabase';

const DRAWER_WIDTH = 280;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    borderRight: 'none',
    boxShadow: '4px 0 24px rgba(0, 0, 0, 0.05)',
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  padding: theme.spacing(0, 2),
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(0),
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  margin: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.2s ease',
  position: 'relative',
  overflow: 'hidden',
  ...(selected && {
    backgroundColor: `${theme.palette.primary.main}10`,
    color: theme.palette.primary.main,
  }),
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}10`,
    transform: 'translateX(5px)',
  },
  '& .MuiListItemIcon-root': {
    color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
    minWidth: 40,
  },
  '& .MuiListItemText-primary': {
    fontWeight: selected ? 600 : 400,
    fontSize: '0.82rem',
  }
}));

const BookSelector = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 200,
}));

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState('');
  const { signOut } = useAuth();
  useEffect(() => {
    fetchBooks();
  }, [user]);

  const fetchBooks = async () => {
    try {
      const { data, error } = await supabase
        .from('books')
        .select('book_id, title')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setBooks(data || []);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const handleBookChange = (event) => {
    setSelectedBook(event.target.value);
    navigate(`/dashboard/${event.target.value}`);
  };

  const handleCreateBook = () => {
    navigate('/new-book');
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <StyledDrawer variant="permanent">
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Logo */}
        <Box sx={{ 
          height: 110, 
          display: 'flex', 
          alignItems: 'center', 
          px: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Logo />
        </Box>

        {/* Book Selection */}
        <Box sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
          <Stack spacing={0}>
            <BookSelector>
              <InputLabel>Select Book</InputLabel>
              <Select
                value={selectedBook}
                onChange={handleBookChange}
                label="Select Book"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>Select a book</em>
                </MenuItem>
                {books.map((book) => (
                  <MenuItem key={book.book_id} value={book.book_id}>
                    {book.title}
                  </MenuItem>
                ))}
              </Select>
            </BookSelector>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCreateBook}
              size="small"
              style={{ maxWidth: '250px', marginLeft: '8px', marginBottom: '8px' }}
            >
              Create New Book
            </Button>
          </Stack>
        </Box>

        {/* Navigation Sections */}
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          {['main', 'user', 'configure', 'tools', 'help'].map((section) => (
            <Box key={section}>
              <SectionTitle>
                {section === 'main' ? 'Navigation' : section}
              </SectionTitle>
              <List>
                {getRoutesBySection(section).map((route) => (
                  <StyledListItem
                    button
                    key={route.path}
                    selected={location.pathname === route.path}
                    onClick={() => navigate(route.path)}
                  >
                    <ListItemIcon>
                      {route.icon}
                    </ListItemIcon>
                    <ListItemText primary={route.label} />
                  </StyledListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
        <Box sx={{ 
  p: 1, 
  borderTop: 1, 
  borderColor: 'divider' 
}}>
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 2, 
    mb: 0.5 
  }}>
    <Avatar 
      src={user?.user_metadata?.avatar_url} 
      alt={user?.email}
    />
    <Box>
      <Typography variant="subtitle2">
        {user?.email}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {user?.user_metadata?.name || 'User'}
      </Typography>
    </Box>
  </Box>
</Box>
        {/* Logout Section */}
        <Box sx={{ p: 1, borderTop: 1, borderColor: 'divider' }}>
          <Button
            fullWidth
            variant="outlined"
            color="inherit"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
          >
            Sign Out
          </Button>
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default Sidebar;