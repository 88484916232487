import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Chip,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RichTextEditor from '../common/RichTextEditor';
import supabase from '../../api/supabase';

const NarrativeStyle = ({ bookId }) => {
  const theme = useTheme();
  const [narrativeStyle, setNarrativeStyle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [formData, setFormData] = useState({
    description: '',
    example_text: '',
    inspiration_source: '',
    tone: '',
    pacing: '',
    perspective: '',
    language_style: ''
  });

  useEffect(() => {
    fetchNarrativeStyle();
  }, [bookId]);

  const fetchNarrativeStyle = async () => {
    try {
      const { data, error } = await supabase
        .from('narrative_styles')
        .select('*')
        .eq('book_id', bookId)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      setNarrativeStyle(data || null);
      if (data) setFormData(data);
    } catch (err) {
      setError('Failed to load narrative style');
      console.error('Error:', err);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const data = {
        ...formData,
        book_id: bookId
      };

      const { error } = narrativeStyle
        ? await supabase
            .from('narrative_styles')
            .update(data)
            .eq('narrative_style_id', narrativeStyle.narrative_style_id)
        : await supabase
            .from('narrative_styles')
            .insert([data]);

      if (error) throw error;
      
      setSuccess('Narrative style saved successfully');
      fetchNarrativeStyle();
    } catch (err) {
      setError('Failed to save narrative style');
      console.error('Error:', err);
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleExampleTextChange = (content) => {
    setFormData(prev => ({
      ...prev,
      example_text: content
    }));
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">Narrative Style</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogType('edit');
              setOpenDialog(true);
            }}
          >
            {narrativeStyle ? 'Edit Style' : 'Define Style'}
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        {narrativeStyle ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Style Description
                </Typography>
                <Typography variant="body1" paragraph>
                  {narrativeStyle.description}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Tone & Pacing
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Tone:
                </Typography>
                <Typography variant="body2" paragraph>
                  {narrativeStyle.tone}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Pacing:
                </Typography>
                <Typography variant="body2" paragraph>
                  {narrativeStyle.pacing}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Perspective & Language
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Perspective:
                </Typography>
                <Typography variant="body2" paragraph>
                  {narrativeStyle.perspective}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Language Style:
                </Typography>
                <Typography variant="body2" paragraph>
                  {narrativeStyle.language_style}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Example Text
                </Typography>
                <Typography variant="body1">
                  {narrativeStyle.example_text}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Typography color="textSecondary" align="center">
            No narrative style defined yet. Click the button above to define your style.
          </Typography>
        )}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {narrativeStyle ? 'Edit Narrative Style' : 'Define Narrative Style'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Style Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Tone"
                  name="tone"
                  value={formData.tone}
                  onChange={handleChange}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Pacing"
                  name="pacing"
                  value={formData.pacing}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Perspective"
                  name="perspective"
                  value={formData.perspective}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Language Style"
                  name="language_style"
                  value={formData.language_style}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Example Text
                </Typography>
                <RichTextEditor
                  value={formData.example_text}
                  onChange={handleExampleTextChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save Style'}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default NarrativeStyle;