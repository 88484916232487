// src/components/tools/calibrator/FeedbackPanel.js

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Rating,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
  Alert,
  useTheme
} from '@mui/material';

const FeedbackPanel = ({ calibrationData, setCalibrationData }) => {
  const theme = useTheme();

  const handleRatingChange = (index, value) => {
    setCalibrationData(prev => ({
      ...prev,
      feedback: {
        ...prev.feedback,
        ratings: {
          ...prev.feedback.ratings,
          [index]: {
            rating: value,
            parameterValue: prev.generationSettings.testValues[index].value,
            parameterLabel: prev.generationSettings.testValues[index].label
          }
        }
      }
    }));
  };

  const handleCommentChange = (index, comment) => {
    setCalibrationData(prev => ({
      ...prev,
      feedback: {
        ...prev.feedback,
        comments: {
          ...prev.feedback.comments,
          [index]: comment
        }
      }
    }));
  };

  const handleEffectiveness = (index, effects) => {
    setCalibrationData(prev => ({
      ...prev,
      feedback: {
        ...prev.feedback,
        effectiveness: {
          ...prev.feedback.effectiveness,
          [index]: effects
        }
      }
    }));
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Parameter Feedback
      </Typography>

      <Alert severity="info" sx={{ mb: 3 }}>
        Rate how well each variation achieves the desired output quality. Consider how the different 
        {' '}{calibrationData.selectedParameter}{' '} 
        values affect the text.
      </Alert>

      <Grid container spacing={3}>
        {calibrationData.generatedTexts.map((text, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardContent>
                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="subtitle1">
                    Variation {index + 1}
                  </Typography>
                  <Chip 
                    label={`${calibrationData.selectedParameter}: ${calibrationData.generationSettings.testValues[index].value}`}
                    color="primary"
                    variant="outlined"
                  />
                </Box>

                <Typography variant="body1" sx={{ 
                  mb: 3,
                  p: 2,
                  backgroundColor: theme.palette.background.default,
                  borderRadius: 1
                }}>
                  {text.content}
                </Typography>

                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Quality Rating
                  </Typography>
                  <Rating
                    value={calibrationData.feedback?.ratings[index]?.rating || 0}
                    onChange={(_, value) => handleRatingChange(index, value)}
                    max={10}
                    size="large"
                  />
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Observed Effects
                  </Typography>
                  <Grid container spacing={1}>
                    {getEffectOptions(calibrationData.selectedParameter).map((effect) => (
                      <Grid item key={effect.value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={calibrationData.feedback?.effectiveness[index]?.includes(effect.value) || false}
                              onChange={(e) => {
                                const currentEffects = calibrationData.feedback?.effectiveness[index] || [];
                                handleEffectiveness(
                                  index,
                                  e.target.checked
                                    ? [...currentEffects, effect.value]
                                    : currentEffects.filter(v => v !== effect.value)
                                );
                              }}
                            />
                          }
                          label={effect.label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Specific Observations"
                  value={calibrationData.feedback?.comments[index] || ''}
                  onChange={(e) => handleCommentChange(index, e.target.value)}
                  placeholder={`How did this ${calibrationData.selectedParameter} value affect the output?`}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const getEffectOptions = (parameter) => {
  const commonEffects = [
    { value: 'coherent', label: 'Coherent' },
    { value: 'incoherent', label: 'Incoherent' },
    { value: 'creative', label: 'Creative' },
    { value: 'repetitive', label: 'Repetitive' },
  ];

  const parameterSpecificEffects = {
    temperature: [
      { value: 'focused', label: 'Focused' },
      { value: 'random', label: 'Random' },
      { value: 'predictable', label: 'Predictable' },
      { value: 'surprising', label: 'Surprising' },
    ],
    top_p: [
      { value: 'diverse', label: 'Diverse Vocabulary' },
      { value: 'limited', label: 'Limited Vocabulary' },
      { value: 'natural', label: 'Natural Flow' },
      { value: 'forced', label: 'Forced Flow' },
    ],
    top_k: [
      { value: 'conservative', label: 'Conservative' },
      { value: 'adventurous', label: 'Adventurous' },
      { value: 'stable', label: 'Stable' },
      { value: 'unstable', label: 'Unstable' },
    ],
  };

  return [...commonEffects, ...(parameterSpecificEffects[parameter] || [])];
};

export default FeedbackPanel;