import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Button,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Switch,
  Snackbar,
  Alert,
  useTheme,
  Accordion,           // Added
  AccordionSummary,    // Added
  AccordionDetails,    // Added
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaletteIcon from '@mui/icons-material/Palette';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PreviewIcon from '@mui/icons-material/Preview';
import { useUI } from '../../hooks/useUI';
import { useCustomTheme } from '../../hooks/useCustomTheme';

// Import color sections
import PrimaryColors from './sections/PrimaryColors';
import SecondaryColors from './sections/SecondaryColors';
import BackgroundColors from './sections/BackgroundColors';
import TextColors from './sections/TextColors';
import StateColors from './sections/StateColors';

// Import typography sections
import FontSettings from './sections/FontSettings';
import HeadingStyles from './sections/HeadingStyles';
import TextStyles from './sections/TextStyles';
import LivePreview from './sections/LivePreview'; 

// Import component sections
import ButtonStyles from './sections/ButtonStyles';
import CardStyles from './sections/CardStyles';
import InputStyles from './sections/InputStyles';

const DRAWER_WIDTH = 400;

const ThemeCustomizerDrawer = () => {
  const theme = useTheme();
  const { themeCustomizerOpen, toggleThemeCustomizer } = useUI();
  const { 
    userThemes, 
    saveTheme, 
    deleteTheme, 
    setThemeAsActive, 
    loading: themesLoading 
  } = useCustomTheme();
  
  const [activeTab, setActiveTab] = useState(0);
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [themeName, setThemeName] = useState('');
  const [showSavedThemes, setShowSavedThemes] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState('');

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : '');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setExpandedAccordion('');
  };

  const handleThemeChange = (updates) => {
    setCurrentTheme(prev => ({
      ...prev,
      ...updates
    }));
  };

  const handleSave = async () => {
    try {
      if (!themeName.trim()) {
        setError('Please enter a theme name');
        return;
      }

      await saveTheme(themeName, currentTheme, true);
      setSuccess('Theme saved successfully!');
      setThemeName('');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (themeId) => {
    try {
      await deleteTheme(themeId);
      setSuccess('Theme deleted successfully!');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleActivate = async (themeId) => {
    try {
      await setThemeAsActive(themeId);
      setSuccess('Theme activated successfully!');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEditTheme = (savedTheme) => {
    setCurrentTheme(savedTheme.theme_data);
    setThemeName(savedTheme.name);
    setShowSavedThemes(false);
  };

  const renderColorSettings = () => (
    <Box>
      <Accordion 
        expanded={expandedAccordion === 'primary'} 
        onChange={handleAccordionChange('primary')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Primary Colors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PrimaryColors currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'secondary'} 
        onChange={handleAccordionChange('secondary')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Secondary Colors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SecondaryColors currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'background'} 
        onChange={handleAccordionChange('background')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Background Colors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BackgroundColors currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'text'} 
        onChange={handleAccordionChange('text')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Text Colors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextColors currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'state'} 
        onChange={handleAccordionChange('state')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>State Colors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StateColors currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const renderTypographySettings = () => (
    <Box>
      <Accordion 
        expanded={expandedAccordion === 'fonts'} 
        onChange={handleAccordionChange('fonts')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Font Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FontSettings currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'headings'} 
        onChange={handleAccordionChange('headings')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Heading Styles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HeadingStyles currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'text-styles'} 
        onChange={handleAccordionChange('text-styles')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Text Styles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextStyles currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const renderComponentSettings = () => (
    <Box>
      <Accordion 
        expanded={expandedAccordion === 'buttons'} 
        onChange={handleAccordionChange('buttons')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Button Styles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ButtonStyles currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'cards'} 
        onChange={handleAccordionChange('cards')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Card Styles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CardStyles currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={expandedAccordion === 'inputs'} 
        onChange={handleAccordionChange('inputs')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Input Styles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InputStyles currentTheme={currentTheme} onChange={handleThemeChange} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="right"
        open={themeCustomizerOpen}
        variant="persistent"
        sx={{
          width: themeCustomizerOpen ? DRAWER_WIDTH : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
            borderLeft: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <Paper elevation={2} sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.paper,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {showSavedThemes && (
              <IconButton onClick={() => setShowSavedThemes(false)} color="primary">
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6" color="primary">
              {showSavedThemes ? 'Saved Themes' : 'Theme Customizer'}
            </Typography>
          </Box>
          <IconButton onClick={toggleThemeCustomizer} color="primary">
            <CloseIcon />
          </IconButton>
        </Paper>

        {showSavedThemes ? (
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <List sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
              {userThemes.map((savedTheme) => (
                <Paper
                  key={savedTheme.id}
                  elevation={1}
                  sx={{
                    mb: 2,
                    overflow: 'hidden',
                    border: savedTheme.is_active ? `2px solid ${theme.palette.primary.main}` : 'none'
                  }}
                >
                  <ListItem>
                    <ListItemText 
                      primary={
                        <Typography variant="subtitle1" color="primary">
                          {savedTheme.name}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="caption" color="text.secondary">
                          Created: {new Date(savedTheme.created_at).toLocaleDateString()}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Switch
                        checked={savedTheme.is_active}
                        onChange={() => handleActivate(savedTheme.id)}
                        color="primary"
                      />
                      <IconButton 
                        onClick={() => handleEditTheme(savedTheme)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleDelete(savedTheme.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>
              ))}
            </List>
          </Box>
        ) : (
          <>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{ 
                borderBottom: 1, 
                borderColor: 'divider',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Tab icon={<PaletteIcon />} label="Colors" />
              <Tab icon={<TextFieldsIcon />} label="Typography" />
              <Tab icon={<WidgetsIcon />} label="Components" />
              <Tab icon={<PreviewIcon />} label="Preview" />
            </Tabs>

            <Box sx={{ 
              p: 2, 
              overflow: 'auto', 
              flexGrow: 1,
              backgroundColor: theme.palette.background.default 
            }}>
              {activeTab === 0 && renderColorSettings()}
              {activeTab === 1 && renderTypographySettings()}
              {activeTab === 2 && renderComponentSettings()}
              {activeTab === 3 && <LivePreview currentTheme={currentTheme} />}
            </Box>

            <Paper elevation={2} sx={{ 
              p: 2, 
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper
            }}>
              <TextField
                fullWidth
                label="Theme Name"
                value={themeName}
                onChange={(e) => setThemeName(e.target.value)}
                sx={{ mb: 2 }}
                variant="outlined"
              />
              <Button
                fullWidth
                variant="contained"
                onClick={handleSave}
                disabled={themesLoading}
                sx={{ mb: 1 }}
              >
                {themesLoading ? 'Saving...' : themeName ? `Save "${themeName}"` : 'Save Theme'}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setShowSavedThemes(true)}
                sx={{ mb: 1 }}
              >
                View Saved Themes
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setCurrentTheme(theme)}
                color="secondary"
              >
                Reset to Default
              </Button>
            </Paper>
          </>
        )}
      </Drawer>

      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      >
        <Alert 
          severity={error ? 'error' : 'success'} 
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
          sx={{ 
            backgroundColor: error ? theme.palette.error.main : theme.palette.success.main,
            color: '#fff'
          }}
        >
          {error || success}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ThemeCustomizerDrawer;