import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Menu,
  MenuItem,
  useTheme 
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const BookCard = ({ book, onSelect, onEdit, onDelete, isSelected }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      onClick={onSelect}
      sx={{
        position: 'relative',
        width: '160px',  // Smaller fixed width
        height: '220px', // Smaller height
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          border: isSelected 
            ? `2px solid ${theme.palette.primary.main}`
            : '1px solid rgba(0, 0, 0, 0.12)',
          boxShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column',
          p: 1.5,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            mb: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {book.title}
        </Typography>

        {book.genre && (
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              mt: 'auto'
            }}
          >
            {book.genre}
          </Typography>
        )}

        <IconButton
          onClick={handleMenuClick}
          size="small"
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
          }}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={(e) => {
            e.stopPropagation();
            handleMenuClose();
            onEdit();
          }}>
            Edit
          </MenuItem>
          <MenuItem 
            onClick={(e) => {
              e.stopPropagation();
              handleMenuClose();
              onDelete();
            }}
            sx={{ color: theme.palette.error.main }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default BookCard;