import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Slider,
  Switch,
  FormControlLabel,
  useTheme
} from '@mui/material';

const ButtonStyles = ({ currentTheme, onChange }) => {
  const theme = useTheme();

  const handleStyleChange = (property, value) => {
    onChange({
      components: {
        ...currentTheme.components,
        MuiButton: {
          styleOverrides: {
            root: {
              ...currentTheme.components?.MuiButton?.styleOverrides?.root,
              [property]: value
            }
          }
        }
      }
    });
  };

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom color="primary">
        Button Styles
      </Typography>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          General Settings
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={currentTheme.components?.MuiButton?.styleOverrides?.root?.textTransform === 'none'}
                  onChange={(e) => handleStyleChange('textTransform', e.target.checked ? 'none' : 'uppercase')}
                />
              }
              label="Disable Text Transform"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">Border Radius</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={currentTheme.components?.MuiButton?.styleOverrides?.root?.borderRadius || 4}
                  onChange={(e, value) => handleStyleChange('borderRadius', value)}
                  min={0}
                  max={24}
                  step={1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {currentTheme.components?.MuiButton?.styleOverrides?.root?.borderRadius || 4}px
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">Padding</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption">Horizontal</Typography>
                <Slider
                  value={parseInt(currentTheme.components?.MuiButton?.styleOverrides?.root?.padding?.split(' ')[1]) || 16}
                  onChange={(e, value) => handleStyleChange('padding', `8px ${value}px`)}
                  min={8}
                  max={32}
                  step={4}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">Vertical</Typography>
                <Slider
                  value={parseInt(currentTheme.components?.MuiButton?.styleOverrides?.root?.padding?.split(' ')[0]) || 8}
                  onChange={(e, value) => handleStyleChange('padding', `${value}px 16px`)}
                  min={4}
                  max={16}
                  step={2}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Preview
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained">
              Contained Button
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined">
              Outlined Button
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text">
              Text Button
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Button styles affect the appearance and interaction of all buttons in your application.
          Ensure they are visually distinct and maintain good contrast.
        </Typography>
      </Box>
    </Box>
  );
};

export default ButtonStyles;